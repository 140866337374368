import React from 'react'
import Banner from '../../../components/Banner';
import Navbar from '../../../components/Navbar';
import TermsAndConditions from '../../../components/governance/TermsAndConditions';

function TermsAndConditionsPage() {
  return (
    <div>
      <Banner />
      <Navbar />
      <TermsAndConditions />
    </div>
  )
}

export default TermsAndConditionsPage;