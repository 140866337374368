import React from 'react';
import classroom from "../assets/images/lesson_classroom.jpg"

function ContentSummarize() {
    return (
        <div className=''><div className="px-4 py-10 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-6">
            <div className="grid gap-5 row-gap-8 lg:grid-cols-2">
                <div className="flex flex-col justify-center">
                    <div className="max-w-xl mb-6">
                        <h2 className="max-w-lg mb-6 font-sans lg:text-5xl xl:6xl md:3xl text-4xl font-semibold tracking-tight text-[#01295c] ">

                            Summarize
                            <br className="hidden md:block" />

                        </h2>

                    </div>
                    <div className="grid gap-5 row-gap-8 sm:grid-cols-2">
                        <div className="bg-white border-l-8 shadow-sm border-[#2baf2b] rounded-lg">
                            <div className="h-full p-5 border border-l-0 rounded-r">
                                <h6 className="mb-2 font-semibold leading-5 underline text-lg">
                                    Topic Synopsis

                                </h6>
                                <p className="text-sm text-gray-900">
                                    These provide a summary of the chapter/topic and act as a ready reckoner for the students to revise concepts.
                                </p>
                            </div>
                        </div>
                        <div className="bg-white border-l-8 shadow-sm border-[#2baf2b] rounded-lg">
                            <div className="h-full p-5 border border-l-0 rounded-r">
                                <h6 className="mb-2 font-semibold leading-5 underline text-lg">
                                    Concept Map
                                </h6>
                                <p className="text-sm text-gray-900">
                                    These are graphic organizers on the concepts with complete flow of content covered in a topic.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <img
                        className="object-cover w-full h-56 sm:h-96"
                        src={classroom}
                        alt=""
                    />
                </div>
            </div>
        </div></div>
    )
}

export default ContentSummarize