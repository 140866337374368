import React from 'react'
import Banner from '../../../components/Banner';
import Navbar from '../../../components/Navbar';
import CodeConduct from '../../../components/governance/CodeConduct';

function CodeOfConductPage() {
  return (
    <div>
      <Banner />
      <Navbar />
      <CodeConduct />
    </div>
  )
}

export default CodeOfConductPage;