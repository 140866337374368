/* eslint-disable react/style-prop-object */
import React from 'react';
import { Link } from 'react-router-dom';


function Banner() {
    return (
        <div className='md:pb-10 lg:pb-10 xl:pb-10 pb-10'>
            <div id="banner" tabindex="-1" className="flex fixed z-50 gap-8 justify-between py-2 md:px-10 lg:px-10 xl:px-10 px-2 w-full bg-[#2baf2b] items-center lg:py-1 text-xs">
                <div className='md:block lg:block xl:block 2xl:block hidden'>
                    <div className='flex gap-6 items-center'>
                        <a className="font-normal text-gray-100 flex items-center gap-1" href="mailto:info@educomp.com">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-5 h-5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
                            </svg>
                            info@educomp.com
                        </a>
                        <p className="text-gray-200 flex gap-1">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-4 h-4 items-center">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z" />
                            </svg>

                            <a href="tel:0124-4529000" className="hover:underline">0124-4529000 </a>
                        </p>
                    </div>
                </div>
                <div className='flex gap-4 items-center justify-between max-w-md w-full md:justify-end lg:justify-end xl:justify-end 2xl:justify-end'>
                    <div className='bg-white rounded-md px-5 py-1'>
                        <a href={`https://www.educomp.com/broucher.pdf`} target="_blank" download="brochure.pdf" className='font-bold tracking-[0.1px] text-[#01295c] text-[12px] md:text-[15px] lg:text-[15px] xl:text-[15px]'>
                            Download Brochure
                        </a>
                    </div>
                    <Link to={`/reach-us`}>
                        <button data-collapse-toggle="banner" type="button" className="flex items-center bg-[#01295c] text-gray-100 hover:bg-[#01295c] rounded-full text-xs p-1 ">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Z" />
                            </svg>
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Banner