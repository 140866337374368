import React from 'react';
import overview from "../../assets/images/overview-img.jpg";

function OverviewHero() {
    return (
        <div>
            <div style={{ position: "relative" }}>
                <img
                    className="md:h-[350px] lg:h-[350px] xl:h-[350px] h-[200px] brightness-75 "
                    src={overview}
                    alt="book-banner-1"
                    style={{
                        width: "100%",
                        objectFit: "cover",

                    }}
                />
                <div
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        background:
                            "linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3))",
                        zIndex: 1,
                    }}
                >
                    <div
                        style={{
                            position: "relative",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                        }}
                        className="max-w-7xl mx-auto md:space-y-8 lg:space-y-8 xl:space-y-8 space-y-6 md:py-24 lg:py-24 xl:py-24 py-4 md:px-0 lg:px-0 xl:px-0 px-4 flex items-center"
                    >

                        <h2 className='md:text-7xl lg:text-7xl xl:text-7xl text-4xl font-bold text-[#2baf2b]'>Overview</h2>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default OverviewHero