import React from 'react'
import CorporateActions from '../../../components/AnnouncementCorporate/CorporateActions'
import Banner from '../../../components/Banner'
import Navbar from '../../../components/Navbar'

function CorporateActionsPage() {
    return (
        <div>
            <Banner />
            <Navbar />
            <CorporateActions />
        </div>
    )
}

export default CorporateActionsPage