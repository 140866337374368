import React from 'react';
import b2bvideo from "../assets/videos/b2b.mp4";
import b2b_2 from "../assets/images/b2b_2.svg";

function B2b() {
    return (
        <section>
            <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-12">
                <div className="relative mb-6 sm:mx-auto md:mb-10 md:max-w-md lg:max-w-2xl">
                    <video className="w-full h-full rounded-lg" autoPlay muted loop playsInline src={b2bvideo} />
                    <a
                        href="/"
                        aria-label="Play Video"
                        className="absolute inset-0 flex items-center justify-center w-full h-full transition-colors duration-300 bg-opacity-50 group hover:bg-opacity-25"
                    >

                    </a>
                </div>
            </div>

            <div className="flex flex-col lg:flex-row justify-between gap-8 md:px-4 md:py-2 lg:px-4 lg:py-2 xl:px-4 xl:py-2 px-0 pt-2 max-w-7xl mx-auto">

                <div
                    initial={{
                        opacity: 0,
                        x: -300,
                    }}
                    whileInView={{
                        opacity: 1,
                        x: 0,
                    }}
                    transition={{
                        duration: 1.5,
                    }}
                    className="w-full lg:w-6/12 flex flex-col justify-center space-y-4 md:px-0 lg:px-0 xl:px-0 px-2"
                >
                    <h2 className="tracking-tight  font-extrabold md:text-5xl text-2xl text-black">
                        <span className="text-[#2baf2b]"> B2B – Smartclass Content & Hardware </span>
                    </h2>
                    <div className="max-w-screen-lg text-gray-800 sm:text-lg  rounded-md">

                        <p className="mb-4 font-normal">
                            Smartclass is supported by a digital content repository that comprises rich-media material spanning grades K-12, tailored to satisfy the precise objectives set by the country's national and state boards.
                            The multimedia content repository engrosses students by bringing abstract concepts to life, as well as assisting them in training through exercises, example papers, and interactivities/simulations. Students may use summarizing materials like subject summaries and concept maps to support their learning. Instructors can use objective and subjective examinations to assess pupils on the subjects taught. Study the topics further using resources such as weblinks, real-life applications, and evaluation for learners.

                        </p>
                        <p className="mb-4 font-normal">
                            We strive to provide solutions that support a professional approach to have increased engagement and make learning a simple, straightforward, and an enjoyable experience for the students. We provide a wide choice of interactive digital panels and digital teaching devices, among other classroom equipment, thanks to our expertise in current technologies and school demands. We want to make the art of learning and presentations more understandable, straightforward, and engaging. Our objective is to provide our clients with the finest in class experience in interactive digital display solutions. We have teamed with many Smartclass hardware OEMs in India to do this.
                        </p>
                    </div>
                </div>

                <div className="w-full lg:w-6/12 ">
                    <img
                        initial={{
                            x: 500,
                            opacity: 0,
                            scale: 0.5,
                        }}
                        animate={{
                            x: 0,
                            opacity: 1,
                            scale: 1,
                        }}
                        transition={{
                            duration: 1,
                        }}
                        className="w-full h-full"
                        src={b2b_2}
                        alt="Road-Map"
                    />
                </div>
            </div>

        </section>
    )
}

export default B2b