import React from 'react'
import Banner from '../components/Banner'
import Navbar from '../components/Navbar'
import AboutUs from '../components/AboutUs'
import AboutContent from '../components/AboutContent'

function AboutPage() {
    return (
        <div>
            <Banner />
            <Navbar />
            <AboutUs />
            <AboutContent />
        </div>
    )
}

export default AboutPage