import React from 'react';
import { motion } from "framer-motion";
import video6 from "../assets/videos/6_Roadmap.mp4";
import video3 from "../assets/videos/3_content_type_no_autoplay.mp4"


function AboutContent() {
    return (
        <div>
            <div className="lg:flex items-center justify-between mt-12 mb-4 mx-auto max-w-7xl">
                <div className="lg:w-7/12 lg:mt-0 mt-8">
                    <div className="w-fit rounded-xl ">
                        <video className="w-full h-80 rounded-lg" autoPlay muted loop playsInline src={video6} />
                    </div>
                </div>
                <div className="lg:w-2/4 md:px-0 lg:px-0 xl:px-0 px-2">


                    <h2 className="tracking-tight  font-extrabold md:text-4xl text-2xl text-black md:px-0 lg:px-0 xl:px-0 px-2">
                        <span className="text-[#2baf2b]"> Background </span>
                    </h2>
                    <p className="text-md mt-4 text-gray-700 font-normal md:text-lg lg:text-lg xl:text-lg text-sm tracking-wide bg-gray-200/40 p-3">
                        We are passionate about creating high-quality content in sync with the National Education Policy that empower learners to achieve their goals, whether that's mastering a new subject, advancing their learning, or simply satisfying the curiosity.
                        <br />
                        <br />
                        Our team is made up of experienced educators, instructional designers, and technology experts who work together to create engaging and effective experiential experiences. We combine the latest research on learning and pedagogy with cutting-edge technology to create content that is interactive, immersive, and personalized.
                    </p>
                </div>
            </div>

            <div className="flex flex-col lg:flex-row justify-between gap-10 md:px-2 md:py-12 lg:px-2 lg:py-12 xl:px-4 xl:py-12 px-0 pt-2 max-w-7xl mx-auto">
                <motion.div
                    initial={{
                        opacity: 0,
                        x: -300,
                    }}
                    whileInView={{
                        opacity: 1,
                        x: 0,
                    }}
                    transition={{
                        duration: 1.5,
                    }}
                    className="w-full lg:w-7/12 flex flex-col justify-center"
                >
                    <div className="max-w-screen-lg text-gray-800 sm:text-lg md:px-0 lg:px-0 xl:px-0 px-2">

                        <p className="mb-4 font-normal">
                            We offer a wide range of K-12 school solutions across various subjects. Our content is designed to be flexible, allowing learners to study at their own pace and on their own schedule. We also offer support services, including access to teachers, discussion forums, and other resources to help students stay motivated and achieve better marks.
                        </p>
                        <p className="mb-4 font-normal">
                            We are committed to providing an exceptional learning experience for all of our students. We strive to create a welcoming and inclusive learning environment where everyone can feel valued, respected, and supported. We are constantly improving and updating our content to ensure that they meet the changing needs of students and teachers, and the demands of the modern school pedagogy.
                        </p>
                    </div>
                </motion.div>

                <div className="lg:w-7/12 lg:mt-0 mt-8 md:px-0 lg:px-0 xl:px-0 px-2">
                    <div className="w-fit rounded-xl ">
                        <video className="w-full h-80 rounded-lg" autoPlay muted loop playsInline src={video3} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutContent