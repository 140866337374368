export const agmpostal = [
    {
        id: 1,
        Heading1: "FY2022-23",
        Type: "AGM",
        Date: "15-03-2024",
        Text: "",
        PDF: "https://educomp.com/Data/Notice_29th_AGM_FY2022_23_15032024.pdf",
        isActive: 1
    },
    {
        id: 2,
        Heading1: "FY2021-22",
        Type: "AGM",
        Date: "07-11-2023",
        Text: "",
        PDF: "https://educomp.com/Data/Notice_28th AGM_FY2021_22_07112023.pdf",
        isActive: 1
    },
    {
        id: 3,
        Heading1: "FY2020-21",
        Type: "AGM",
        Date: "25-08-2023",
        Text: "",
        PDF: "https://educomp.com/Data/ESL_Notice_AGM_FY2020_21_25082023.pdf",
        isActive: 1
    },
    {
        id: 4,
        Heading1: "FY2019-20",
        Type: "AGM",
        Date: "27-03-2023",
        Text: "",
        PDF: "https://educomp.com/Data/Notice_AGM_FY2019_20_27032023.pdf",
        isActive: 1
    },
    {
        id: 5,
        Heading1: "FY2019-20",
        Type: "EGM",
        Date: "22-11-2022",
        Text: "",
        PDF: "https://educomp.com/Data/EGM_Notice_Educomp_22_11_2022.pdf",
        isActive: 1
    },
    {
        id: 6,
        Heading1: "FY2016-17",
        Type: "AGM",
        Date: "30-09-2016",
        Text: "23/09/2016-30/09/2016 (both days inclusive)",
        PDF: "https://educomp.com/Data/AGM-Notice-2016-ESL.PDF",
        isActive: 1
    },
    {
        id: 7,
        Heading1: "FY2015-16",
        Type: "AGM",
        Date: "28-09-2015",
        Text: "23/09/2015-28/09/2015 (both days inclusive)",
        PDF: "https://educomp.com/Data/AGM_Notice_28-09-2015.pdf",
        isActive: 1
    },
    {
        id: 8,
        Heading1: "FY2014-15",
        Type: "Postal Ballot",
        Date: "11-03-2015",
        Text: "",
        PDF: "https://educomp.com/Data/Postal_Ballot_Notice_Final_11-03-2015.pdf",
        isActive: 1
    },
    {
        id: 9,
        Heading1: "FY2014-15",
        Type: "AGM",
        Date: "29-09-2014",
        Text: "24/09/2014-29/09/2014 (both days inclusive)",
        PDF: "https://educomp.com/Data/AGM_Notice_29-09-2014.pdf",
        isActive: 1
    },
    {
        id: 10,
        Heading1: "FY2014-15",
        Type: "Postal Ballot",
        Date: "26-03-2014",
        Text: "",
        PDF: "https://educomp.com/Data/Postal_Ballot_Notice_Final_26-03-2014.pdf",
        isActive: 1
    },
    {
        id: 11,
        Heading1: "FY2013-14",
        Type: "Postal Ballot",
        Date: "11-08-2014",
        Text: "",
        PDF: "https://educomp.com/Data/Postal_Ballot_Notice_Final_11-8-2014.pdf",
        isActive: 1
    },
    {
        id: 12,
        Heading1: "FY2013-14",
        Type: "AGM",
        Date: "26-09-2013",
        Text: "21/09/2013-26/09/2013 (both days inclusive)",
        PDF: "https://educomp.com/Data/AGM_Notice_26-09-2013.pdf",
        isActive: 1
    },
    {
        id: 13,
        Heading1: "FY2012-13",
        Type: "EGM",
        Date: "15-11-2012",
        Text: "",
        PDF: "https://educomp.com/Data/EGM_Notice_15-11-2012.pdf",
        isActive: 1
    },
    {
        id: 14,
        Heading1: "FY2012-13",
        Type: "AGM",
        Date: "26-09-2012",
        Text: "22/09/2012-26/09/2012 (both days inclusive)",
        PDF: "https://educomp.com/Data/AGM_Notice_26-09-2012.pdf",
        isActive: 1
    },
    {
        id: 15,
        Heading1: "FY2012-13",
        Type: "EGM",
        Date: "16-07-2012",
        Text: "",
        PDF: "https://educomp.com/Data/EGM_Notice_16-07-2012.pdf",
        isActive: 1
    },
    {
        id: 16,
        Heading1: "FY2011-12",
        Type: "AGM",
        Date: "24-09-2011",
        Text: "17/09/2011-24/09/2011 (both days inclusive)",
        PDF: "https://educomp.com/Data/AGM_Notice_24-09-2011.pdf",
        isActive: 1
    },
    {
        id: 17,
        Heading1: "FY2011-12",
        Type: "Postal Ballot",
        Date: "26-07-2011",
        Text: "",
        PDF: "https://educomp.com/Data/Postal_Ballot_Notice_Final_26-07-2011.pdf",
        isActive: 1
    }
]