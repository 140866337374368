import React, { } from 'react'

function Disclaimer() {
    return (
        <section>

            <div className='mx-auto max-w-7xl py-8'>
                <div className='md:flex lg:flex xl:flex 2xl:flex'>
                    <div className='lg:px-6 md:px-6 xl:px-6 px-2 md:pb-6 lg:pb-6 xl:pb-6 pb-4 max-w-4xl'>
                        <h2 class="my-4 text-2xl text-gray-700 font-bold">Disclaimer and Terms of use</h2>
                        <h3 class="my-4 text-lg text-gray-700 font-bold">ALL CONTENTS ARE COPYRIGHT © 2014 EDUCOMP SOLUTIONS LTD. ALL RIGHTS RESERVED</h3>
                        <h4 class="my-4 text-md text-gray-700 font-bold">1. TERMS OF USE</h4>
                        <p class="mb-4 text-md font-normal text-gray-500 ">The following terms and conditions will be deemed to have been accepted by the User on usage of the website http://www.educomp.com. You are requested to read them carefully before you use the services of this site.
                            <br />
                            <br />
                        </p>
                    </div>
                </div>

                <div className='lg:px-6 md:px-6 xl:px-6 px-2 md:pb-6 lg:pb-6 xl:pb-6 pb-4 max-w-4xl'>
                    <p class="mb-4 text-md font-normal text-gray-500 ">{`a)`} The term User shall refer to the user who is browsing the Site. The term ESL shall refer to Educomp Solutions Limited and/or its affiliates/subsidiary companies. The term Site refers to www.educomp.com and other websites, owned and monitored by Educomp.</p>
                    <p class="mb-4 text-md font-normal text-gray-500 ">{`b)`} By using the Site, you agree to follow and be bound by the following terms and conditions concerning your use of the Site. ESL may revise the Terms of Use at any time without notice to you. Areas of the Site may have different terms of use posted. If there is a conflict between the Terms of Use and terms of use posted for a specific area of the Site, the latter shall have precedence with respect to your use of that area of the Site.</p>
                    <p class="mb-4 text-md font-normal text-gray-500 ">{`c)`} All content present on this site is the exclusive property of ESL. The software, text, images, graphics, video and audio used on this site belong to ESL. No material from this site may be copied, modified, reproduced, republished, uploaded, transmitted, posted or distributed in any form whatsoever without prior written permission from ESL. All rights not expressly granted herein are reserved. Unauthorized use of the materials appearing on this site may violate copyright, trademark and other applicable laws, and could result in criminal and/or civil penalties. ESL is a registered trademark of ESL Limited. This trademark may not be used in any manner without prior written consent from ESL Limited.</p>

                    <p class="mb-4 text-md font-normal text-gray-500 ">{`d)`} ESL does not make any warranties, express or implied, including without limitation, those of merchantability and fitness for a particular purpose, with respect to any information, data, statements or products made available on the Site.</p>

                    <p class="mb-4 text-md font-normal text-gray-500 ">{`e)`} ESL does not accept any responsibility towards the contents and/or information practices of third party Sites which have links through ESL's Site. The said links to internal or external Web site locations are only for the purpose of facilitating your visit or clarify your query.</p>

                    <p class="mb-4 text-md font-normal text-gray-500 ">{`f)`} The Site, and all content, materials, information, software, products and services provided on the Site, are provided on an "as is" and "as available" basis. ESL expressly disclaims all warranties of any kind, whether express or implied, including, but not limited to, the implied warranties of merchantability, fitness for a particular purpose and non-infringement.</p>

                    <p class="mb-4 text-md font-normal text-gray-500 ">{`g)`} ESL shall have no responsibility for any damage to a User's computer system or loss of data that results from the download of any content, materials, information from the Site.</p>

                    <p class="mb-4 text-md font-normal text-gray-500 ">{`h)`} In no event will ESL be liable for damages of any kind, including without limitation, direct, incidental or consequential damages (including, but not limited to, damages for lost profits, business interruption and loss of programs or information) arising out of the use of or inability to use ESL's Web site, or any information provided on the Web site, or in the Products any claim attributable to errors, omissions or other inaccuracies in the Product or interpretations thereof. Some jurisdictions do not allow the limitation or exclusion of liability. Accordingly, some of the above limitations may not apply to the User.</p>

                    <p class="mb-4 text-md font-normal text-gray-500 ">{`i)`} The User agrees to indemnify, defend and hold ESL harmless from and against all losses, expenses, damages and costs, including reasonable attorneys' fees, arising out of or relating to any misuse by the User of the content and services provided on the Site.</p>

                    <p class="mb-4 text-md font-normal text-gray-500 ">{`j)`} The information contained in the Site has been obtained from sources believed to be reliable. ESL disclaims all warranties as to the accuracy, completeness or adequacy of such information.</p>

                    <p class="mb-4 text-md font-normal text-gray-500 ">{`k)`} ESL makes no warranty that: (a) the Site will meet your requirements; (b) the Site will be available on an uninterrupted, timely, secure, or error-free basis; (c) the results that may be obtained from the use of the Site or any services offered through the Site will be accurate or reliable.</p>

                    <p class="mb-4 text-md font-normal text-gray-500 ">{`l)`} The User's right to privacy is of paramount importance to ESL. Any information provided by the User will not be shared with any third party. ESL reserves the right to use the information to provide the User a more personalized online experience.</p>

                    <p class="mb-4 text-md font-normal text-gray-500 ">{`m)`} The Site provides links to Web sites and access to content, products and services from third parties, including users, advertisers, affiliates and sponsors of the Site. You agree that ESL is not responsible for the availability of, and content provided on, third party Web sites. The User is requested to peruse the policies posted by other Web sites regarding privacy and other topics before use. ESL is not responsible for third party content accessible through the Site, including opinions, advice, statements and advertisements and User shall bear all risks associated with the use of such content. ESL is not responsible for any loss or damage of any sort that a User may incur from dealing with any third party.</p>

                    <h3 class="my-4 text-lg text-gray-700 font-bold">2. GENERAL DISCLAIMER</h3>
                    <p class="mb-4 text-md font-normal text-gray-500 ">THE DOCUMENTS AND GRAPHICS ON THIS WEBSITE COULD INCLUDE TECHNICAL INACCURACIES OR TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE INFORMATION HEREIN. ESL MAY MAKE IMPROVEMENTS AND/OR CHANGES HEREIN AT ANY TIME. ESL MAKES NO REPRESENTATIONS ABOUT THE ACCURACY OF THE INFORMATION CONTAINED IN THE DOCUMENTS AND GRAPHICS ON THIS WEB SITE FOR ANY PURPOSE. ALL DOCUMENTS AND GRAPHICS ARE PROVIDED "AS IS". ESL HEREBY DISCLAIMS ALL WARRANTIES AND CONDITIONS WITH REGARD TO THIS INFORMATION, INCLUDING ALL IMPLIED WARRANTIES AND CONDITIONS OF MERCHANTABILITY, FITNESS FOR ANY PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT. IN NO EVENT, ESL AND/OR ITS LICENSOR/ SUPPLIER SHALL NOT BE LIABLE TO ANY PARTY FOR ANY DIRECT, INDIRECT, SPECIAL OR OTHER CONSEQUENTIAL DAMAGES FOR ANY USE OF THE SITES, THE INFORMATION, OR ON ANY OTHER HYPERLINKED WEB SITE, INCLUDING, WITHOUT LIMITATION, ANY LOST PROFITS, BUSINESS INTERRUPTION, LOSS OF PROGRAMS OR OTHER DATA ON YOUR INFORMATION HANDLING SYSTEM OR OTHERWISE, EVEN IF ESL IS EXPRESSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</p>

                </div>
            </div>
        </section>
    )
}

export default Disclaimer