import React, { useState } from 'react'
import GovernanceHero from '../governance/GovernanceHero'
import SideMenu from './SideMenu'
import { agmpostal } from '../../js/AGMPostal';

function AGMPostal() {
    const [showArchive, setShowArchive] = useState(false);

    const recentData = agmpostal.filter(item => parseInt(item.Date.split('-')[2]) >= 2016);
    const archivedData = agmpostal.filter(item => parseInt(item.Date.split('-')[2]) < 2016);

    return (
        <div>
            <GovernanceHero headingText={'Corporate Announcements'} />

            <div className='mx-auto max-w-7xl py-8'>
                <div className='md:flex lg:flex xl:flex 2xl:flex'>
                    <div className='lg:px-6 md:px-6 xl:px-6 px-2 md:pb-6 lg:pb-6 xl:pb-6 pb-4 max-w-4xl w-full'>
                        <h2 className="my-4 text-2xl text-gray-700 font-bold">AGM/EGM/POSTAL BALLOTS</h2>

                        <div className="relative overflow-x-auto p-2">
                            <table className="text-sm text-left rtl:text-right text-gray-500">
                                <thead className="text-xs text-gray-700 uppercase">
                                    <tr>
                                        <th scope="col" className="px-6 py-3">Financial Year</th>
                                        <th scope="col" className="px-6 py-3">Meeting Type</th>
                                        <th scope="col" className="px-6 py-3">Date of Meeting</th>
                                        <th scope="col" className="px-6 py-3">Book Closure Period</th>
                                        <th scope="col" className="px-6 py-3">Notice</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {recentData.map(agmpostals => (
                                        <tr key={agmpostals.id} className="border-b border-gray-200">
                                            <td className="px-6 py-4">{agmpostals.Heading1}</td>
                                            <td className="px-6 py-4">{agmpostals.Type}</td>
                                            <td className="px-6 py-4">{agmpostals.Date}</td>
                                            <td className="px-6 py-4">{agmpostals.Text}</td>
                                            <td className="px-6 py-4 text-[#2baf2b]">
                                                <a href={agmpostals.PDF} target="_blank" rel="noopener noreferrer">
                                                    Download
                                                </a>
                                            </td>
                                        </tr>
                                    ))}
                                    {showArchive && archivedData.map(agmpostals => (
                                        <tr key={agmpostals.id} className="border-b border-gray-200">
                                            <td className="px-6 py-4">{agmpostals.Heading1}</td>
                                            <td className="px-6 py-4">{agmpostals.Type}</td>
                                            <td className="px-6 py-4">{agmpostals.Date}</td>
                                            <td className="px-6 py-4">{agmpostals.Text}</td>
                                            <td className="px-6 py-4 text-[#2baf2b]">
                                                <a href={agmpostals.PDF} target="_blank" rel="noopener noreferrer">
                                                    Download
                                                </a>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        <button
                            onClick={() => setShowArchive(!showArchive)}
                            className="mt-5 px-4 py-1 bg-[#2baf2b] text-white text-sm rounded flex gap-2 items-center"
                        >


                            {showArchive ? 'Hide Archive Data' : 'Show Archive Data'}
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                            </svg>
                        </button>
                    </div>

                    <div className='md:px-0 lg:px-0 xl:px-0 2xl:px-0 px-3.5'>
                        <SideMenu />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AGMPostal