import React from 'react';

function OverviewMain() {
    return (
        <div>
            <div className='max-w-7xl mx-auto'>
                <div className="px-4 py-10 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-6 lg:py-12">
                    <h2 class="text-5xl font-extrabold text-[#2baf2b]">Share Information</h2>
                </div>

                <ul class="max-w-lg divide-y divide-gray-200 pb-5 px-8">
                    <li class="pt-3 pb-0 sm:pt-4">
                        <div class="flex items-center space-x-4 rtl:space-x-reverse">

                            <div class="flex-1 min-w-0">
                                <p class="text-sm font-medium text-gray-900 truncate ">
                                    NSE Code
                                </p>

                            </div>
                            <div class="inline-flex items-center text-base font-semibold text-gray-700 ">
                                EDUCOMP
                            </div>
                        </div>
                    </li>
                    <li class="pt-3 pb-0 sm:pt-4">
                        <div class="flex items-center space-x-4 rtl:space-x-reverse">

                            <div class="flex-1 min-w-0">
                                <p class="text-sm font-medium text-gray-900 truncate ">
                                    BSE Code
                                </p>

                            </div>
                            <div class="inline-flex items-center text-base font-semibold text-gray-700 ">
                                532696
                            </div>
                        </div>
                    </li>
                    <li class="pt-3 pb-0 sm:pt-4">
                        <div class="flex items-center space-x-4 rtl:space-x-reverse">

                            <div class="flex-1 min-w-0">
                                <p class="text-sm font-medium text-gray-900 truncate">
                                    Date of Listing
                                </p>

                            </div>
                            <div class="inline-flex items-center text-base font-semibold text-gray-700 ">
                                13-01-2006
                            </div>
                        </div>
                    </li>
                    <li class="pt-3 pb-0 sm:pt-4">
                        <div class="flex items-center space-x-4 rtl:space-x-reverse">

                            <div class="flex-1 min-w-0">
                                <p class="text-sm font-medium text-gray-900 truncate">
                                    ISIN No
                                </p>

                            </div>
                            <div class="inline-flex items-center text-base font-semibold text-gray-700 ">
                                INE216H01027
                            </div>
                        </div>
                    </li>
                    <li class="pt-3 pb-0 sm:pt-4">
                        <div class="flex items-center space-x-4 rtl:space-x-reverse">

                            <div class="flex-1 min-w-0">
                                <p class="text-sm font-medium text-gray-900 truncate">
                                    Market Lot
                                </p>

                            </div>
                            <div class="inline-flex items-center text-base font-semibold text-gray-700 ">
                                1
                            </div>
                        </div>
                    </li>
                    <li class="pt-3 pb-0 sm:pt-4">
                        <div class="flex items-center space-x-4 rtl:space-x-reverse">

                            <div class="flex-1 min-w-0">
                                <p class="text-sm font-medium text-gray-900 truncate">
                                    Face Value
                                </p>

                            </div>
                            <div class="inline-flex items-center text-base font-semibold text-gray-700 ">
                                Rs. 2.00
                            </div>
                        </div>
                    </li>
                </ul>

            </div>



            <div className='max-w-7xl mx-auto '>
                <div className="px-4 py-10 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-6 lg:py-12">
                    <h2 class="text-5xl font-extrabold text-[#2baf2b]">Credit Ratings</h2>
                </div>



                <div class="relative overflow-x-auto bg-gray-100/80 rounded-md p-2">
                    <table class="w-full text-sm text-left rtl:text-right text-gray-500">
                        <thead class="text-xs text-gray-700 uppercase">
                            <tr>
                                <th scope="col" class="px-6 py-3 ">
                                    Rating Type
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Rating Agency
                                </th>
                                <th scope="col" class="px-6 py-3 ">
                                    Rating
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Amount (Rs. in Crores)
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="border-b border-gray-200 ">
                                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap  ">
                                    Bank Facilities
                                </th>
                                <td class="px-6 py-4">
                                    Credit Analysis & Research Ltd. (CARE Ratings)
                                </td>
                                <td class="px-6 py-4 ">
                                    CARE D
                                </td>
                                <td class="px-6 py-4">
                                    1921.80
                                </td>
                            </tr>
                            <tr class="border-b border-gray-200 ">
                                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap  ">
                                    Non Convertible Debentures
                                </th>
                                <td class="px-6 py-4">
                                    Credit Analysis & Research Ltd. (CARE Ratings)
                                </td>
                                <td class="px-6 py-4 ">
                                    CARE D
                                </td>
                                <td class="px-6 py-4">
                                    45
                                </td>
                            </tr>
                            <tr class="">
                                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap  ">
                                    Bank Facilities
                                </th>
                                <td class="px-6 py-4">
                                    Credit Analysis & Research Ltd. (CARE Ratings)
                                </td>
                                <td class="px-6 py-4 ">
                                    CARE D
                                </td>
                                <td class="px-6 py-4">
                                    404.08
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>


            </div>

            <div>
                <div className='mx-auto max-w-7xl p-6'>

                    <h2 class="text-5xl font-extrabold text-[#2baf2b]">Investors Relations</h2>

                </div>
                <div className="px-4 py-10 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-12">
                    <div className="grid gap-10 row-gap-5 sm:row-gap-8 sm:grid-cols-2 lg:grid-cols-3">
                        <div className='bg-gray-100/80 p-3 rounded-md'>

                            <h6 className="mb-2 font-bold leading-5 text-xl">Registered Office</h6>
                            <p className="mb-3  text-gray-900 text-md">
                                1211, Padma Tower 1, 5, Rajendra Place, New Delhi-110008.

                            </p>
                            <p className="mb-3  text-gray-900 text-md">
                                Telephone: 91-11-25755920;
                                <br />
                                Fax: 91-11-25766775

                            </p>


                        </div>
                        <div className='bg-gray-100/80 p-3 rounded-md'>

                            <h6 className="mb-2 font-bold leading-5 text-xl">Corporate Office</h6>
                            <p className="mb-3  text-gray-900 text-md">
                                514, Udyog Vihar, Phase- III, Gurgaon, Haryana-122001.

                            </p>
                            <p className='mb-3  text-gray-900 text-md'>Telephone: 0124-4529000;
                                <br />
                                Fax: 0124 - 4529039.</p>
                        </div>

                        <div className='bg-gray-100/80 p-3 rounded-md'>

                            <h6 className="mb-2 font-bold leading-5 text-xl">Registrar and Transfer Agent</h6>
                            <p className="mb-3  text-gray-900 text-md">
                                Link Intime India Private limited
                                44, Community Centre, Phase- I,
                                Near PVR, Naraina Industrial Area, New Delhi - 110028
                                Telephone: 011-41410592-94
                            </p>
                            <p className="mb-3  text-gray-900 text-md">
                                Fax: 011-41410591
                                <br />
                                E-mail: delhi@linkintime.co.in
                            </p>

                        </div>

                        <div className='bg-gray-100/80 p-3 rounded-md'>

                            <h6 className="mb-2 font-bold leading-5 text-xl">Debenture Trustees</h6>
                            <p className="mb-3  text-gray-900 text-md">
                                Axis Trustee Services Limited,
                                Axis House, 2nd Floor, Bombay Dyeing Mills Compound,
                                Pandurang Budhkar Marg, Worli, Mumbai, Maharashtra- 400025
                                Ph:-022-24255215/5216
                            </p>
                            <p className="mb-3  text-gray-900 text-md">
                                E-mail: complaints@axistrustee.com;
                                <br />
                                debenturetrustee@axistrustee.com
                            </p>

                        </div>

                        <div className='bg-gray-100/80 p-3 rounded-md'>

                            <h6 className="mb-2 font-bold leading-5 text-xl">Investor grievances & Compliance Matters</h6>
                            <p className="mb-3  text-gray-900 text-md">
                                Company Secretary
                                Educomp Solutions Limited,
                                Plot no 514, Udyog Vihar, Phase III
                                Gurgaon-122001
                                Telephone: 0124-4529000
                            </p>
                            <p className="mb-3  text-gray-900 text-md">
                                Fax: 0124 - 4529039
                                <br />
                                E-mail: investor.services@educomp.com
                            </p>

                        </div>

                        <div className='bg-gray-100/80 p-3 rounded-md'>

                            <h6 className="mb-2 font-bold leading-5 text-xl">For Securities held in Physical form please contact</h6>
                            <p className="mb-3  text-gray-900 text-md">
                                Link Intime India Private limited
                                44, Community Centre, Phase- I,
                                Near PVR, Naraina Industrial Area, New Delhi - 110028
                            </p>
                            <p className='mb-3  text-gray-900 text-md'>For General inquiries

                                investor.services@educomp.com</p>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OverviewMain