import React, { } from 'react'
import GovernanceHero from './GovernanceHero';
import { Link, useLocation } from 'react-router-dom';

function TermsAndConditions() {
    const location = useLocation();

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    const isLinkActive = (pathname) => {
        return location.pathname === pathname;
    };
    return (
        <section>
            <GovernanceHero headingText={"Terms & Conditions"} />

            <div className='mx-auto max-w-7xl py-8'>
                <div className='md:flex lg:flex xl:flex 2xl:flex'>
                    <div className='lg:px-6 md:px-6 xl:px-6 px-2 md:pb-6 lg:pb-6 xl:pb-6 pb-4 max-w-4xl'>
                        <h2 class="my-4 text-2xl text-gray-700 font-bold">FOR APPOINTMENT OF INDEPENDENT DIRECTORS</h2>
                        <h3 class="my-4 text-lg text-gray-700 font-bold">1. TENURE OF APPOINTMENT</h3>
                        <p class="mb-4 text-md font-normal text-gray-500 ">Appointment of all independent non-executive directors, other than Mrs. Swati Sinha and Ms. Azra Shauqia Hasan, for the time being is up to 31st March 2019.Appointment of Mrs. Swati Sinha and Ms. Azra Shauqia Hasan as independent non-executive director for the time being is up to 28th September, 2019 and 27th May, 2020, respectively.
                            <br />
                            <br />
                            This tenure is subject to the condition that they continue to meet the criteria for being an Independent Director. Their appointment is also subject to the maximum permissible Directorships that one can hold as per the provisions of the Companies Act, 2013 (“the Act”) and the rules made there under and the listing agreement, as amended from time to time.</p>

                    </div>

                    <div className='md:px-0 lg:px-0 xl:px-0 2xl:px-0 px-3.5'>
                        <div className="block mt-5 max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-50">

                            <h5 class="mb-2 text-xl font-bold tracking-tight text-gray-700 ">Governance</h5>
                            <ul class="font-normal text-gray-700 list-disc  list-inside space-y-2">
                                <li className={`${isLinkActive('/investors/governance') ? 'font-bold text-blue-600' : ''}`}>

                                    <Link
                                        onClick={scrollToTop}
                                        to={`/investors/governance`}>
                                        Board of Directors
                                    </Link>
                                </li>
                                <li className={`${isLinkActive('/investors/terms-and-conditions-independent-directors') ? 'font-bold text-blue-600' : ''}`}>
                                    <Link
                                        onClick={scrollToTop}
                                        to={`/investors/terms-and-conditions-independent-directors`}>
                                        Terms & Conditions for appointment of Independent Directors
                                    </Link>
                                </li>
                                <li className={`${isLinkActive('/investors/committee-to-board') ? 'font-bold text-blue-600' : ''}`}>
                                    <Link
                                        onClick={scrollToTop}
                                        to={`/investors/committee-to-board`}>
                                        Committees to the Board
                                    </Link>
                                </li>
                                <li className={`${isLinkActive('/investors/code-to-conduct') ? 'font-bold text-blue-600' : ''}`}>
                                    <Link
                                        onClick={scrollToTop}
                                        to={`/investors/code-to-conduct`}>
                                        Code of Conduct
                                    </Link>
                                </li>
                                <li className={`${isLinkActive('/investors/policies') ? 'font-bold text-blue-600' : ''}`}>
                                    <Link
                                        onClick={scrollToTop}
                                        to={`/investors/policies`}>
                                        Policies
                                    </Link>
                                </li>
                                <li className={`${isLinkActive('/investors/familiarisation-programme') ? 'font-bold text-blue-600' : ''}`}>
                                    <Link
                                        onClick={scrollToTop}
                                        to={`/investors/familiarisation-programme`}>
                                        Familiarisation Programme
                                    </Link>
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>

                <div className='lg:px-6 md:px-6 xl:px-6 px-2 md:pb-6 lg:pb-6 xl:pb-6 pb-4 max-w-4xl'>
                    <h3 class="my-4 text-lg text-gray-700 font-bold">2. COMMITTEES</h3>
                    <p class="mb-4 text-md font-normal text-gray-500 ">The Board of Directors (the Board) may, if it deems fit, invite them for being appointed on one or more existing Board Committees or any such Committee that is set up in the future. Their appointment on such Committee(s) will be subject to the applicable regulations.</p>

                    <h3 class="my-4 text-lg text-gray-700 font-bold">3. TIME COMMITMENT</h3>
                    <p class="mb-4 text-md font-normal text-gray-500 ">As Independent Director, they are expected to bring objectivity and independence of view to the Board's discussions and to help provide the Board with effective leadership in relation to the Company's strategy, performance, and risk management as well as ensuring high standards of financial probity and corporate governance. The Board meets at least 4 times in a year. The Audit Committee also meets at least 4 times in a year. Besides, there are other Committee meetings like Nomination and Remuneration Committee, Stakeholders' Relationship Committee and Corporate Social Responsibility Committee meetings which are ordinarily convened as and when required. They will be expected to attend Board, Board Committees to which they may be appointed and Shareholders meetings and to devote such time to your duties, as appropriate for them to discharge their duties effectively.</p>

                    <h3 class="my-4 text-lg text-gray-700 font-bold">4. DUTIES AND LIABILITIES</h3>
                    <p class="mb-4 text-md font-normal text-gray-500 ">Their role and duties will be those normally required of a Non-Executive Independent Director under the Companies Act, 2013 and the listing agreement. There are certain duties prescribed for all Directors, both Executive and Non-Executive, which are fiduciary in nature and are as under:</p>

                    <ul class="mb-4 text-md font-normal text-gray-500 list-disc list-inside space-y-2">
                        <li>They shall act in accordance with the Company's Articles of Association.</li>

                        <li>They shall act in good faith in order to promote the objects of the Company for the benefit of its members as a whole, and in the best interest of the Company.</li>

                        <li>They shall discharge their duties with due and reasonable care, skill and diligence.</li>

                        <li>They shall not involve themselves in a situation in which they may have a direct or indirect interest that conflicts, or possibly may conflict, with the interest of the Company.</li>

                        <li>They shall not achieve or attempt to achieve any undue gain or advantage either to themselves or to their relatives, partners or associates.</li>

                        <li>They shall not assign their office as Director and any assignments so made shall be void.</li>
                    </ul>

                    <p class="mb-4 text-md font-medium text-gray-500 ">In addition to the above requirements applicable to all Non Executive Directors, the role of the Non Executive Directors has the following key elements:</p>

                    <p class="mb-4 text-md font-normal text-gray-500 "><span className='font-bold'>Strategy:</span> Non-Executive Directors should constructively challenge and help develop proposals on strategy.</p>

                    <p class="mb-4 text-md font-normal text-gray-500 "><span className='font-bold'>Performance:</span> Non-Executive Directors should scrutinize the performance of management in meeting agreed goals and objectives.</p>

                    <p class="mb-4 text-md font-normal text-gray-500 "><span className='font-bold'>Risk:</span> Non-Executive Directors should satisfy themselves on the integrity of financial information and that financial controls and systems of risk management are robust and defensible.</p>

                    <p class="mb-4 text-md font-normal text-gray-500 "><span className='font-bold'>People:</span>  Non-Executive Directors are responsible for determining appropriate levels of remuneration of Executive Directors and have a prime role in appointing, and where necessary, removing Executive Directors and in succession planning.</p>

                    <p class="mb-4 text-md font-normal text-gray-500 "><span className='font-bold'>Compliance:</span> Non-Executive Directors should keep governance and compliance with the applicable Educomp Code of Conduct, legislation and regulations under review and the conformity of ESL practices to accepted norms.</p>

                    <h3 class="my-4 text-lg text-gray-700 font-bold">5. STATUS OF APPOINTMENT</h3>
                    <p class="mb-4 text-md font-normal text-gray-500 ">They will not be an employee of the Company and this letter shall not constitute a contract of employment. They will be entitled to remuneration as per applicable provisions of laws for the time being in force and in accordance with the criteria, as approved by the Board of Directors on the recommendation by the Nomination and Remuneration Committee, within the overall limits approved by the shareholders and the applicable legal provisions. They may also be paid remuneration by commission as may be approved by the Board and the Shareholders from time to time.</p>

                    <p class="mb-4 text-md font-normal text-gray-500 ">They will also be entitled to sitting fee for attending the meetings of the Board or Committee thereof either personally or through Video Conference or other audio visual means or for any other purpose whatsoever as may be decided by the Board of Directors from time to time.</p>
                    <p class="mb-4 text-md font-normal text-gray-500 ">The remuneration and fee payable shall be subject to applicable tax deduction at source. In addition to the above they will be entitled to reimbursement of all expenses for participation in the Board and other meetings.</p>

                    <h3 class="my-4 text-lg text-gray-700 font-bold">6. CONFLICT OF INTEREST</h3>
                    <p class="mb-4 text-md font-normal text-gray-500 ">It is accepted and acknowledged that they may have business interests other than those of the Company. As a condition to their appointment commencing, they are required to declare any such directorships, appointments and interests to the Board in writing in the prescribed form at the time of their appointment.
                    </p>
                    <p class="mb-4 text-md font-normal text-gray-500 ">In the event that their circumstances seem likely to change and might give rise to a conflict of interest or, when applicable, circumstances that might lead the Board to revise its judgment that they are independent, this should be disclosed to the Chairman.
                    </p>

                    <h3 class="my-4 text-lg text-gray-700 font-bold">7. CONFIDENTIALITY</h3>
                    <p class="mb-4 text-md font-normal text-gray-500 ">All information acquired during their appointment is confidential to ESL and should not be released, either during their appointment or following termination (by whatever means) to third parties without prior clearance from the Chairman unless required by law or by the rules of any stock exchange or regulatory body. On reasonable request, they shall surrender any documents and other materials made available to them by ESL.
                    </p>
                    <p class="mb-4 text-md font-normal text-gray-500 ">They also require to comply the requirements under the applicable regulations and the ESL Code of Conduct which concern the disclosure of price sensitive information and dealing in the securities of ESL. Consequently they should avoid making any statements or performing any transactions that might risk a breach of these requirements without prior clearance from the Chairman.
                    </p>

                    <h3 class="my-4 text-lg text-gray-700 font-bold">8. PERFORMANCE EVALUATION</h3>
                    <p class="mb-4 text-md font-normal text-gray-500 ">Performance evaluation of each director shall be done by the Board of Directors annually, without participation of concerned director. Each Director will participate in reviewing the performance of other directors.
                    </p>

                    <h3 class="my-4 text-lg text-gray-700 font-bold">9. DIRECTORS AND OFFICERS LIABILITY INSURANCE POLICY</h3>
                    <p class="mb-4 text-md font-normal text-gray-500 ">The Company have a Directors and officers Liability Insurance Policy. The Directors would be covered /indemnified as per the policy of the Company.
                    </p>

                    <h3 class="my-4 text-lg text-gray-700 font-bold">10. DISCLOSURE OF INTEREST</h3>
                    <p class="mb-4 text-md font-normal text-gray-500 ">The Company must include in its Annual Accounts a note of any material interest that a Director may have in any transaction or arrangement that the Company has entered into. Such interest should be disclosed not later than when the transaction or arrangement comes up at a Board meeting so that the minutes may record your interest appropriately and our records are updated. A general notice that you are interested in any contracts with a particular person, firm or company is acceptable.
                    </p>

                    <h3 class="my-4 text-lg text-gray-700 font-bold">11. TERMINATION</h3>
                    <p class="mb-4 text-md font-normal text-gray-500 ">They may resign from their position at any time and when they wish to do so. However, they are expected to serve a reasonable written notice on the Board.
                    </p>
                    <p class="mb-4 text-md font-normal text-gray-500 ">Continuation of their appointment is contingent on their getting re-elected by the shareholders in accordance with provisions of Act, listing agreement and the Articles of Association of the Company, from time to time in force. They will not be entitled to compensation if the shareholders do not re-elect them at any time.
                    </p>
                    <p class="mb-4 text-md font-normal text-gray-500 ">Their appointment may also be terminated in accordance with the provisions of the Act and Articles of Association of the Company from time to time in force.
                    </p>


                    <h3 class="my-4 text-lg text-gray-700 font-bold">12. GOVERNING LAW</h3>
                    <p class="mb-4 text-md font-normal text-gray-500 ">Their terms of appointment are governed by and will be interpreted in accordance with Indian law, as amended from time to time, and their engagement shall be subject to the jurisdiction of the Indian courts.
                    </p>
                </div>
            </div>
        </section>
    )
}

export default TermsAndConditions