// MyComponent.js
import React from 'react';
import { corporateGovernance } from '../../js/CorporateGovernance';
import GovernanceHero from '../governance/GovernanceHero';

const CorporateGovernance = () => {


    return (
        <div>
            <GovernanceHero headingText={"CORPORATE GOVERNANCE "} />

            <div className='mx-auto max-w-7xl py-8'>
                <div className='md:flex lg:flex xl:flex 2xl:flex'>
                    <div className='lg:px-6 md:px-6 xl:px-6 px-2 md:pb-6 lg:pb-6 xl:pb-6 pb-4 max-w-7xl w-full'>
                        <h3 class="my-6 text-lg text-gray-700 font-bold">CORPORATE GOVERNANCE REPORT</h3>
                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 gap-8 px-2">
                            {corporateGovernance.map(governances => (
                                <div key={governances.id} className="mb-4 text-md font-normal text-gray-500 border-2 p-3 border-gray-200/70 rounded-md">
                                    <a href={governances.PDF} target="_blank" rel="noopener noreferrer">
                                        <span className='text-gray-700 font-semibold'>{governances.Heading1} </span>{' - '} {governances.Heading2}  <span className='underline underline-offset-2 text-[#2baf2b]'>Download</span>
                                    </a>
                                </div>
                            ))}
                        </div>

                    </div>


                </div>
            </div>
        </div>
    );
};

export default CorporateGovernance;
