import React from 'react'
import CForm from './CForm'
import Careerss from './Careerss'

function Career() {
    const scrollToApplyForm = () => {
        const applyFormSection = document.getElementById('formsFill');
        if (applyFormSection) {
            applyFormSection.scrollIntoView({ behavior: 'smooth' });
        }
    };
    return (
        <div>
            <div className="bg-gray-50 py-3">
                <div className="container mx-auto flex flex-col items-center py-12 sm:py-24">
                    <div className="w-11/12 sm:w-2/3 lg:flex justify-center items-center flex-col  mb-5 sm:mb-10">
                        <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl text-center text-gray-800 font-black leading-7 md:leading-10">
                            Join Our Team and Shape the Future Together{` `}
                            <span className="text-[#2baf2b]">Educomp</span>
                        </h1>
                        <p className="mt-5 sm:mt-10 lg:w-10/12 text-gray-600 font-normal text-center text-sm sm:text-lg">Educomp adheres to the philosophy of EMPLOYEE FIRST by offering the appropriate talent with the correct sort of information, skill, support, empowerment, recognition, and growth.
                        </p>
                    </div>
                    <div className="flex justify-center items-center">
                        <button onClick={scrollToApplyForm} className="focus:outline-none focus:ring-2 focus:ring-offset-2 bg-[#2baf2b] transition duration-150 ease-in-out hover:bg-[#2baf2b]/80 lg:text-xl lg:font-bold  rounded text-white px-4 sm:px-10 border border-[#2baf2b] py-2 sm:py-4 text-sm">Join Us</button>
                    </div>
                </div>
            </div>

            <Careerss />

            <div id="formsFill" >
                <CForm />
            </div>
        </div>
    )
}

export default Career