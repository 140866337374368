export const policies = [
    {
        id: 1,
        Title: "Archival Policy",
        PDF: "https://educomp.com/Data/policies/archival-policy.pdf",
        isActive: 1
    },
    {
        id: 2,
        Title: "Corporate Social Responsibility policy",
        PDF: "https://educomp.com/Data/policies/corporate-social-responsibility-policy.pdf",
        isActive: 1
    },
    {
        id: 3,
        Title: "Policy on diversity",
        PDF: "https://educomp.com/Data/policies/policy-on-diversity.pdf",
        isActive: 1
    },
    {
        id: 4,
        Title: "Policy on Material Subsidiary",
        PDF: "https://educomp.com/Data/policies/policy-on-material-subsidiary.pdf",
        isActive: 1
    },
    {
        id: 5,
        Title: "Policy on Preservation of documents",
        PDF: "https://educomp.com/Data/policies/policy-on-preservation-of-documents.pdf",
        isActive: 1
    },
    {
        id: 6,
        Title: "Related Party Policy",
        PDF: "https://educomp.com/Data/policies/related-party-policy.pdf",
        isActive: 1
    },
    {
        id: 7,
        Title: "vigil mechanism",
        PDF: "https://educomp.com/Data/policies/vigil-mechanism.pdf",
        isActive: 1
    }
];