import React from 'react';
import Banner from '../../components/Banner';
import Navbar from '../../components/Navbar';
import UnpaidUnclaimed from '../../components/UnpaidUnclaimed/UnpaidUnclaimed';

function UnpaidAmounts() {
    return (
        <div>
            <Banner />
            <Navbar />
            <UnpaidUnclaimed />
        </div>
    )
}

export default UnpaidAmounts