import React, { useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import PropTypes from 'prop-types';

const ReCAPTCHAComponent = ({ onChange }) => {
  const recaptchaRef = useRef(null);

  return (
    <ReCAPTCHA
      ref={recaptchaRef}
      sitekey='6LfRn-8pAAAAANnZR-k23J5LGxWWf03_tFordmO1' //educomp
     // sitekey='6LdE6nYaAAAAAA5MJKOKljIwTI6ofca_IBi06R7M' //edumitram
      onChange={onChange}
    />
  );
};

ReCAPTCHAComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default ReCAPTCHAComponent;
