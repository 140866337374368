import React from 'react'
import GovernanceHero from '../governance/GovernanceHero'
import SideMenu from './SideMenu'

function BoardMeetings() {
    return (
        <div>
            <GovernanceHero headingText={'Corporate Announcements'} />

            <div className='mx-auto max-w-7xl py-8'>
                <div className='md:flex lg:flex xl:flex 2xl:flex'>
                    <div className='lg:px-6 md:px-6 xl:px-6 px-2 md:pb-6 lg:pb-6 xl:pb-6 pb-4 max-w-4xl w-full'>
                        <h2 class="my-4 text-2xl text-gray-700 font-bold">BOARD MEETINGS</h2>

                        <div class="relative overflow-x-auto  p-2">
                            <h2 class="my-4 text-xl text-gray-700 font-bold">FY 2016-17</h2>
                            <table class=" text-sm text-left rtl:text-right text-gray-500">
                                <tbody>
                                    <tr class="border-b border-gray-200 ">
                                        <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap  ">
                                            14-09-2016
                                        </th>
                                        <td class="px-6 py-4">
                                            Inter alia, to consider & approve the unaudited financial results of the Company for the quarter ended on June 30, 2016.
                                        </td>

                                    </tr>
                                    <tr class="border-b border-gray-200 ">
                                        <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap  ">
                                            12-08-2016
                                        </th>
                                        <td class="px-6 py-4">
                                            Inter alia, to consider & approve notice and date, time and place of the Annual General Meeting of the Company
                                        </td>

                                    </tr>
                                    <tr className="">
                                        <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap  ">
                                            26-05-2016
                                        </th>
                                        <td class="px-6 py-4">
                                            Inter alia, to consider and approve the standalone and consolidated audited financial results of the Company for the quarter and year ended on March 31, 2016.
                                            To consider and approve the draft Scheme of Arrangement between Edu Smart Services Private Limited and Educomp Solutions Limited.
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className='md:px-0 lg:px-0 xl:px-0 2xl:px-0 px-3.5'>
                        <SideMenu />
                    </div>
                </div>

                <div className='lg:px-6 md:px-6 xl:px-6 px-2 md:pb-6 lg:pb-6 xl:pb-6 pb-4 max-w-4xl'>
                    <h2 class="my-4 text-xl text-gray-700 font-bold">FY 2015-16</h2>
                    <div class="relative overflow-x-auto  p-2">
                        <table class=" text-sm text-left rtl:text-right text-gray-500">

                            <tbody>
                                <tr class="border-b border-gray-200 ">
                                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap  ">
                                        11-02-2016
                                    </th>
                                    <td class="px-6 py-4">
                                        Inter alia, to consider and approve the standalone and consolidated unaudited financial results of the company for the quarter and nine months ended on December 31, 2015.
                                    </td>

                                </tr>
                                <tr class="border-b border-gray-200 ">
                                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap  ">
                                        09-11-2015
                                    </th>
                                    <td class="px-6 py-4">
                                        Inter alia, to consider and approve the standalone and consolidated unaudited financial results of the company for the quarter ended on September 30, 2015.
                                    </td>

                                </tr>
                                <tr class="border-b border-gray-200 ">
                                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap  ">
                                        13-08-2015
                                    </th>
                                    <td class="px-6 py-4">
                                        Inter alia, to consider and approve the standalone and consolidated unaudited financial results of the company for the quarter ended on June 30, 2015.
                                    </td>
                                </tr>
                                <tr class="">
                                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap  ">
                                        28-05-2015
                                    </th>
                                    <td class="px-6 py-4">
                                        Inter alia, to consider and approve the standalone and consolidated audited financial results of the company for the financial year ended on March 31, 2015.
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <h2 class="my-4 text-xl text-gray-700 font-bold">FY 2014-15</h2>
                    <div class="relative overflow-x-auto  p-2">
                        <table class=" text-sm text-left rtl:text-right text-gray-500">
                            <tbody>
                                <tr class="border-b border-gray-200 ">
                                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap  ">
                                        13-02-2015
                                    </th>
                                    <td class="px-6 py-4">
                                        Inter alia, to consider and approve the standalone and consolidated unaudited financial results of the company for the quarter ended on December 31, 2014.
                                    </td>
                                </tr>

                                <tr class="border-b border-gray-200 ">
                                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap  ">
                                        13-01-2015
                                    </th>
                                    <td class="px-6 py-4">
                                        Inter alia to consider and approve the following:- <br />
                                        1. To issue Equity Shares of the Company, as per CDR Scheme of the company, in accordance with SEBI (ICDR) Regulations, 2009. <br />
                                        2. To consider Restructuring options pursuant to the CDR Scheme.
                                    </td>

                                </tr>
                                <tr class="border-b border-gray-200">
                                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap  ">
                                        14-11-2014
                                    </th>
                                    <td class="px-6 py-4">
                                        Inter alia, to consider and approve the standalone and consolidated unaudited financial results of the company for the quarter ended on September 30, 2014.
                                    </td>
                                </tr>
                                <tr class="border-b border-gray-200">
                                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap  ">
                                        12-08-2014
                                    </th>
                                    <td class="px-6 py-4">
                                        Inter alia, to consider and approve the standalone and consolidated unaudited financial results of the company for the quarter ended June 30, 2014.
                                    </td>
                                </tr>
                                <tr class="">
                                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap  ">
                                        26-05-2014
                                    </th>
                                    <td class="px-6 py-4">
                                        Inter alia, to consider and approve the standalone and consolidated audited financial results of the company for the financial year ended on March 31, 2014.
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <h2 class="my-4 text-xl text-gray-700 font-bold">FY 2013-14</h2>
                    <div class="relative overflow-x-auto  p-2">
                        <table class=" text-sm text-left rtl:text-right text-gray-500">

                            <tbody>
                                <tr class="border-b border-gray-200 ">
                                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap  ">
                                        14-02-2014
                                    </th>
                                    <td class="px-6 py-4">
                                        Inter alia, to consider and approve the Un-audited Financial Results of the Company for the quarter ended on December 31, 2013.
                                    </td>

                                </tr>
                                <tr class="border-b border-gray-200 ">
                                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap  ">
                                        13-11-2013
                                    </th>
                                    <td class="px-6 py-4">
                                        Inter alia to consider and approve Un-audited Financial Results of the Company for the quarter and half year ended on September 30, 2013.
                                    </td>

                                </tr>
                                <tr class="border-b border-gray-200">
                                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap  ">
                                        13-08-2013
                                    </th>
                                    <td class="px-6 py-4">
                                        Inter alia, to consider and approve the unaudited financial results of the company for the quarter ended on June 30, 2013
                                    </td>
                                </tr>
                                <tr class="">
                                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap  ">
                                        30-05-2013
                                    </th>
                                    <td class="px-6 py-4">
                                        Inter alia to consider and approve the standalone and consolidated audited financial results of the Company for the financial year ended on March 31, 2013
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>


                </div>
            </div>
        </div>
    )
}

export default BoardMeetings