import { Routes, Route } from 'react-router-dom';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import B2bPage from './pages/Solutions/B2bPage';
import B2gPage from './pages/Solutions/B2gPage';
import Overview from './pages/InvestorPages/Overview';
import Governance from './pages/InvestorPages/Governance';
import AnnualResults from './pages/InvestorPages/AnnualResults';
import AnnualReports from './pages/InvestorPages/AnnualReports';
import Shareholding from './pages/InvestorPages/Shareholding';
import CorporateGovernances from './pages/InvestorPages/CorporateGovernancePage';
import CorporateAnnoucement from './pages/InvestorPages/CorporateAnnoucement';
import StockPrices from './pages/InvestorPages/StockPrices';
import UnpaidAmounts from "./pages/InvestorPages/UnpaidAmounts";
import Disclosures from "./pages/InvestorPages/Disclosures";
import EmployeeStock from "./pages/InvestorPages/EmployeeStock";
import OtherDisclosures from "./pages/InvestorPages/OtherDisclosures";
import TermsAndConditionsPage from './pages/InvestorPages/GovernanceSubPages/TermsAndConditionsPage';
import ComitteeBoard from './pages/InvestorPages/GovernanceSubPages/ComitteeBoard';
import CodeOfConductPage from './pages/InvestorPages/GovernanceSubPages/CodeOfConductPage';
import PoliciesPage from './pages/InvestorPages/GovernanceSubPages/PoliciesPage';
import FamiliarisationPage from './pages/InvestorPages/GovernanceSubPages/FamiliarisationPage';
import DisclaimerPage from './pages/DisclaimerPage';
import Subsidiaries from './components/AnnualReports/Subsidiaries';
import AGMPostalPage from './pages/InvestorPages/AnnounceCorporate/AGMPostalPage';
import ResultsDisclosuresPage from './pages/InvestorPages/AnnounceCorporate/ResultsDisclosuresPage';
import CorporateActionsPage from './pages/InvestorPages/AnnounceCorporate/CorporateActionsPage';
import StockExchangeAnnouncmentPage from './pages/InvestorPages/AnnounceCorporate/StockExchangeAnnouncmentPage';
import NewspaperAdvertisementsPage from './pages/InvestorPages/AnnounceCorporate/NewspaperAdvertisementsPage';
import CareerPage from './pages/CareerPage';
import CorporateInsolvencyPage from './pages/CorporateInsolvencyPage';
import ReachOut from './pages/ReachOut';
import ResourcePage from './pages/ResourcePage';

function App() {
  return (
    <div>
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/about-us' element={<AboutPage />} />
        <Route path='/solutions/b2b-page' element={<B2bPage />} />
        <Route path='/solutions/b2g-page' element={<B2gPage />} />
        <Route path='/investors/overview' element={<Overview />} />
        <Route path='/investors/governance' element={<Governance />} />
        <Route path='/investors/terms-and-conditions-independent-directors' element={<TermsAndConditionsPage />} />
        <Route path={'/investors/code-to-conduct'} element={<CodeOfConductPage />} />
        <Route path={'/investors/policies'} element={<PoliciesPage />} />
        <Route path='/investors/committee-to-board' element={<ComitteeBoard />} />
        <Route path='/investors/familiarisation-programme' element={<FamiliarisationPage />} />
        <Route path='/investors/annual-results' element={<AnnualResults />} />
        <Route path='/investors/annual-reports' element={<AnnualReports />} />
        <Route path="/investors/subsidiaries" element={<Subsidiaries />} />
        <Route path='/investors/shareholding-patterns' element={<Shareholding />} />
        <Route path='/investors/corporate-governance-reports' element={<CorporateGovernances />} />
        <Route path='/announce-corporate/board-meetings' element={<CorporateAnnoucement />} />
        <Route path='/announce-corporate/agm-postal-ballots' element={<AGMPostalPage />} />
        <Route path='/announce-corporate/results-disclosures' element={<ResultsDisclosuresPage />} />
        <Route path='/announce-corporate/corporate-actions' element={<CorporateActionsPage />} />
        <Route path='/announce-corporate/stock-exchange-announcements' element={<StockExchangeAnnouncmentPage />} />
        <Route path='/announce-corporate/newspaper-advertisments' element={<NewspaperAdvertisementsPage />} />
        <Route path='/investors/stock-prices' element={<StockPrices />} />
        <Route path='/investors/unpaid-dividend-amounts' element={<UnpaidAmounts />} />
        <Route path='/investors/employee-stock-option-scheme' element={<EmployeeStock />} />
        <Route path='/investors/Other_Disclosures_as_per_Companies_Act_2013' element={<OtherDisclosures />} />
        <Route path='/reach-us' element={<ReachOut />} />

        <Route path='/investors/disclosures' element={<Disclosures />} />

        <Route path='/resources' element={<ResourcePage />} />
        <Route path='/right-reserved/disclaimer' element={<DisclaimerPage />} />
        <Route path='/educomp/careers' element={<CareerPage />} />
        <Route path='/educomp/corporate-insolvency' element={<CorporateInsolvencyPage />} />

      </Routes>
      <Footer />
    </div>
  );
}

export default App;
