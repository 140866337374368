import React from 'react';
import GovernanceHero from '../governance/GovernanceHero';
import { shareholding } from '../../js/Shareholding';


function ShareHolding() {
    return (
        <div>
            <GovernanceHero headingText={"Familiarisation"} />

            <div className='mx-auto max-w-7xl py-8'>
                <div className='md:flex lg:flex xl:flex 2xl:flex'>
                    <div className='lg:px-6 md:px-6 xl:px-6 px-2 md:pb-6 lg:pb-6 xl:pb-6 pb-4 max-w-6xl w-full'>
                        <h3 class="my-6 text-lg text-gray-700 font-bold">SHAREHOLDING PATTERN</h3>
                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-4 px-2">
                            {shareholding.map(shareholdings => (
                                <div key={shareholdings.id} className="mb-4 text-md font-normal text-gray-500">
                                    <a href={shareholdings.PDF} target="_blank" rel="noopener noreferrer">
                                        {shareholdings.Heading1} {' '} {shareholdings.Heading2} : <span className='underline underline-offset-2 text-[#2baf2b]'>Download</span>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default ShareHolding