export const insolvency = [
    {
        id: 1,
        Title: "NCLT Order approval of plan dated 09.10.2023",
        PDF: "https://www.educomp.com/Data/NCLT_Order_approval_of_plan_%20dated_09.10.2023.pdf",
        isActive: 1
    },
    {
        id: 2,
        Title: "NCLAT Judgement 29/07/2020",
        PDF: "https://educomp.com/Data/Judgment.pdf",
        isActive: 1
    },
    {
        id: 3,
        Title: "RFRP-revised timelines",
        PDF: "https://educomp.com/Data/ESL-RFRP-20012018-Revised-timelines.pdf",
        isActive: 1
    },
    {
        id: 4,
        Title: "Revised RFRP",
        PDF: "https://educomp.com/Data/ESL-RFRP-17012018.pdf",
        isActive: 1
    },
    {
        id: 5,
        Title: "ESL - RFRP",
        PDF: "https://educomp.com/Data/ESL-RFRP.pdf",
        isActive: 1
    },
    {
        id: 6,
        Title: "Educomp - Company Profile",
        PDF: "https://educomp.com/DataImages/Downloads/Educomp-Company-Profile.pdf",
        isActive: 1
    },
    {
        id: 7,
        Title: "EOI Extension",
        PDF: "https://educomp.com/Data/EOI_Extension.jpg",
        isActive: 1
    },
    {
        id: 8,
        Title: "Claims of Financial Creditors",
        PDF: "https://educomp.com/Data/Claim-of-Financial-Creditors.pdf",
        isActive: 1
    },
    {
        id: 9,
        Title: "Claims of Operational Creditors",
        PDF: "https://educomp.com/Data/Claim-of-Operational-Creditors.pdf",
        isActive: 1
    },
    {
        id: 10,
        Title: "Claims of Workmen and Employees",
        PDF: "https://educomp.com/Data/Claim-of-Workmen-and-Employees.pdf",
        isActive: 1
    },
    {
        id: 11,
        Title: "Eligibility criteria for Resolution Applicant",
        PDF: "https://educomp.com/Data/Eligibility_criteria_for_Resolution_Applicant.pdf",
        isActive: 1
    },
    {
        id: 12,
        Title: "EoI Advertisement",
        PDF: "https://educomp.com/Data/EoI_Advertisement_17102017.pdf",
        isActive: 1
    },
    {
        id: 13,
        Title: "ESL Notice Custody and Control",
        PDF: "https://educomp.com/Data/ESL_Notice_Custody_and_Control.pdf",
        isActive: 1
    },
    {
        id: 14,
        Title: "Form-B For Operational Creditors",
        PDF: "https://educomp.com/Data/Form-B-For_Operational_Creditors.pdf",
        isActive: 1
    },
    {
        id: 15,
        Title: "Form-C For Financial Creditors",
        PDF: "https://educomp.com/Data/Form-C-For_Financial_Creditors.pdf",
        isActive: 1
    },
    {
        id: 16,
        Title: "Form-D For Workmen and Employees",
        PDF: "https://educomp.com/Data/Form-D-For_Workmen_and_Employees.pdf",
        isActive: 1
    },
    {
        id: 17,
        Title: "Form-E For Authorised Representative of Workman and Employees",
        PDF: "https://educomp.com/Data/Form-E-For_Authorised_Representative_of_Workman_and_Employees.pdf",
        isActive: 1
    },
    {
        id: 18,
        Title: "Form-F Other than Financial Creditors and Operational Creditors",
        PDF: "https://educomp.com/Data/Form-F-Other_than_Financial_Creditors_and_Operational_Creditors.pdf",
        isActive: 1
    },
    {
        id: 19,
        Title: "Order dated (12-09-2017)",
        PDF: "https://educomp.com/Data/Order_dated_12092017.pdf",
        isActive: 1
    },
    {
        id: 20,
        Title: "Order dated (30-05-2017)",
        PDF: "https://educomp.com/Data/Order_dated_30052017.pdf",
        isActive: 1
    },
    {
        id: 21,
        Title: "Public Announcement Form-A",
        PDF: "https://educomp.com/Data/Public_Announcement_Form%20A.pdf",
        isActive: 1
    }
];