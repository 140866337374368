import React from 'react';
import svg1 from "../assets/images/board_questions.svg";
import svg2 from "../assets/images/case_study.svg";
import svg3 from "../assets/images/home_assignment.svg";
import svg4 from "../assets/images/interactivity.svg";
import svg5 from "../assets/images/numerical.svg";
import svg6 from "../assets/images/sample_papers.svg";
import svg7 from "../assets/images/textbook_solutions.svg";
import svg8 from "../assets/images/worksheet.svg";

function ContentPractice() {
    return (
        <div>
            <div class="h-auto">

                <div class=" flex flex-col items-center ">
                    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
                        <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">

                            <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none  text-gray-800 sm:text-5xl md:mx-auto  ">
                                <span className="relative inline-block">
                                    <svg
                                        viewBox="0 0 52 24"
                                        fill="currentColor"
                                        className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-[#2baf2b] lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
                                    >
                                        <defs>
                                            <pattern
                                                id="07690130-d013-42bc-83f4-90de7ac68f76"
                                                x="0"
                                                y="0"
                                                width=".135"
                                                height=".30"
                                            >
                                                <circle cx="1" cy="1" r=".8" />
                                            </pattern>
                                        </defs>
                                        <rect
                                            fill="url(#07690130-d013-42bc-83f4-90de7ac68f76)"
                                            width="52"
                                            height="24"
                                        />
                                    </svg>
                                    <h2 className="max-w-lg mb-6 font-sans lg:text-5xl xl:6xl md:3xl text-4xl font-semibold tracking-tight text-[#01295c] ">

                                        Practice
                                        <br className="hidden md:block" />

                                    </h2>
                                </span>{' '}

                            </h2>
                        </div>
                        <div className="grid max-w-screen-lg mx-auto space-y-6 lg:grid-cols-2 lg:space-y-0 lg:divide-x">
                            <div className="space-y-6 sm:px-16">
                                <div className="flex flex-col max-w-md sm:flex-row">
                                    <div className="mb-4 mr-4">
                                        <div className="flex items-center justify-center w-12 h-12 rounded-full bg-indigo-50">

                                            <img src={svg8} alt='worksheet' className="w-12 h-12 text-deep-purple-accent-400 sm:w-10 sm:h-10" />
                                        </div>
                                    </div>
                                    <div>
                                        <h6 className="mb-3 text-lg font-bold uppercase tracking-[1px] text-[#2baf2b]">
                                            Worksheets
                                        </h6>
                                        <p className="text-sm text-gray-800 font-normal">
                                            These are exercises mapped to chapter-topics that help students practice the concepts learned.
                                        </p>
                                    </div>
                                </div>
                                <div className="flex flex-col max-w-md sm:flex-row">
                                    <div className="mb-4 mr-4">
                                        <div className="flex items-center justify-center w-12 h-12 rounded-full bg-indigo-50">
                                            <img src={svg5} alt='numerical' className="w-12 h-12 text-deep-purple-accent-400 sm:w-10 sm:h-10" />
                                        </div>
                                    </div>
                                    <div>
                                        <h6 className="mb-3 text-lg font-bold uppercase tracking-[1px] text-[#2baf2b]">
                                            Numerical
                                        </h6>
                                        <p className="text-sm text-gray-800 font-normal">
                                            Numerical are available for Grades 9-12 Chemistry and Physics. Using these numerical, students can practice the application of learnt concepts.
                                        </p>
                                    </div>
                                </div>
                                <div className="flex flex-col max-w-md sm:flex-row">
                                    <div className="mb-4 mr-4">
                                        <div className="flex items-center justify-center w-12 h-12 rounded-full bg-indigo-50">
                                            <img src={svg6} alt='sample-papers' className="w-12 h-12 text-deep-purple-accent-400 sm:w-10 sm:h-10" />
                                        </div>
                                    </div>
                                    <div>
                                        <h6 className="mb-3 text-lg font-bold uppercase tracking-[1px] text-[#2baf2b]">
                                            Sample Papers
                                        </h6>
                                        <p className="text-sm text-gray-800 font-normal">
                                            These include solved sample papers in all subjects for various grades.
                                        </p>
                                    </div>
                                </div>
                                <div className="flex flex-col max-w-md sm:flex-row">
                                    <div className="mb-4 mr-4">
                                        <div className="flex items-center justify-center w-12 h-12 rounded-full bg-indigo-50">
                                            <img src={svg1} alt='board-questions' className="w-12 h-12 text-deep-purple-accent-400 sm:w-10 sm:h-10" />
                                        </div>
                                    </div>
                                    <div>
                                        <h6 className="mb-3 text-lg font-bold uppercase tracking-[1px] text-[#2baf2b]">
                                            Board Questions
                                        </h6>
                                        <p className="text-sm text-gray-800 font-normal">
                                            These are solved old board papers for grades 10 and 12.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="space-y-6 sm:px-16 md:block lg:block xl:block hidden">
                                <div className="flex flex-col max-w-md sm:flex-row">
                                    <div className="mb-4 mr-4">
                                        <div className="flex items-center justify-center w-12 h-12 rounded-full bg-indigo-50">
                                            <img src={svg4} alt='interactivities' className="w-12 h-12 text-deep-purple-accent-400 sm:w-10 sm:h-10" />
                                        </div>
                                    </div>
                                    <div>
                                        <h6 className="mb-3 text-lg font-bold uppercase tracking-[1px] text-[#2baf2b]">
                                            Interactivities
                                        </h6>
                                        <p className="text-sm text-gray-800 font-normal">
                                            These include interactive exercises across subjects and grades that help students to practice the concepts learned.
                                        </p>
                                    </div>
                                </div>
                                <div className="flex flex-col max-w-md sm:flex-row">
                                    <div className="mb-4 mr-4">
                                        <div className="flex items-center justify-center w-12 h-12 rounded-full bg-indigo-50">
                                            <img src={svg2} alt='case-studies' className="w-12 h-12 text-deep-purple-accent-400 sm:w-10 sm:h-10" />
                                        </div>
                                    </div>
                                    <div>
                                        <h6 className="mb-3 text-lg font-bold uppercase tracking-[1px] text-[#2baf2b]">
                                            Case Studies
                                        </h6>
                                        <p className="text-sm text-gray-800 font-normal">
                                            Grade 11-12 Business Studies has Case Studies mapped to each chapter for the real-life understanding of various concepts.
                                        </p>
                                    </div>
                                </div>
                                <div className="flex flex-col max-w-md sm:flex-row">
                                    <div className="mb-4 mr-4">
                                        <div className="flex items-center justify-center w-12 h-12 rounded-full bg-indigo-50">
                                            <img src={svg3} alt='home-assignments' className="w-12 h-12 text-deep-purple-accent-400 sm:w-10 sm:h-10" />
                                        </div>
                                    </div>
                                    <div>
                                        <h6 className="mb-3 text-lg font-bold uppercase tracking-[1px] text-[#2baf2b]">
                                            Home Assignments
                                        </h6>
                                        <p className="text-sm text-gray-800 font-normal">
                                            These are printable question sets that can be used as home assignments.
                                        </p>
                                    </div>
                                </div>
                                <div className="flex flex-col max-w-md sm:flex-row">
                                    <div className="mb-4 mr-4">
                                        <div className="flex items-center justify-center w-12 h-12 rounded-full bg-indigo-50">
                                            <img src={svg7} alt='textbook-solutions' className="w-12 h-12 text-deep-purple-accent-400 sm:w-10 sm:h-10" />
                                        </div>
                                    </div>
                                    <div>
                                        <h6 className="mb-3 text-lg font-bold uppercase tracking-[1px] text-[#2baf2b]">
                                            Textbook Solutions
                                        </h6>
                                        <p className="text-sm text-gray-800 font-normal">
                                            This type of content provides solutions of all the exercises of the textbook.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContentPractice