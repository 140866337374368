import React from 'react'
import Banner from '../../components/Banner'
import Navbar from '../../components/Navbar'
import B2g from '../../components/B2g'

function B2gPage() {
  return (
    <div>
      <Banner />
      <Navbar />
      <B2g />
    </div>
  )
}

export default B2gPage