import React from 'react';
import Banner from '../../components/Banner';
import Navbar from '../../components/Navbar';
import ReportsAnnual from '../../components/AnnualReports/ReportsAnnual';

function AnnualReports() {
    return (
        <div>
            <Banner />
            <Navbar />
            <ReportsAnnual />
        </div>
    )
}

export default AnnualReports