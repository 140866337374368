/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import image1 from "../assets/images/contact-us.svg"
import ReCAPTCHAComponent from './GoogleRecaptcha/googleCaptcha'

function ReachUs() {
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [address, setAddress] = useState('');
    const [subject, setSubject] = useState('Educomp - Enquiry');
    const [body, setBody] = useState('');
    const [file, setFile] = useState(null);
    const [category, setCategory] = useState('');
    const [phone, setPhone] = useState('');
    const [isCaptchaValid, setisCaptchaValid] = useState(false);
    const [buttonEnable, setButton] = useState(true);

    const handleCaptchaChange = (value) => {
        setisCaptchaValid(!!value);

    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setButton(false);
        setisCaptchaValid(false);

        const combinedBody = `
        <b class="font-bold">Name:</b> ${name}}<br>
        <b class="font-bold">Address:</b> ${address}<br>
        <b class="font-bold">Category:</b> ${category}<br>
        <b class="font-bold">Phone:</b> ${phone}<br><br>
        <b class="font-bold">Message:</b><br>${body.replace(/\n/g, '<br>')}
        `;

        const formData = new FormData();
        formData.append('ToEmail', email);
        formData.append('Subject', subject);
        formData.append('Body', combinedBody);
        if (file) {
            formData.append('Attachments', file);
        }

        try {
           // const response = await fetch('https://edmcorpservices.edumitram.co.in/api/Email/SendEducompCorp', {
                  const response = await fetch('https://corpapi.educomp.com/api/Email/SendEducompCorp', {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                const errorText = await response.text();
                console.error('Failed to send mail:', response.status, errorText);
                throw new Error('Failed to send mail');
            }
            setButton(true);
            setisCaptchaValid(true);

            toast.success('Mail sent successfully');

            setEmail('');
            setSubject('Educomp - Enquiry');
            setBody('');
            setFile(null);
            setCategory('');
            setPhone('');
            setName('');
            setAddress('');
          //  setisCaptchaValid(false);
        } catch (error) {
            console.error('Error sending mail:', error);
            toast.error('Failed to send mail');
            setButton(true);
            setisCaptchaValid(true);
        }
    };
    return (
        <div>
            <ToastContainer />
            <section className=" h-auto">
                <div id="map" className="relative h-[400px] overflow-hidden bg-cover bg-[50%] bg-no-repeat">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3505.925416913447!2d77.08776237495232!3d28.51189158954985!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d195049555555%3A0x9d05a4fb1daeb4ee!2sEducomp%20Solutions%20Limited.!5e0!3m2!1sen!2sin!4v1707479693240!5m2!1sen!2sin" width="100%" height="500" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
                <div className="container px-6 md:px-12 pb-24">
                    <div
                        className="block rounded-lg bg-[hsla(0,0%,100%,0.8)] px-6 py-12 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]  md:py-16 md:px-12 -mt-[70px] backdrop-blur-[30px] border border-gray-300">

                        <div className="w-full shrink-0 grow-0 basis-auto md:w-6/12 md:px-3 lg:w-full lg:px-6 xl:mb-5 xl:w-6/12">
                            <div className="align-start flex">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
                                </svg>


                                <div className="ml-2 grow">
                                    <p className="text-sm text-neutral-800 italic underline underline-offset-4"> Distance from New Delhi railway station: 22 KM & Distance from Indra Gandhi International Airport: 8 KM
                                    </p>
                                </div>
                            </div>
                        </div>
                        <h1 className='text-4xl py-2 text-[#2baf2b] md:px-6 lg:px-6 xl:px-6 px-2 font-bold'>Write to us</h1>
                        {/* <p className='max-w-2xl mb-4 md:px-6 lg:px-6 xl:px-6 px-2 text-sm italic'>
                            We value your comments and suggestions. To request a sales call, fix up an appointment and for any queries or suggestions, please fill the feedback form given below and submit. We will revert within 3 working days or earlier.</p> */}

                        <p className='max-w-lg mb-4 md:px-6 lg:px-6 text-gray-700 xl:px-6 px-2 text-xl font-bold'>
                            Tell us about yourself</p>
                        <div className="flex flex-wrap">
                            <div className="mb-12 w-full shrink-0 grow-0 basis-auto md:px-3 lg:mb-0 lg:w-5/12 lg:px-6">
                                <form onSubmit={handleSubmit}>
                                    <div className="mb-4 max-w-md">
                                        <label className="block mb-2 text-sm font-semibold text-gray-900">
                                            Email
                                            <input
                                                type="email"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                className="shadow-sm bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 mt-1 placeholder:italic placeholder:font-normal focus:outline-none"
                                                placeholder="username@gmail.com"
                                                required
                                            />
                                        </label>
                                    </div>
                                    <div className="mb-4 max-w-md">
                                        <label className="block mb-2 text-sm font-semibold text-gray-900">
                                            Organisation / Individual Name
                                            <input
                                                type="name"
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                                className="shadow-sm bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 mt-1 placeholder:italic placeholder:font-normal focus:outline-none"
                                                placeholder="Your name or your organisation name"
                                                required
                                            />
                                        </label>
                                    </div>
                                    <div className="mb-4 max-w-md">
                                        <label className="block mb-2 text-sm font-medium text-gray-900">
                                            Phone number
                                            <input
                                                type="tel"
                                                value={phone}
                                                onChange={(e) => setPhone(e.target.value)}
                                                className="shadow-sm bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 mt-1 placeholder:italic placeholder:font-normal focus:outline-none"
                                                placeholder="Your Phone number"
                                                required
                                            />
                                        </label>
                                    </div>
                                    <div className="mb-4 max-w-md">
                                        <label className="block mb-2 text-sm font-semibold text-gray-900">
                                            Address
                                            <input
                                                type="text"
                                                value={address}
                                                onChange={(e) => setAddress(e.target.value)}
                                                className="shadow-sm bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 mt-1 placeholder:italic placeholder:font-normal focus:outline-none"
                                                placeholder="Complete address with postal code"
                                                required
                                            />
                                        </label>
                                    </div>
                                    <div className="mb-4 max-w-md">
                                        <label className="block mb-2 text-sm font-medium text-gray-900">
                                            Select one:
                                            <select
                                                value={category}
                                                onChange={(e) => setCategory(e.target.value)}
                                                className="shadow-sm bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 mt-1"
                                                required
                                            >
                                                <option value="">Select Category</option>
                                                <option value="Corporate">Corporate</option>
                                                <option value="Government">Government</option>
                                                <option value="School">School</option>
                                                <option value="Individual/Self use">Individual/Self use</option>
                                                <option value="Consultant">Consultant</option>
                                                <option value="NGO/Foundation">NGO/Foundation</option>
                                            </select>
                                        </label>
                                    </div>
                                    <div className="mb-4 max-w-md">
                                        <label className="block mb-2 text-sm font-medium text-gray-900">
                                            Message
                                            <textarea
                                                value={body}
                                                onChange={(e) => setBody(e.target.value)}
                                                className="shadow-sm bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 mt-1 placeholder:italic placeholder:font-normal focus:outline-none"
                                                rows="6"
                                                placeholder="Message"
                                                required
                                            ></textarea>
                                        </label>
                                    </div>
                                    {/* <div className="mb-6 max-w-md ">
                                        <label className="block mb-2 text-sm font-medium text-gray-900">
                                            Attachment (optional)
                                            <input
                                                type="file"
                                                onChange={(e) => setFile(e.target.files[0])}
                                                className="block p-1 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer focus:outline-none"
                                            />
                                        </label>
                                    </div> */}

                                    <ReCAPTCHAComponent onChange={handleCaptchaChange} />

                                    <button
                                        type="submit"
                                        className={`mb-6 w-full rounded text-white px-6 pt-2 pb-2 mt-3 text-lg font-medium leading-normal lg:mb-0 max-w-md ${isCaptchaValid ? ' bg-[#2baf2b]' : 'bg-[#a5f2a5]'}`}
                                        disabled={!isCaptchaValid}
                                    >
                                        {buttonEnable ? 'Send' : 'Sending...'}
                                    </button>
                                    {/* <button className='px-2 py-2 bg-[#2baf2b]'>
                                    <div role="status">
                                        <svg aria-hidden="true" class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                        </svg>
                                        <span class="sr-only">Loading...</span>
                                    </div>

                                    </button> */}

                                </form>
                            </div>
                            <div className="w-full shrink-0 grow-0 basis-auto lg:w-7/12 bg-gray-200/40 rounded-lg p-3 text-black">
                                <div className="flex flex-wrap mt-5">
                                    <div className="mb-12 w-full shrink-0 grow-0 basis-auto md:w-6/12 md:px-3 lg:w-full lg:px-6 xl:w-6/12">
                                        <div className="flex items-start">
                                            <div className="shrink-0">
                                                <div className="inline-block rounded-full bg-[#2baf2b] md:p-4 text-primary p-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="md:h-6 md:w-6 text-white h-3 w-3">
                                                        <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
                                                    </svg>

                                                </div>
                                            </div>
                                            <div className="ml-6 grow">
                                                <p className="mb-1 font-bold  text-gray-800">
                                                    You can also Email us at
                                                </p>

                                                <p className="text-sm text-neutral-800 italic underline underline-offset-4 decoration-green-500">
                                                    <a href="mailto:enquiry@educomp.com">enquiry@educomp.com</a>
                                                </p>
                                                <p className="text-sm text-neutral-800 italic underline underline-offset-4 decoration-green-500">
                                                    <a href="mailto:connect@educomp.com">connect@educomp.com</a>
                                                </p>


                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-12 w-full shrink-0 grow-0 basis-auto md:w-6/12 md:px-3 lg:w-full lg:px-6 xl:w-6/12">
                                        <div className="flex items-start">
                                            <div className="srink-0">
                                                <div className="inline-block rounded-full bg-[#2baf2b] md:p-4 p-2 text-primary">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                                                        stroke="currentColor" className="md:h-6 md:w-6 text-white h-3 w-3">
                                                        <path stroke-linecap="round" stroke-linejoin="round"
                                                            d="M14.25 9.75v-4.5m0 4.5h4.5m-4.5 0l6-6m-3 18c-8.284 0-15-6.716-15-15V4.5A2.25 2.25 0 014.5 2.25h1.372c.516 0 .966.351 1.091.852l1.106 4.423c.11.44-.054.902-.417 1.173l-1.293.97a1.062 1.062 0 00-.38 1.21 12.035 12.035 0 007.143 7.143c.441.162.928-.004 1.21-.38l.97-1.293a1.125 1.125 0 011.173-.417l4.423 1.106c.5.125.852.575.852 1.091V19.5a2.25 2.25 0 01-2.25 2.25h-2.25z" />
                                                    </svg>
                                                </div>
                                            </div>
                                            <div className="ml-6 grow">
                                                <p className="mb-2 font-bold ">
                                                    Call us on
                                                </p>
                                                <p className="text-sm text-neutral-800 italic mt-2">
                                                    <span className='font-semibold underline underline-offset-2 decoration-green-500'>Landline</span> : 0124-4529000
                                                </p>
                                                {/* <p className='text-sm text-neutral-800 italic'>
                                                    <span className='font-semibold underline underline-offset-2 decoration-green-500'>Mobile</span> - +91 8826005644
                                                </p> */}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mb-12 w-full shrink-0 grow-0 basis-auto md:w-6/12 md:px-3 lg:w-full lg:px-6 xl:w-6/12">
                                        <div className="align-start flex">
                                            <div className="shrink-0">
                                                <div className="inline-block rounded-full bg-[#2baf2b] md:p-4 p-2 text-primary">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                                        stroke="currentColor" className="md:h-6 md:w-6 text-white h-3 w-3">
                                                        <path stroke-linecap="round" stroke-linejoin="round"
                                                            d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3" />
                                                    </svg>
                                                </div>
                                            </div>
                                            <div className="ml-6 grow">
                                                <p className="mb-2 font-bold ">More</p>
                                                <p className="text-sm text-neutral-800 italic"> Shareholders may also send
                                                    a mail to investor.services@educomp.com
                                                </p>
                                                <br />

                                            </div>
                                        </div>
                                    </div>

                                    <div className="mb-12 w-full shrink-0 grow-0 basis-auto md:w-6/12 md:px-3 lg:w-full lg:px-6 xl:w-6/12">
                                        <div className="flex items-start">
                                            <div className="srink-0">
                                                <div className="inline-block rounded-full bg-[#2baf2b] md:p-4 p-2 text-primary">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                                        stroke="currentColor" className="md:h-6 md:w-6 text-white h-3 w-3">
                                                        <path stroke-linecap="round" stroke-linejoin="round"
                                                            d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5zm6-10.125a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0zm1.294 6.336a6.721 6.721 0 01-3.17.789 6.721 6.721 0 01-3.168-.789 3.376 3.376 0 016.338 0z" />
                                                    </svg>
                                                </div>
                                            </div>
                                            <div className="ml-6 grow">
                                                <p className="mb-2 font-bold ">
                                                    Address
                                                </p>
                                                <p className='text-sm text-neutral-800 italic'> 514,
                                                    Udyog Vihar Phase III <br />
                                                    Gurgaon - 122001</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <hr className='border-b-2 border-green-500 m-2' />

                                <div className='flex justify-center bg-gradient-to-r from-cyan-200 to-green-400 p-2 rounded-md'>
                                    <img src={image1} alt="contact" className='h-56' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section></div>
    )
}

export default ReachUs