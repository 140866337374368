import React, { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReCAPTCHAComponent from './GoogleRecaptcha/googleCaptcha';
let links = ["/mail.txt"];

function CForm() {
    let [texts, setTexts] = React.useState("");
    let [mailbody, setMailBody] = React.useState("");

    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [location, setLocation] = useState('');
    const [department, setDepartment] = useState('');
    const [resume, setResume] = useState(null);
    const [resumename, setResumeName] = useState('');
    const [coverletter, setCoverletter] = useState(null);
    const [coverlettername, setCoverletterName] = useState('');
    const subject = 'Educomp - Career Enquiry';
    const [body, setBody] = useState('');
    const [file, setFile] = useState(null);
    const [isCaptchaValid, setIsCaptchaValid] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    React.useEffect(() => {
        async function main() {
            const files = await Promise.all(
                links.map((link) => fetch(link).then((res) => res.text()))
            );
            setTexts(files);
        }
        main();
    }, [setTexts]);

    const handleCaptchaChange = (value) => {
        setIsCaptchaValid(!!value);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);  // Starts loading here
        const newText = texts.toString().replace("@name", name).replace("@name", name).replace("@email", email).replace("@location", location).replace("@department", department).replace("@message", body);
        setMailBody(newText);
        const formData = new FormData();
        formData.append('ToEmail', email);
        formData.append('Subject', subject);
        formData.append('Body', newText);
        if (resume) {
            formData.append('Attachments', resume);
        }
        if (coverletter) {
            formData.append('Attachments', coverletter);
        }

        try {
           // const response = await fetch('https://edmcorpservices.edumitram.co.in/api/Email/SendEducompCorp', {
                const response = await fetch('https://corpapi.educomp.com/api/Email/SendEducompCorp', {    
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                throw new Error('Failed to send data');
            }

            toast.success('Mail sent successfully');

            // Reset form fields
            setEmail('');
            setName('');
            setLocation('');
            setDepartment('');
            setResumeName('');
            setResume(null);
            setCoverletter(null);
            setCoverletterName('');
            setBody('');
            setFile(null);
            setTexts('');
            // setIsCaptchaValid(false);
        } catch (error) {
            toast.error('Failed to send mail');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="rounded-lg px-8 py-2 mx-auto my-4 max-w-7xl">
            <ToastContainer />
            <h1 className="text-xl font-normal mb-4 italic">We also believe in providing an ideal environment for those who are passionate about self and organisational growth via contributions to the development and change of fellow humans and society.</h1>
            <h2 className="text-lg font-extrabold mb-4 underline underline-offset-4 text-[#01295c]">Fill out this form!</h2>
            <form onSubmit={handleSubmit}>
                <div className='md:flex lg:flex xl:flex flex-none md:gap-2 lg:gap-2 xl:gap-2'>
                    <div className="mb-4 w-full">
                        <label htmlFor="name" className="block text-[#2baf2b] font-bold mb-1">Name *</label>
                        <input type="text" id="name" name="name" value={name} onChange={(e) => setName(e.target.value)} className="border border-gray-200 p-2 w-full rounded-lg focus:outline-none focus:border-blue-400 bg-gray-100 placeholder:text-gray-400 placeholder:italic" placeholder='Name' required disabled={isLoading} />
                    </div>
                    <div className="mb-4 w-full">
                        <label htmlFor="email" className="block text-[#2baf2b] font-bold mb-1">Email *</label>
                        <input type="email" id="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} className="border border-gray-200 bg-gray-100 p-2 w-full rounded-lg focus:outline-none focus:border-blue-400 placeholder:text-gray-400 placeholder:italic" placeholder='john@gmail.com' required disabled={isLoading} />
                    </div>
                    <div className="mb-4  w-full">
                        <label htmlFor="location" className="block text-[#2baf2b] font-bold mb-1">Location *</label>
                        <input type="text" id="location" name="location" value={location} onChange={(e) => setLocation(e.target.value)} className="border border-gray-200 p-2 w-full rounded-lg focus:outline-none bg-gray-100 focus:border-blue-400 placeholder:text-gray-400 placeholder:italic" placeholder='Current location' required disabled={isLoading} />
                    </div>
                </div>
                <div className='md:flex lg:flex xl:flex flex-none md:gap-2 lg:gap-2 xl:gap-2'>
                    <div className="mb-4 w-full">
                        <label htmlFor="department" className="block text-[#2baf2b] font-bold mb-1">Department *</label>
                        <select id="department" name="department" value={department}
                            onChange={(e) => setDepartment(e.target.value)} className="border border-gray-200 bg-gray-100 p-2 w-full rounded-lg focus:outline-none focus:border-blue-400" required disabled={isLoading}>
                            <option value="">Select department</option>
                            <option value="Accounting & Finance">Accounting & Finance</option>
                            <option value="Sales & Marketing">Sales & Marketing</option>
                            <option value="Legal">Legal</option>
                            <option value="Human Resources">Human Resources</option>
                            <option value="Administration & Procurement">Administration & Procurement</option>
                            <option value="IT">IT</option>
                            <option value="Creative">Creative</option>
                            <option value="Subject Matter Expert">Subject Matter Expert</option>
                        </select>
                    </div>
                    <div className="mb-4 w-full">
                        <label htmlFor="resume" className="block text-[#2baf2b] font-bold mb-1">Resume *</label>
                        <input type="file" id="resume" value={resumename}
                            onChange={(e) => { setResume(e.target.files[0]); setResumeName(e.target.value) }} name="resume" className="border border-gray-200 bg-gray-100 p-2 w-full rounded-lg focus:outline-none focus:border-blue-400" required disabled={isLoading} />
                    </div>
                    <div className="mb-4 w-full">
                        <label htmlFor="coverLetter"
                            className="block text-[#2baf2b] font-bold mb-1">Cover Letter</label>
                        <input type="file" value={coverlettername} onChange={(e) => { setCoverletter(e.target.files[0]); setCoverletterName(e.target.value) }} id="coverLetter" name="coverLetter" className="border border-gray-200 bg-gray-100 p-2 w-full rounded-lg focus:outline-none focus:border-blue-400" disabled={isLoading} />
                    </div>
                </div>
                <div className="mb-4">
                    <label htmlFor="message" className="block text-[#2baf2b] font-bold mb-1">Message</label>
                    <textarea id="message" value={body} onChange={(e) => setBody(e.target.value)} name="message" className="border border-gray-200 bg-gray-100 p-2 w-full rounded-lg focus:outline-none focus:border-blue-400 placeholder:text-gray-400 placeholder:italic" placeholder='Briefly mention' rows="5" required disabled={isLoading}></textarea>
                </div>
                <ReCAPTCHAComponent onChange={handleCaptchaChange} />

                <div>
                    <button type="submit" className={`text-white px-4 py-2 mt-3 rounded-lg ${isCaptchaValid ? 'bg-[#2baf2b] hover:bg-[#2baf2b]/70' : 'bg-[#91f291]'} ${isLoading ? 'cursor-not-allowed' : ''}`} disabled={!isCaptchaValid || isLoading}>
                        {isLoading ? 'Submitting...' : 'Submit'}
                    </button>
                </div>
            </form>
        </div>
    );
}

export default CForm;
