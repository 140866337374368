export const corporateGovernance = [
    {
        id: 1,
        Heading1: "FY 2016-17",
        Heading2: "April - June, 2016",
        PDF: "https://educomp.com/Data/CGR-April-June-2016.pdf",
        isActive: 1
    },
    {
        id: 2,
        Heading1: "FY 2016-17",
        Heading2: "July - September, 2016",
        PDF: "https://educomp.com/Data/CGR-30-09-2016-ESL",
        isActive: 1
    },
    {
        id: 3,
        Heading1: "FY 2016-17",
        Heading2: "October - December, 2016",
        PDF: "https://educomp.com/Data/CGR-Oct-Dec-2016.pdf",
        isActive: 1
    },
    {
        id: 4,
        Heading1: "FY 2015-16",
        Heading2: "April - June, 2015",
        PDF: "https://educomp.com/Data/CGR-April-June-2015.pdf",
        isActive: 1
    },
    {
        id: 5,
        Heading1: "FY 2015-16",
        Heading2: "July - September, 2015",
        PDF: "https://educomp.com/Data/CGR-July-Sep-2015.pdf",
        isActive: 1
    },
    {
        id: 6,
        Heading1: "FY 2015-16",
        Heading2: "October - December, 2015",
        PDF: "https://educomp.com/Data/CGR-Oct-Dec-2015.pdf",
        isActive: 1
    },
    {
        id: 7,
        Heading1: "FY 2015-16",
        Heading2: "January - March, 2016",
        PDF: "https://educomp.com/Data/CGR-Q4-31.03.2016.pdf",
        isActive: 1
    },
    {
        id: 8,
        Heading1: "FY 2014-15",
        Heading2: "April - June, 2014",
        PDF: "https://educomp.com/Data/CGR-April-June-2014.pdf",
        isActive: 1
    },
    {
        id: 9,
        Heading1: "FY 2014-15",
        Heading2: "July - September, 2014",
        PDF: "https://educomp.com/Data/CGR-July-Sep-2014.pdf",
        isActive: 1
    },
    {
        id: 10,
        Heading1: "FY 2014-15",
        Heading2: "October - December, 2014",
        PDF: "https://educomp.com/Data/CGR-Oct-Dec-2014.pdf",
        isActive: 1
    },
    {
        id: 11,
        Heading1: "FY 2014-15",
        Heading2: "January - March, 2015",
        PDF: "https://educomp.com/Data/CGR-Jan-March-2015.pdf",
        isActive: 1
    }
];