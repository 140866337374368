import React from 'react'
import Resource from '../components/Resource'
import Banner from '../components/Banner'
import Navbar from '../components/Navbar'

function ResourcePage() {
    return (
        <div>
            <Banner />
            <Navbar />
            <Resource />
        </div>
    )
}

export default ResourcePage