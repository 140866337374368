import React from 'react';
import { Link, useLocation } from 'react-router-dom';

function GovernanceMain() {
    const location = useLocation();

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    const isLinkActive = (pathname) => {
        // Check if the current pathname matches the link
        return location.pathname === pathname;
    };


    return (
        <div>
            <div className='max-w-7xl mx-auto'>
                <div className="px-4 py-10 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-6 lg:py-6">
                    <h2 class="text-5xl font-extrabold text-[#2baf2b]">Board of Directors</h2>
                </div>
                <div className='md:flex lg:flex xl:flex 2xl:flex'>
                    <div className='lg:px-6 md:px-6 xl:px-6 px-2 md:pb-6 lg:pb-6 xl:pb-6 pb-4 max-w-4xl w-full'>
                        {/* <h2 class="my-4 text-2xl text-gray-700 font-bold">SHANTANU PRAKASH</h2>
                        <p class="mb-4 text-md font-normal text-gray-500 ">Mr. Shantanu Prakash, MBA (IIM- Ahmedabad) is the Chairman and Managing Director of Educomp Solutions Limited. He founded Educomp Solutions Limited in 1994, a few years after acquiring an MBA degree from the Indian Institute of Management, Ahmedabad.</p>
                        <p class="mb-4 text-md font-normal text-gray-500 ">Shantanu's vision has been to transform the teaching-learning process through the use of technology and best practices.
                            Shantanu is also the Founder and Managing Trustee of the Learning Leadership Foundation (LLF), an organisation dedicated to bringing best practices in education to under-resourced schools.</p>

                        <p class="mb-4 text-md font-normal text-gray-500 ">Shantanu is a charter member of TiE (The Indus Entrepreneurs), an organisation that connects entrepreneurs. He is a frequent speaker in education and business conferences worldwide.</p> */}

                        <div className='lg:px-6 md:px-6 xl:px-6 px-2 md:pb-6 lg:pb-6 xl:pb-6 pb-4 max-w-5xl'>
                            <h2 class="my-4 text-xl text-gray-700 font-bold">
                                Appointment/Resignation of Directors
                            </h2>
                            <ul class="mb-4 text-md font-normal text-gray-500 list-disc list-inside space-y-2">
                                <li>Shareholder of the company in their Annual General Meeting held on September 29, 2014 has appointed Ms. Swati Sinha as Director (Category-Independent/Non-Executive) of the Company with effect from September 29, 2014.</li>
                                <li>Board of Directors of the Company has appointed Ms. Azra Shauqia Hasan as Additional Director (Category - Independent/Non-Executive) of the Company with effect from May 28, 2015.</li>

                                <li>Mr. Shonu Chandra, Non-Executive - Independent Director, due to preoccupation, has tendered resignation as Director of the Company with effect from May 29, 2015. <a href="https://www.educomp.com/Data/Resignation-Shonu-Chandra.pdf" target="_blank" rel="noreferrer" className='text-blue-600'>“Download</a>  Resignation letter of Shonu Chandra”</li>
                                <li>Mr. Rajat Khare, Non-Executive - Independent Director, due to preoccupation, has tendered resignation as Director of the Company with effect from May 27, 2016. <a href="https://www.educomp.com/Data/Resignation_Letter_Rajat_Khare.pdf" target="_blank" rel="noreferrer" className='text-blue-600'>“Download</a> Resignation letter of Rajat Khare”</li>
                                <li>Mr. Venkata Subbarao Valluri, Non-Executive - Independent Director, due to preoccupation, has tendered resignation as Director of the Company with effect from May 27, 2016. <a href="https://www.educomp.com/Data/Resignation_Letter_Venkata_Subbarao_Valluri.pdf" target="_blank" rel="noreferrer" className='text-blue-600'>“Download</a> Resignation letter of Venkata Subbarao Valluri”</li>
                                <li>Board of Directors of the Company, vide resolution by circulation on 16th July 2016, has appointed Mr. Sanjay Kumar Bhattacharyya as Additional Director (Category - Independent/Non-Executive) of the Company.</li>
                            </ul>
                        </div>

                    </div>

                    <div className='md:px-2 lg:px-2 xl:px-2 2xl:px-2 px-3.5 md:mb-2 lg:mb-2 xl:mb-2 mb-4'>

                        <a href="/" class="block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-50">

                            <h5 class="mb-2 text-xl font-bold tracking-tight text-gray-700 ">Governance</h5>
                            <ul class="font-normal text-gray-700 list-disc  list-inside space-y-2">
                                <li className={`${isLinkActive('/investors/governance') ? 'font-bold text-blue-600' : ''}`}>

                                    <Link
                                        onClick={scrollToTop}
                                        to={`/investors/governance`}>
                                        Board of Directors
                                    </Link>
                                </li>
                                <li className={`${isLinkActive('/investors/terms-and-conditions-independent-directors') ? 'font-bold text-blue-600' : ''}`}>
                                    <Link
                                        onClick={scrollToTop}
                                        to={`/investors/terms-and-conditions-independent-directors`}>
                                        Terms & Conditions for appointment of Independent Directors
                                    </Link>
                                </li>
                                <li className={`${isLinkActive('/investors/committee-to-board') ? 'font-bold text-blue-600' : ''}`}>
                                    <Link
                                        onClick={scrollToTop}
                                        to={`/investors/committee-to-board`}>
                                        Committees to the Board
                                    </Link>
                                </li>
                                <li className={`${isLinkActive('/investors/code-to-conduct') ? 'font-bold text-blue-600' : ''}`}>
                                    <Link
                                        onClick={scrollToTop}
                                        to={`/investors/code-to-conduct`}>
                                        Code of Conduct
                                    </Link>
                                </li>
                                <li className={`${isLinkActive('/investors/policies') ? 'font-bold text-blue-600' : ''}`}>
                                    <Link
                                        onClick={scrollToTop}
                                        to={`/investors/policies`}>
                                        Policies
                                    </Link>
                                </li>
                                <li className={`${isLinkActive('/investors/familiarisation-programme') ? 'font-bold text-blue-600' : ''}`}>
                                    <Link
                                        onClick={scrollToTop}
                                        to={`/investors/familiarisation-programme`}>
                                        Familiarisation Programme
                                    </Link>
                                </li>
                            </ul>
                        </a>

                    </div>
                </div>

                {/* <div className='lg:px-6 md:px-6 xl:px-6 px-2 md:pb-6 lg:pb-6 xl:pb-6 pb-4 max-w-5xl'>
                    <h2 class="my-4 text-2xl text-gray-700 font-bold">V K DANDONA</h2>
                    <p class="mb-4 text-md font-normal text-gray-500 ">Mr. V K Dandona, was a highly decorated Police officer with Police medals awarded by the Hon'ble Prime Minister / President of India for 37 years of highly eventful, meritorious and distinguished service, having served all over the country handling crisis managements and multiple tasks of highly classified nature had been his strong forte, besides invaluable experience of tackling political and Bureaucratic machinery of the States / Govt. of India. He retired from service as the Inspector General of Police (IG).</p>

                </div> */}

                {/* <div className='lg:px-6 md:px-6 xl:px-6 px-2 md:pb-6 lg:pb-6 xl:pb-6 pb-4 max-w-5xl'>
                    <h2 class="my-4 text-2xl text-gray-700 font-bold">VIJAY K CHOUDHARY</h2>
                    <p class="mb-4 text-md font-normal text-gray-500 ">Mr. Vijay K Choudhary has been actively involved in the profession of Accountancy, Auditing, Taxation, Corporate structuring, Project Finance, Evaluating & Consultancy, Management consultancy. Activities under this cell consist of Project Report Preparation, Project Implementation, Planning, Preparation of Accounting & Management Manuals and development of all types of Management Information System for the clients, Merchant Banking, Money changers, Management of mergers, demergers, amalgamations, company law matters etc.</p>

                    <p class="mb-4 text-md font-normal text-gray-500 ">Mr. Choudhary had a privilege of being a speaker. From time to time, he had been requested by the agency likes Star TV to share the expertise on the tax laws. During his stint of more than 33 years, he has undertaken the audit of various public, private and multinational companies</p>

                </div> */}
            </div>

        </div>
    )
}

export default GovernanceMain