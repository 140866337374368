import React, { useState } from 'react';
import video1 from '../assets/videos/Class1_ 5.mp4';
import video2 from '../assets/videos/Class1_55.mp4';
import video3 from '../assets/videos/Class6_8.mp4';
import video4 from '../assets/videos/Class6_88.mp4';
import video5 from '../assets/videos/Class8_12.mp4';
import video6 from '../assets/videos/Class8_112.mp4';
import video7 from '../assets/videos/Class8_122.mp4';
import video9 from "../assets/videos/Air-Poem.mp4";
import video10 from "../assets/videos/Introduction_to_Robotics.mp4";
import video11 from "../assets/videos/Aloo.mp4";
import video8 from "../assets/videos/Prenursery.mp4";
import image1 from "../assets/images/finance.png";
import image2 from "../assets/images/structure-function.jpg";
import myhouse from "../assets/images/myhouse.png"
import comp from "../assets/images/Public_Composition.png";
import trade from "../assets/images/trade_company.png";
import geo from "../assets/images/geography.png";
import project from "../assets/images/project-work.png";
import evs from "../assets/images/evs.png";
import childfood from "../assets/videos/Child s Need Food.mp4"

function Resource() {
    const [selectedPDF, setSelectedPDF] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);

    const openModal = (pdf) => {
        setSelectedPDF(pdf);
        setModalOpen(true);
    };

    const closeModal = () => {
        setSelectedPDF(null);
        setModalOpen(false);
    };
    return (
        <div className=''>
            <div className='max-w-7xl mx-auto py-8'>
                <h1 className='text-md font-extrabold px-4 py-2 text-[#01295c] ' >
                    <span>
                        <span className='md:text-lg lg:text-[20px] xl:text-[20px] 2xl:text-[20px] text-md font-extrabold'> Demo Videos </span>
                    </span> {` `} - <span className='underline underline-offset-2 decoration-green-500'> Pre Nursery - K.G.</span></h1>
                <hr className='px-4 border-b ' />
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-5 p-4 ">
                    <div className="group cursor-pointer relative">
                        <video
                            className="md:max-w-4xl lg:max-w-4xl xl:max-w-4xl w-full h-full 2xl:max-w-4xl mx-auto p-2 justify-between  rounded-lg "
                            controls
                            controlsList="nodownload"
                        >
                            <source src={video8} type="video/mp4" />
                        </video>
                        <h1 className='text-md font-extrabold px-4 py-2'>After Bath</h1>
                    </div>

                    <div className="group cursor-pointer relative">
                        <video
                            className="md:max-w-4xl lg:max-w-4xl xl:max-w-4xl w-full h-full 2xl:max-w-4xl mx-auto p-2 justify-between rounded-lg"
                            controls
                            controlsList="nodownload"
                        >
                            <source src={video11} type="video/mp4" />
                        </video>
                        <h1 className='text-md font-extrabold px-4 py-2'>आलू-कचालू बेटा, कहाँ गए थे?</h1>
                    </div>

                    <div className="group cursor-pointer relative">
                        <video
                            className="md:max-w-4xl lg:max-w-4xl xl:max-w-4xl w-full h-full 2xl:max-w-4xl mx-auto p-2 justify-between rounded-lg"
                            controls
                            controlsList="nodownload"
                        >
                            <source src={video9} type="video/mp4" />
                        </video>
                        <h1 className='text-md font-extrabold px-4 py-2'>Air</h1>
                    </div>


                </div>
            </div>

            <div className='max-w-7xl mx-auto py-8'>
                <h1 className='text-md font-extrabold px-4 py-2 text-[#01295c] ' >
                    <span>
                        <span className='md:text-lg lg:text-[20px] xl:text-[20px] 2xl:text-[20px] text-md font-extrabold'> Demo Videos </span>
                    </span> {` `} - <span className='underline underline-offset-2 decoration-green-500'> Class 1 - 5</span></h1>
                <hr className='px-4 border-b ' />
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-5 p-4">
                    <div className="cursor-pointer">
                        <video className="md:max-w-4xl lg:max-w-4xl xl:max-w-4xl w-full h-full 2xl:max-w-4xl mx-auto p-2 justify-between rounded-lg" controls controlsList="nodownload">
                            <source src={video1} type="video/mp4" />
                        </video>
                        <h1 className='text-md font-extrabold px-4 py-2'>Domestic Animals</h1>
                    </div>
                    <div className="cursor-pointer">
                        <video className="md:max-w-4xl lg:max-w-4xl xl:max-w-4xl w-full h-full 2xl:max-w-4xl mx-auto p-2 justify-between rounded-lg" controls controlsList="nodownload">
                            <source src={video2} type="video/mp4" />
                        </video>
                        <h1 className='text-md font-extrabold px-4 py-2'>Double Bar Graph</h1>
                    </div>
                    <div className="cursor-pointer">
                        <video className="md:max-w-4xl lg:max-w-4xl xl:max-w-4xl w-full h-full 2xl:max-w-4xl mx-auto p-2 justify-between rounded-lg" controls controlsList="nodownload">
                            <source src={childfood} type="video/mp4" />
                        </video>
                        <h1 className='text-md font-extrabold px-4 py-2'>Child's Need Food</h1>
                    </div>
                </div>
            </div>

            <div className='max-w-7xl mx-auto py-8'>
                <h1 className='text-md font-extrabold px-4 py-2 text-[#01295c] ' >
                    <span>
                        <span className='md:text-lg lg:text-[20px] xl:text-[20px] 2xl:text-[20px] text-md font-extrabold'> Demo Videos </span>
                    </span> {` `} - <span className='underline underline-offset-2 decoration-green-500'> Class 6 - 8</span></h1>
                <hr className='px-4 border-b ' />
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-5 p-4 ">
                    <div className="cursor-pointer">
                        <video className="md:max-w-4xl lg:max-w-4xl xl:max-w-4xl w-full h-full 2xl:max-w-4xl mx-auto p-2 justify-between rounded-lg" controls controlsList="nodownload">
                            <source src={video3} type="video/mp4" />
                        </video>
                        <h1 className='text-md font-extrabold px-4 py-2'>Mughal Empire in 17th Centurye</h1>
                    </div>
                    <div className="cursor-pointer">
                        <video className="md:max-w-4xl lg:max-w-4xl xl:max-w-4xl w-full h-full 2xl:max-w-4xl mx-auto p-2 justify-between rounded-lg" controls controlsList="nodownload">
                            <source src={video4} type="video/mp4" />
                        </video>
                        <h1 className='text-md font-extrabold px-4 py-2'>Major Landforms of the Earth</h1>
                    </div>

                    <div className="cursor-pointer">
                        <video className="md:max-w-4xl lg:max-w-4xl xl:max-w-4xl w-full h-full 2xl:max-w-4xl mx-auto p-2 justify-between rounded-lg" controls controlsList="nodownload">
                            <source src={video10} type="video/mp4" />
                        </video>
                        <h1 className='text-md font-extrabold px-4 py-2'>Introduction to Robotics</h1>
                    </div>
                </div>
            </div>

            <div className='max-w-7xl mx-auto py-10 mb-2'>
                <h1 className='text-md font-extrabold px-4 py-2 text-[#01295c] ' >
                    <span>
                        <span className='md:text-lg lg:text-[20px] xl:text-[20px] 2xl:text-[20px] text-md font-extrabold'> Demo Videos </span>
                    </span> {` `} - <span className='underline underline-offset-2 decoration-green-500'> Class 9 - 12</span></h1>
                <hr className='px-4 border-b ' />
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-5 p-4 ">
                    <div className="cursor-pointer">
                        <video className="md:max-w-4xl lg:max-w-4xl xl:max-w-4xl w-full h-full 2xl:max-w-4xl mx-auto p-2 justify-between rounded-lg" controls controlsList="nodownload" poster={image1}>
                            <source src={video5} type="video/mp4" />
                        </video>
                        <h1 className='text-md font-extrabold px-4 py-2'>Financial Management</h1>
                    </div>
                    <div className="cursor-pointer">
                        <video className="md:max-w-4xl lg:max-w-4xl xl:max-w-4xl w-full h-full 2xl:max-w-4xl mx-auto p-2 justify-between rounded-lg" controls controlsList="nodownload">
                            <source src={video6} type="video/mp4" />
                        </video>
                        <h1 className='text-md font-extrabold px-4 py-2'>Meaning and Importance of Yoga</h1>
                    </div>
                    <div className="cursor-pointer">
                        <video className="md:max-w-4xl lg:max-w-4xl xl:max-w-4xl w-full h-full 2xl:max-w-4xl mx-auto p-2 justify-between rounded-lg" controls controlsList="nodownload" poster={image2}>
                            <source src={video7} type="video/mp4" />
                        </video>
                        <h1 className='text-md font-extrabold px-4 py-2'>Structures & Functions of Circulatory system of Heart</h1>
                    </div>
                </div>
            </div>


            <div className='max-w-7xl mx-auto py-8'>

                <h1 className='text-md font-extrabold px-4 py-2 text-[#01295c] ' >
                    <span>
                        <span className='md:text-lg lg:text-[20px] xl:text-[20px] 2xl:text-[20px] text-md font-extrabold'> Demo Content </span>
                    </span> {` `} - <span className='underline underline-offset-2 decoration-green-500'> Worksheets, Project Works, Classroom Activities & Notes</span></h1>

                <hr className='px-4 border-b ' />
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-5 p-4 mt-4">
                    <div className="group cursor-pointer relative border-2 rounded-lg text-center overflow-hidden" onClick={() => openModal('./My_House.pdf')}>
                        <img
                            className="w-full h-40 object-cover sm:h-44 md:h-48 lg:h-52 xl:h-56 rounded-lg mx-auto p-2 transform transition duration-300 group-hover:scale-105 group-hover:blur-sm"
                            src={myhouse}
                            alt="My-House"
                        />
                        <h1 className='text-md font-extrabold px-4 py-2'>Worksheet - My House</h1>

                        <span className="absolute inset-0 flex items-center justify-center bg-[#01295c] bg-opacity-50 text-white font-bold text-lg rounded-lg opacity-0 group-hover:opacity-100 transition-opacity duration-150">
                            Open PDF
                        </span>
                    </div>



                    <div className="group cursor-pointer relative border-2 rounded-lg text-center overflow-hidden" onClick={() => openModal('./Picture_Composition.pdf')}>
                        <img
                            className="w-full h-40 object-cover sm:h-44 md:h-48 lg:h-52 xl:h-56 rounded-lg mx-auto p-2 transform transition duration-300 group-hover:scale-105 group-hover:blur-sm"
                            src={comp}
                            alt="Public-Composition"
                        />
                        <h1 className='text-md font-extrabold px-4 py-2'>Worksheet - Public Composition</h1>

                        <span className="absolute inset-0 flex items-center justify-center bg-[#01295c] bg-opacity-50 text-white font-bold text-lg rounded-lg opacity-0 group-hover:opacity-100 transition-opacity duration-150">
                            Open PDF
                        </span>
                    </div>



                    <div className="group cursor-pointer relative border-2 rounded-lg text-center overflow-hidden" onClick={() => openModal('./Trade_to_Territory_The_Company_Establishes_Power.pdf')}>
                        <img
                            className="w-full h-40 object-cover sm:h-44 md:h-48 lg:h-52 xl:h-56 rounded-lg mx-auto p-2 transform transition duration-300 group-hover:scale-105 group-hover:blur-sm"
                            src={trade}
                            alt="From Trade to Territory"
                        />
                        <h1 className='text-md font-extrabold px-4 py-2'>Notes - From Trade to Territory</h1>

                        <span className="absolute inset-0 flex items-center justify-center bg-[#01295c] bg-opacity-50 text-white font-bold text-lg rounded-lg opacity-0 group-hover:opacity-100 transition-opacity duration-150">
                            Open PDF
                        </span>
                    </div>


                </div>
            </div>


            <div className='max-w-7xl mx-auto mb-4'>

                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-5 p-4 ">

                    <div className="group cursor-pointer relative border-2 rounded-lg text-center overflow-hidden" onClick={() => openModal('./6_geo_Our Country-India_ch-6.pdf')}>
                        <img
                            className="w-full h-40 object-cover sm:h-44 md:h-48 lg:h-52 xl:h-56 rounded-lg mx-auto p-2 transform transition duration-300 group-hover:scale-105 group-hover:blur-sm"
                            src={geo}
                            alt="Geography"
                        />
                        <h1 className='text-md font-extrabold px-4 py-2'>Notes - Our Country-India</h1>

                        <span className="absolute inset-0 flex items-center justify-center bg-[#01295c] bg-opacity-50 text-white font-bold text-lg rounded-lg opacity-0 group-hover:opacity-100 transition-opacity duration-150">
                            Open PDF
                        </span>
                    </div>

                    <div className="group cursor-pointer relative border-2 rounded-lg text-center overflow-hidden" onClick={() => openModal('./Maths_Sixth_Chapter11_Project activity.pdf')}>
                        <img
                            className="w-full h-40 object-cover sm:h-44 md:h-48 lg:h-52 xl:h-56 rounded-lg mx-auto p-2 transform transition duration-300 group-hover:scale-105 group-hover:blur-sm"
                            src={project}
                            alt="Maths Sixth Chapter-11"
                        />
                        <h1 className='text-md font-extrabold px-4 py-2'>Project Work - Maths Sixth Chapter-11</h1>

                        <span className="absolute inset-0 flex items-center justify-center bg-[#01295c] bg-opacity-50 text-white font-bold text-lg rounded-lg opacity-0 group-hover:opacity-100 transition-opacity duration-150">
                            Open PDF
                        </span>
                    </div>

                    <div className="group cursor-pointer relative border-2 rounded-lg text-center overflow-hidden" onClick={() => openModal('./EVS_Fifth_Chapter19_Classroom activity.pdf')}>
                        <img
                            className="w-full h-40 object-cover sm:h-44 md:h-48 lg:h-52 xl:h-56 rounded-lg mx-auto p-2 transform transition duration-300 group-hover:scale-105 group-hover:blur-sm"
                            src={evs}
                            alt="EVS_Fifth_Chapter19_Classroom activity"
                        />
                        <h1 className='text-md font-extrabold px-4 py-2'>Classroom Activity - EVS Chapter-5</h1>


                        <span className="absolute inset-0 flex items-center justify-center bg-[#01295c] bg-opacity-50 text-white font-bold text-lg rounded-lg opacity-0 group-hover:opacity-100 transition-opacity duration-150">
                            Open PDF
                        </span>
                    </div>
                </div>
            </div>

            {modalOpen && (
                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <div className="fixed inset-0 transition-opacity" aria-hidden="true" onClick={closeModal}>
                            <div className="absolute inset-0 bg-gray-700 opacity-75"></div>
                        </div>

                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                            <button onClick={closeModal} type="button" className="absolute top-0 right-0 m-4 text-gray-100 hover:text-gray-200 focus:outline-none bg-red-500 rounded-full md:p-2 p-1">
                                <svg xmlns="http://www.w3.org/2000/svg" className="md:h-6 md:w-6 h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 flex flex-col items-center w-full">
                                <div className="mt-2 flex justify-center w-full">
                                    <iframe title="PDF Viewer" src={selectedPDF} className="w-full md:max-w-2xl lg:max-w-3xl xl:max-w-3xl 2xl:max-w-3xl max-w-full h-96 sm:h-128 md:h-144 lg:h-160 mt-8" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Resource;
