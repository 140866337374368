import React from 'react'
import Banner from '../components/Banner'
import Hero from '../components/Hero'
import Navbar from '../components/Navbar'
import Counter from '../components/Counter'
import ContentEngage from '../components/ContentEngage'
import ContentSummarize from '../components/ContentSummarize'
import ContentPractice from '../components/ContentPractice'
import HomeContent from '../components/HomeContent'
import Videosection from '../components/Videosection'

function HomePage() {
    return (
        <div>
            <Banner />
            <Navbar />
            <Hero />
            <Counter />
            <HomeContent />
            <Videosection />
            <ContentEngage />
            <ContentSummarize />
            <ContentPractice />
        </div>
    )
}

export default HomePage