import React from 'react'
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import home4 from "../assets/images/bg-hero-2.jpg";
import home2 from "../assets/images/Optimized-educomp-ed-1.jpg";
import home3 from "../assets/images/bg-3.png";
import home1 from "../assets/images/bg-hero-3.jpg";


function Hero() {
    return (
        <div>
            <Carousel preventMovementUntilSwipeScrollTolerance={true} swipeScrollTolerance={50} infiniteLoop useKeyboardArrows autoPlay showStatus={false} showThumbs={false}>
                <div class="relative bg-gray-600 h-screen text-white overflow-hidden">
                    <div class="absolute inset-0">
                        <img src={home1} alt="home-screen-1" className="object-cover object-center w-full h-full brightness-90" />
                        <div class="absolute inset-0 bg-black opacity-40"></div>
                    </div>

                    <div class="relative z-10 flex flex-col justify-center items-center h-full text-center">
                        <h1 class="text-5xl font-bold leading-tight mb-4 text-[#2baf2b]">#There-Is-Another-Way</h1>
                        <p class="md:text-2xl lg:text-2xl xl:text-2xl text-xl text-gray-300 mb-8">Time to let go of the old system. Time to embrace the smart way of learning!</p>
                        <a href="/" class="bg-[#2baf2b] text-gray-100 hover:bg-[#1b781b] py-2 px-6 rounded-full text-lg font-semibold transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg">Get Started</a>
                    </div>
                </div>

                <div class="relative bg-gradient-to-r from-purple-600 to-blue-600 h-screen text-white overflow-hidden">
                    <div class="absolute inset-0">
                        <img src={home2} alt="home-screen-2" className="object-cover object-center w-full h-full brightness-90" />
                        <div class="absolute inset-0 bg-black opacity-40"></div>
                    </div>

                    <div class="relative z-10 flex flex-col justify-center items-center h-full text-center">
                        <h1 class="text-5xl font-bold leading-tight mb-4">#Innovate-To-Educate</h1>
                        <p class="text-lg text-gray-300 mb-8">Shedding outdated methods, embracing intelligent learning strategies!</p>
                        <a href="/" class="bg-[#2baf2b] text-gray-100 hover:bg-[#1b781b] py-2 px-6 rounded-full text-lg font-semibold transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg">Reach Out</a>
                    </div>
                </div>

                <div class="relative bg-gradient-to-r from-purple-600 to-blue-600 h-screen text-white overflow-hidden">
                    <div class="absolute inset-0">
                        <img src={home3} alt="home-screen-2" className="object-cover object-center w-full h-full brightness-90" />
                        <div class="absolute inset-0 bg-black opacity-40"></div>
                    </div>

                    <div class="relative z-10 flex flex-col justify-center items-center h-full text-center">
                        <h1 class="text-5xl font-bold leading-tight mb-4">#Next-Gen-Learning</h1>
                        <p class="text-lg text-gray-300 mb-8">It's time to abandon the past and embrace the era of intelligent learning solutions!
                        </p>

                    </div>
                </div>

                <div class="relative bg-gradient-to-r from-purple-600 to-blue-600 h-screen text-white overflow-hidden">
                    <div class="absolute inset-0">
                        <img src={home4} alt="home-screen-2" className="object-cover object-center w-full h-full brightness-90" />
                        <div class="absolute inset-0 bg-black opacity-40"></div>
                    </div>

                    <div class="relative z-10 flex flex-col justify-center items-center h-full text-center">
                        <h1 class="text-5xl font-bold leading-tight mb-4">#Smart-Education-Revolution</h1>
                        <p class="text-lg text-gray-300 mb-8">Bid farewell to antiquated systems, welcome the era of intelligent learning!</p>

                    </div>
                </div>
            </Carousel>
        </div>
    )
}

export default Hero