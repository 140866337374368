import React from 'react'
import GovernanceHero from '../governance/GovernanceHero'

function StocksPrice() {
  return (
    <div>
      <GovernanceHero headingText={'Stock Exchange'} />

      <div className='mx-auto max-w-7xl py-8'>
        <div className='md:flex lg:flex xl:flex 2xl:flex'>
          <div className='lg:px-6 md:px-6 xl:px-6 px-2 md:pb-6 lg:pb-6 xl:pb-6 pb-4 max-w-4xl w-full'>
            <h2 class="my-4 text-2xl text-gray-700 font-bold">Stock Prices &amp; Charts</h2>
            <div class="relative overflow-x-auto  p-2">

              <p>
                Share Price the current share price on the <a href={"http://www.bseindia.com/stockinfo/index.htm?scrip_cd=532696#"} target="_blank" rel="noopener noreferrer" className='text-[#2baf2b]'>BSE indices</a> and <a href={"https://www1.nseindia.com/companytracker/cmtracker.jsp?symbol=EDUCOMP&cName=cmtracker_nsedef.css"} target="_blank" rel="noopener noreferrer" className='text-[#2baf2b]'>NSE indices </a>
                Educomp is listed at both BSE and NSE.
              </p>
            </div>


          </div>

        </div>
      </div>
    </div>
  )
}

export default StocksPrice