export const stockexchange = [
    {
        id: 1,
        Heading1: "FY 2016-17",
        Heading2: "30-09-2016",
        Text: "Change of Director",
        PDF: "https://educomp.com/Data/300916_Change_of_Director.pdf",
        isActive: 1
    },
    {
        id: 2,
        Heading1: "FY 2016-17",
        Heading2: "30-09-2016",
        Text: "Outcome of the 22nd Annual General Meeting of the Company",
        PDF: "https://educomp.com/Data/300916_AGMProceedings-ESL.pdf",
        isActive: 1
    },
    {
        id: 3,
        Heading1: "FY 2016-17",
        Heading2: "07-09-2016",
        Text: "Newspaper Advertisement for Annual General Meeting; Cut Off date for E-Voting & Intimation of Book Closure",
        PDF: "https://educomp.com/Data/07092016_Newspaperdisclosure.pdf",
        isActive: 1
    },
    {
        id: 4,
        Heading1: "FY 2016-17",
        Heading2: "06-09-2016",
        Text: "Annual General Meeting Notice - Cut Off date for E-Voting & Intimation of Book Closure",
        PDF: "https://educomp.com/Data/06092016_Notice_of_AGM.pdf",
        isActive: 1
    },
    {
        id: 5,
        Heading1: "FY 2016-17",
        Heading2: "01-09-2016",
        Text: "Board meeting on Wednesday, September 14, 2016",
        PDF: "https://educomp.com/Data/BM_Notice_ESL_14.09.2016.pdf",
        isActive: 1
    },
    {
        id: 6,
        Heading1: "FY 2016-17",
        Heading2: "12-08-2016",
        Text: "Outcome of the Board Meeting",
        PDF: "https://educomp.com/Data/12082016_Stock_Exchange_letter.pdf",
        isActive: 1
    },
    {
        id: 7,
        Heading1: "FY 2016-17",
        Heading2: "03-08-2016",
        Text: "Board Meeting on Friday, August 12, 2016",
        PDF: "https://educomp.com/Data/03082016-Notice-BoardMeeting-ESL.pdf",
        isActive: 1
    },
    {
        id: 8,
        Heading1: "FY 2016-17",
        Heading2: "21-07-2016",
        Text: "Investor Complaints disclosure",
        PDF: "https://educomp.com/Data/21072016_Investor_Complaints_ESL.pdf",
        isActive: 1
    },
    {
        id: 9,
        Heading1: "FY 2016-17",
        Heading2: "18-07-2016",
        Text: "Appointment of Director",
        PDF: "https://educomp.com/Data/18072016_DISCLOSURE_APPOINTMENT_OF_DIRECTOR_ESL.pdf",
        isActive: 1
    },
    {
        id: 10,
        Heading1: "FY 2016-17",
        Heading2: "15-07-2016",
        Text: "Subscription to the shares in the newly incorporated company by the indirect subsidiary of the Educomp Solutions Limited",
        PDF: "https://educomp.com/Data/15072016_ESL.pdf",
        isActive: 1
    },
    {
        id: 11,
        Heading1: "FY 2016-17",
        Heading2: "05-07-2016",
        Text: "EISML land sale disclosure",
        PDF: "https://educomp.com/Data/05072016_EISML_Land_sale_disclosure.pdf",
        isActive: 1
    },
    {
        id: 12,
        Heading1: "FY 2016-17",
        Heading2: "27-05-2016",
        Text: "Resignation of Director(s)",
        PDF: "https://educomp.com/Data/27052016_Disclosure_resign_of_Director-ESL.pdf",
        isActive: 1
    },
    {
        id: 13,
        Heading1: "FY 2016-17",
        Heading2: "24-05-2016",
        Text: "Notice for the Closure of Trading Window",
        PDF: "https://educomp.com/Data/24052016_TradingWindowClosureNotice.pdf",
        isActive: 1
    },
    {
        id: 14,
        Heading1: "FY 2016-17",
        Heading2: "13-05-2016",
        Text: "Board Meeting on Thursday, May 26, 2016",
        PDF: "https://educomp.com/Data/26-05-2016_BM-notice-ESL.pdf",
        isActive: 1
    },
    {
        id: 15,
        Heading1: "FY 2016-17",
        Heading2: "06-05-2016",
        Text: "Credit Rating – Educomp Solutions Limited",
        PDF: "https://educomp.com/Data/06052016_Credit_Rating_ESL.pdf",
        isActive: 1
    },
    {
        id: 16,
        Heading1: "FY 2016-17",
        Heading2: "06-05-2016",
        Text: "Credit Rating – Edu Smart Services Private Limited",
        PDF: "https://educomp.com/Data/06052016_Credit_Rating_ESSPL.pdf",
        isActive: 1
    },
    {
        id: 17,
        Heading1: "FY 2016-17",
        Heading2: "21-04-2016",
        Text: "Investor Complaints",
        PDF: "https://educomp.com/Data/Investor-complaints-31.03.2016.pdf",
        isActive: 1
    },
    {
        id: 18,
        Heading1: "FY 2016-17",
        Heading2: "05-04-2016",
        Text: "Credit Rating – Educomp Solutions Limited",
        PDF: "https://educomp.com/Data/05042016_Credit_Rating_Disclosure-ESL.PDF",
        isActive: 1
    },
    {
        id: 19,
        Heading1: "FY 2016-17",
        Heading2: "05-04-2016",
        Text: "Credit Rating – Educomp Infrastructure & School Management Limited",
        PDF: "https://educomp.com/Data/05042016_Credit_Rating_Disclosure_EISML.PDF",
        isActive: 1
    },
    {
        id: 20,
        Heading1: "FY 2016-17",
        Heading2: "05-04-2016",
        Text: "Credit Rating – Edu Smart Services Private Limited",
        PDF: "https://educomp.com/Data/05042016_Credit_Rating_Disclosure_ESSPL.PDF",
        isActive: 1
    },
    {
        id: 21,
        Heading1: "FY 2015-16",
        Heading2: "31-03-2016",
        Text: "Assignment of Credit Rating",
        PDF: "https://educomp.com/Data/31-03-16_assignment_credit_rating.pdf",
        isActive: 1
    },
    {
        id: 22,
        Heading1: "FY 2015-16",
        Heading2: "10-03-2016",
        Text: "Redemption of Units issued to Educomp Solutions Limited by India Education Fund, a SEBI registered Venture Capital Fund",
        PDF: "https://educomp.com/Data/13-03-16_redemption_of_units.pdf",
        isActive: 1
    },
    {
        id: 23,
        Heading1: "FY 2015-16",
        Heading2: "08-02-2016",
        Text: "Notice for the Closure of Trading Window",
        PDF: "https://educomp.com/Data/08-02-2016_Tradingwindowclosure-1.pdf",
        isActive: 1
    },
    {
        id: 24,
        Heading1: "FY 2015-16",
        Heading2: "29-01-2016",
        Text: "Board Meeting on Thursday, February 11, 2016",
        PDF: "https://educomp.com/Data/29-01-2016_BM-notice-ESL.pdf",
        isActive: 1
    },
    {
        id: 25,
        Heading1: "FY 2015-16",
        Heading2: "22-01-2016",
        Text: "Investor Complaints Disclosure",
        PDF: "https://educomp.com/Data/22-01-2016_Investor-Complaint.pdf",
        isActive: 1
    },
    {
        id: 26,
        Heading1: "FY 2015-16",
        Heading2: "12-01-2016",
        Text: "Action Taken on news item appearing in CNBC TV 18",
        PDF: "https://educomp.com/Data/12-01-2016_News-item.pdf",
        isActive: 1
    },
    {
        id: 27,
        Heading1: "FY 2015-16",
        Heading2: "05-01-2016",
        Text: "Clarification/Confirmation on news item appearing on CNBC TV 18",
        PDF: "https://educomp.com/Data/05-01-2016_clarification-on-News-item.pdf",
        isActive: 1
    },
    {
        id: 28,
        Heading1: "FY 2015-16",
        Heading2: "31-12-2015",
        Text: "Investor Complaints",
        PDF: "https://educomp.com/Data/Investor-Complaints-31.12.2015.pdf",
        isActive: 1
    },
    {
        id: 29,
        Heading1: "FY 2015-16",
        Heading2: "28-12-2015",
        Text: "Sale of One of the Land and Building by M/s Educomp Infrastructure & School Management Limited (subsidiary of Educomp Solutions Limited)",
        PDF: "https://educomp.com/Data/28-12-2015_DisclosureSaleofLandESL.pdf",
        isActive: 1
    },
    {
        id: 30,
        Heading1: "FY 2015-16",
        Heading2: "23-12-2015",
        Text: "Authorization to determine materiality of information and disclosures to the Stock Exchanges",
        PDF: "https://educomp.com/Data/23-12-2015_DisclosureReg30(5)ESL.pdf",
        isActive: 1
    },
    {
        id: 31,
        Heading1: "FY 2015-16",
        Heading2: "09-11-2015",
        Text: "Un-audited Standalone & Consolidated Financial Results for the Quarter Ended on 30th September 2015",
        PDF: "https://educomp.com/Data/09-11-2015_ResultsQ2final.pdf",
        isActive: 1
    },
    {
        id: 32,
        Heading1: "FY 2015-16",
        Heading2: "05-11-2015",
        Text: "Notice for the Closure of Trading Window",
        PDF: "https://educomp.com/Data/05-11-2015_TradingwindowclosingnoticeESL.pdf",
        isActive: 1
    },
    {
        id: 33,
        Heading1: "FY 2015-16",
        Heading2: "29-10-2015",
        Text: "Proceeding of the result of 21st Annual General Meeting of the Company",
        PDF: "https://educomp.com/Data/29-10-2015_proceedingAGM.pdf",
        isActive: 1
    },
    {
        id: 34,
        Heading1: "FY 2015-16",
        Heading2: "27-10-2015",
        Text: "Board Meeting on Monday, November 09, 2015",
        PDF: "https://educomp.com/Data/27-10-2015_BMnoticeESL-1.pdf",
        isActive: 1
    },
    {
        id: 35,
        Heading1: "FY 2015-16",
        Heading2: "05-10-2015",
        Text: "Educomp Intelliprop Ventures Pte Ltd, subsidiary of Educomp Solutions Limited, sale its stake in Wiz Learn Technology Pte Ltd",
        PDF: "https://educomp.com/Data/05-10-2015_ESLannouncement.pdf",
        isActive: 1
    },
    {
        id: 36,
        Heading1: "FY 2015-16",
        Heading2: "30-09-2015",
        Text: "APPOINTMENT OF DIRECTOR",
        PDF: "https://educomp.com/Data/30-09-2015_letterforappointment.pdf",
        isActive: 1
    },
    {
        id: 37,
        Heading1: "FY 2015-16",
        Heading2: "30-09-2015",
        Text: "Result of e-voting/Poll w.r.t. Annual General Meeting (AGM) dt. September 28, 2015",
        PDF: "https://educomp.com/Data/30-09-2015_results.pdf",
        isActive: 1
    },
    {
        id: 38,
        Heading1: "FY 2015-16",
        Heading2: "13-08-2015",
        Text: "Grant of Stock Options under Various ESOP Schemes",
        PDF: "https://educomp.com/Data/13-08-2015_ESOPgrant.pdf",
        isActive: 1
    },
    {
        id: 39,
        Heading1: "FY 2015-16",
        Heading2: "13-08-2015",
        Text: "Outcome of the Board Meeting held on 13-08-2015",
        PDF: "https://educomp.com/Data/13-08-2015_Outcomeofboardmeeting.pdf",
        isActive: 1
    },
    {
        id: 40,
        Heading1: "FY 2015-16",
        Heading2: "13-08-2015",
        Text: "Un-audited Standalone & Consolidated Financial Results for the Quarter Ended on 30th June 2015",
        PDF: "https://educomp.com/Data/13-08-2015_Results-1.pdf",
        isActive: 1
    },
    {
        id: 41,
        Heading1: "FY 2015-16",
        Heading2: "11-08-2015",
        Text: "Notice for the Closure of Trading Window",
        PDF: "https://educomp.com/Data/11-08-2015_ESLTradingwindowclosingnotice.pdf",
        isActive: 1
    },
    {
        id: 42,
        Heading1: "FY 2015-16",
        Heading2: "03-08-2015",
        Text: "Board Meeting on Thursday, August 13, 2015",
        PDF: "https://educomp.com/Data/03-08-2015_ESLBMNotice.pdf",
        isActive: 1
    },
    {
        id: 43,
        Heading1: "FY 2015-16",
        Heading2: "29-05-2015",
        Text: "Grant of Stock Options under Various ESOP Schemes",
        PDF: "https://educomp.com/Data/29-05-2015_ESOPGrant.pdf",
        isActive: 1
    },
    {
        id: 44,
        Heading1: "FY 2015-16",
        Heading2: "29-05-2015",
        Text: "Outcome of Board Meeting",
        PDF: "https://educomp.com/Data/29-05-2015_OutcomeofBM.pdf",
        isActive: 1
    },
    {
        id: 45,
        Heading1: "FY 2015-16",
        Heading2: "29-05-2015",
        Text: "Audited Standalone & Consolidated Financial Results for the Quarter & Financial Year Ended on 31st March 2015",
        PDF: "https://educomp.com/Data/29-05-2015_Results-FINALNSE.pdf",
        isActive: 1
    },
    {
        id: 46,
        Heading1: "FY 2015-16",
        Heading2: "26-05-2015",
        Text: "Notice for the Closure of Trading Window",
        PDF: "https://educomp.com/Data/26-05-2015_Tradingwindowclosure.pdf",
        isActive: 1
    },
    {
        id: 47,
        Heading1: "FY 2015-16",
        Heading2: "20-05-2015",
        Text: "Code of Practices and Fair Disclosure of Unpublished Price Sensitive Information Under SEBI (Prohibition of Insider Trading) Regulation, 2015 (Regulations) and Confirmation for the formation of Code of Conduct in terms of Regulations",
        PDF: "https://educomp.com/Data/20-05-2015_UPSICode.pdf",
        isActive: 1
    },
    {
        id: 48,
        Heading1: "FY 2015-16",
        Heading2: "16-05-2015",
        Text: "Disclosure under Clause 36 of the Listing Agreement",
        PDF: "https://educomp.com/Data/16-05-2015_ESLdocScan.pdf",
        isActive: 1
    },
    {
        id: 49,
        Heading1: "FY 2015-16",
        Heading2: "08-05-2015",
        Text: "Board Meeting on Thursday, May 28, 2015",
        PDF: "https://educomp.com/Data/08-05-2015_ESLScan22.pdf",
        isActive: 1
    },
    {
        id: 50,
        Heading1: "FY 2015-16",
        Heading2: "29-04-2015",
        Text: "Action Taken on News item",
        PDF: "https://educomp.com/Data/29-04-2015_ActionTakenonNewsItem.pdf",
        isActive: 1
    },
    {
        id: 51,
        Heading1: "FY 2015-16",
        Heading2: "20-04-2015",
        Text: "News Item",
        PDF: "https://educomp.com/Data/20-04-2015_NewsItem.pdf",
        isActive: 1
    },
    {
        id: 52,
        Heading1: "FY 2015-16",
        Heading2: "14-04-2015",
        Text: "Proceeding of the Announcements of results of Postal Ballot",
        PDF: "https://educomp.com/Data/14-04-2015_PostalballotMinutesESL.pdf",
        isActive: 1
    }
]