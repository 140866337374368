export const financial = [
    {
        id: 1,
        Title: "Ask N Learn Pte. Ltd., Singapore",
        PDF: "http://www.educomp.com/Data/subsidaries/AskNLearn-Pte-Ltd.pdf",
        isActive: 1
    },
    {
        id: 2,
        Title: "Authorgen Technologies Ltd.",
        PDF: "http://www.educomp.com/Data/subsidaries/Authorzen-Technologies-Limited.pdf",
        isActive: 1
    },
    {
        id: 3,
        Title: "Boston Realtech Pvt. Ltd.",
        PDF: "http://www.educomp.com/Data/subsidaries/Boston-Realtech-Private-Limited.pdf",
        isActive: 1
    },
    {
        id: 4,
        Title: "Crosshome Developers Private Ltd.",
        PDF: "http://www.educomp.com/Data/subsidaries/Crosshome-Developers-Private-Limited.pdf",
        isActive: 1
    },
    {
        id: 5,
        Title: "Educomp APAC Services Ltd. BVI",
        PDF: "http://www.educomp.com/Data/subsidaries/Educomp-Apac-Services-Limited.pdf",
        isActive: 1
    },
    {
        id: 6,
        Title: "Educomp Asia Pacific Pte. Ltd, Singapore",
        PDF: "http://www.educomp.com/Data/subsidaries/Educomp-Asia-Pacific-Pte.Ltd.pdf",
        isActive: 1
    },
    {
        id: 7,
        Title: "Educomp Child Care Pvt. Ltd.",
        PDF: "http://www.educomp.com/Data/subsidaries/Educomp-Childcare-Private-Limited.pdf",
        isActive: 1
    },
    {
        id: 8,
        Title: "Educomp Infrastructure and School Management Ltd.",
        PDF: "http://www.educomp.com/Data/subsidaries/Educomp-Infrastructure-and-School-Management-Limited.pdf",
        isActive: 1
    },
    {
        id: 9,
        Title: "Educomp Infrastructure Services Pvt Ltd.",
        PDF: "http://www.educomp.com/Data/subsidaries/Educomp-Infrastructure-Services-Private-Limited.pdf",
        isActive: 1
    },
    {
        id: 10,
        Title: "Educomp IntelProp Ventures Pte Ltd, Singapore",
        PDF: "http://www.educomp.com/Data/subsidaries/Educomp-Intelprop-Ventures-Pte.-Ltd.pdf",
        isActive: 1
    },
    {
        id: 11,
        Title: "Educomp Learning Hour Pvt. Ltd.",
        PDF: "http://www.educomp.com/Data/subsidaries/Educomp-Learning-Hour-Pvt-Ltd.pdf",
        isActive: 1
    },
    {
        id: 12,
        Title: "Educomp Learning Pvt. Ltd.",
        PDF: "http://www.educomp.com/Data/subsidaries/Educomp-Learning-Private-Limited.pdf",
        isActive: 1
    },
    {
        id: 13,
        Title: "Educomp Online Supplemental Services Ltd.",
        PDF: "http://www.educomp.com/Data/subsidaries/Educomp-Online-supplemental-Services-Ltd..pdf",
        isActive: 1
    },
    {
        id: 14,
        Title: "Educomp Professional Education Ltd.",
        PDF: "http://www.educomp.com/Data/subsidaries/Educomp-Professional-Education-Limited.pdf",
        isActive: 1
    },
    {
        id: 15,
        Title: "Educomp School Management Ltd.",
        PDF: "http://www.educomp.com/Data/subsidaries/Educomp-School-Management-Limited.pdf",
        isActive: 1
    },
    {
        id: 16,
        Title: "Educomp Software Ltd.",
        PDF: "http://www.educomp.com/Data/subsidaries/Educomp-Software-Limited.pdf",
        isActive: 1
    },
    {
        id: 17,
        Title: "Edumatics Corporation Inc. USA",
        PDF: "http://www.educomp.com/Data/subsidaries/Edumatics-Corporation.pdf",
        isActive: 1
    },
    {
        id: 18,
        Title: "EuroKids India Ltd.",
        PDF: "http://www.educomp.com/Data/subsidaries/Eurokids-India-Limited.pdf",
        isActive: 1
    },
    {
        id: 19,
        Title: "EuroKids International Ltd.",
        PDF: "http://www.educomp.com/Data/subsidaries/Eurokids-International-Limited.pdf",
        isActive: 1
    },
    {
        id: 20,
        Title: "Euroschool International Limited",
        PDF: "http://www.educomp.com/Data/subsidaries/Euroschool-International-Limited.pdf",
        isActive: 1
    },
    {
        id: 21,
        Title: "Evergreen Realtech Pvt. Ltd.",
        PDF: "http://www.educomp.com/Data/subsidaries/Evergreen-Realtech-Private-Limited.pdf",
        isActive: 1
    },
    {
        id: 22,
        Title: "Falcate Builders Private Ltd",
        PDF: "http://www.educomp.com/Data/subsidaries/Falcate-Builders-Private-Limited.pdf",
        isActive: 1
    },
    {
        id: 23,
        Title: "Good Luck Structure Private Ltd.",
        PDF: "http://www.educomp.com/Data/subsidaries/Good-Luck-Structure-Private-Limited.pdf",
        isActive: 1
    },
    {
        id: 24,
        Title: "Grider Infratech Pvt. Ltd.",
        PDF: "http://www.educomp.com/Data/subsidaries/Grider-Infratech-Private-Limited.pdf",
        isActive: 1
    },
    {
        id: 25,
        Title: "Growzone Infrastructure Private Ltd.",
        PDF: "http://www.educomp.com/Data/subsidaries/Growzone-Infrastructure-Private-Limited.pdf",
        isActive: 1
    },
    {
        id: 26,
        Title: "Herold Infra Private Limited",
        PDF: "http://www.educomp.com/Data/subsidaries/Herold-Infra-Private-Limited.pdf",
        isActive: 1
    },
    {
        id: 27,
        Title: "Constructions Private Ltd.",
        PDF: "http://www.educomp.com/Data/subsidaries/Hidream-Constructions-Private-Limited.pdf",
        isActive: 1
    },
    {
        id: 28,
        Title: "Leading Edge Infratech Private Ltd.",
        PDF: "http://www.educomp.com/Data/subsidaries/Leading-Edge-Infratech-Private-Limited.pdf",
        isActive: 1
    },
    {
        id: 29,
        Title: "Learning Internet Inc. USA**",
        PDF: "http://www.educomp.com/Data/subsidaries/Learning-Internet-Inc.pdf",
        isActive: 1
    },
    {
        id: 30,
        Title: "Markus Infrastructure Private Ltd.",
        PDF: "http://www.educomp.com/Data/subsidaries/Markus-infrastructure-Private-Limited.pdf",
        isActive: 1
    },
    {
        id: 31,
        Title: "Newzone Infrastructure Pvt.Ltd.",
        PDF: "http://www.educomp.com/Data/subsidaries/Newzone-Infrastructure-Private-Limited.pdf",
        isActive: 1
    },
    {
        id: 32,
        Title: "Onega Infrastructure Pvt. Ltd.",
        PDF: "http://www.educomp.com/Data/subsidaries/Onega-Infrastructure-Private-Limited.pdf",
        isActive: 1
    },
    {
        id: 33,
        Title: "Orlando Builders Private Ltd.",
        PDF: "http://www.educomp.com/Data/subsidaries/Orlando-Builders-Private-Limited.pdf",
        isActive: 1
    },
    {
        id: 34,
        Title: "Pave Education Pte. Ltd.",
        PDF: "http://www.educomp.com/Data/subsidaries/Pave-Education-Pte-Ltd.pdf",
        isActive: 1
    },
    {
        id: 35,
        Title: "Reverie Infratech Private Limited",
        PDF: "http://www.educomp.com/Data/subsidaries/Reverie-Infratech-Private-Limited.pdf",
        isActive: 1
    },
    {
        id: 36,
        Title: "Rockstrong Infratech Private Ltd.",
        PDF: "http://www.educomp.com/Data/subsidaries/Rockstrong-Infratech-Private-Limited.pdf",
        isActive: 1
    },
    {
        id: 37,
        Title: "Savvica Inc., Canada",
        PDF: "http://www.educomp.com/Data/subsidaries/SAVVICA-Inc.pdf",
        isActive: 1
    },
    {
        id: 38,
        Title: "Sikhya Solutions Inc.USA",
        PDF: "http://www.educomp.com/Data/subsidaries/Sikhya-Solutions-LLC.pdf",
        isActive: 1
    },
    {
        id: 39,
        Title: "Singapore Learning.com Pte. Limited, Singapore",
        PDF: "http://www.educomp.com/Data/subsidaries/Singpore-Learning.com-Pte-Ltd.pdf",
        isActive: 1
    },
    {
        id: 40,
        Title: "Strotech Infrastructure Private Ltd.",
        PDF: "http://www.educomp.com/Data/subsidaries/Strotech-Infrastructure-Private-Limited.pdf",
        isActive: 1
    },
    {
        id: 41,
        Title: "Wheitstone Productions Pvt Ltd.",
        PDF: "http://www.educomp.com/Data/subsidaries/Wheitstone-Productions-Pvt.-Ltd.pdf",
        isActive: 1
    },
    {
        id: 42,
        Title: "Wiz Learn Pte Ltd., Singapore",
        PDF: "http://www.educomp.com/Data/subsidaries/WizLearn-Pte-Ltd.pdf",
        isActive: 1
    },
    {
        id: 43,
        Title: "Zeta Buildcon Pvt. Ltd",
        PDF: "http://www.educomp.com/Data/subsidaries/Zeta-Buildcon-Private-Limited.pdf",
        isActive: 1
    }
];