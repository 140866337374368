import React from 'react';
import engage5 from '../assets/images/engage-img-5.jpg'

function AboutUs() {
    const gradient1 = 'linear-gradient(to bottom, rgba(0,0,0,0.7), rgba(0,0,0,0.3))';
    const gradient2 = 'linear-gradient(to bottom, rgba(0,0,0,0.5), rgba(0,0,0,0.1))';

    return (
        <section>
            <div className="relative" style={{ backgroundImage: `${gradient1}, ${gradient2}, url(${engage5})`, backgroundSize: 'cover', }}>
                <div className="absolute inset-x-0 bottom-0">
                    <svg
                        viewBox="0 0 224 12"
                        fill="currentColor"
                        className="w-full -mb-1 text-white"
                        preserveAspectRatio="none"
                    >
                        <path d="M0,0 C48.8902582,6.27314026 86.2235915,9.40971039 112,9.40971039 C137.776408,9.40971039 175.109742,6.27314026 224,0 L224,12.0441132 L0,12.0441132 L0,0 Z" />
                    </svg>
                </div>
                <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
                    <div className="relative max-w-2xl sm:mx-auto sm:max-w-xl md:max-w-2xl sm:text-center">
                        <h2 className="mb-6 font-sans text-3xl font-bold tracking-tight text-[#2baf2b] sm:text-5xl sm:leading-none">
                            Welcome to Educomp Solutions Ltd!
                            <br className="hidden md:block" />

                        </h2>
                        <p className="mb-6 text-base font-normal tracking-wide text-gray-100 md:text-xl">
                            Our mission is to make education accessible, engaging, and effective for learners of all grades.
                            We believe that education is the key to personal and professional growth. We are passionate about creating high-quality content in sync with the National Education Policy.
                        </p>
                        <form className="flex flex-col items-center w-full mb-4 md:flex-row md:px-16">
                            <input
                                placeholder="Email"
                                required
                                type="text"
                                className="flex-grow w-full h-12 px-4 mb-3 text-black transition duration-200 bg-gray-200 rounded appearance-none md:mr-2 md:mb-0 focus:border-deep-purple-accent-200 focus:outline-none focus:shadow-outline"
                            />
                            <button
                                type="submit"
                                className="inline-flex items-center justify-center w-full h-12 px-6 font-medium  
                                bg-[#2baf2b] tracking-wide text-white transition duration-200 rounded shadow-md md:w-auto bg-deep-purple-accent-400 focus:shadow-outline focus:outline-none"
                            >
                                Subscribe
                            </button>
                        </form>
                        {/* <p className="max-w-md mb-10 text-xs font-thin tracking-wide text-gray-500 sm:text-sm sm:mx-auto md:mb-16">
                            Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                            accusantium doloremque laudantium.
                        </p> */}
                        <a
                            href="/"
                            aria-label="Scroll down"
                            className="flex items-center justify-center w-10 h-10 mx-auto text-white duration-300 transform animate-bounce bg-[#2baf2b] rounded-full hover:text-teal-accent-400  hover:border-teal-accent-400 hover:shadow hover:scale-110"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="12"
                                height="12"
                                viewBox="0 0 12 12"
                                fill="currentColor"
                            >
                                <path d="M10.293,3.293,6,7.586,1.707,3.293A1,1,0,0,0,.293,4.707l5,5a1,1,0,0,0,1.414,0l5-5a1,1,0,1,0-1.414-1.414Z" />
                            </svg>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AboutUs