import React from 'react'
import Banner from '../../../components/Banner'
import Navbar from '../../../components/Navbar'
import Familiarisation from '../../../components/governance/Familiarisation'

function FamiliarisationPage() {
    return (
        <div>
            <Banner />
            <Navbar />
            <Familiarisation />
        </div>
    )
}

export default FamiliarisationPage