import React from 'react'
import Banner from '../../components/Banner'
import Navbar from '../../components/Navbar'
import B2b from '../../components/B2b'

function B2bPage() {
    return (
        <div>
            <Banner />
            <Navbar />
            <B2b />
        </div>
    )
}

export default B2bPage