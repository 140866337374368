import React from 'react'
import GovernanceHero from '../governance/GovernanceHero';
import { divinendAmount } from '../../js/DivinendAmount';

function UnpaidUnclaimed() {
    return (
        <div>
            <GovernanceHero headingText={'Stock Exchange'} />

            <div className='mx-auto max-w-7xl py-8'>
                <div className='md:flex lg:flex xl:flex 2xl:flex'>
                    <div className='lg:px-6 md:px-6 xl:px-6 px-2 md:pb-6 lg:pb-6 xl:pb-6 pb-4 max-w-4xl w-full'>
                        <h2 class="my-4 text-2xl text-gray-700 font-bold">Unpaid/Unclaimed Dividend Amounts</h2>
                        <div class="relative overflow-x-auto  p-2">

                            <ul className='space-y-3'>
                                {divinendAmount.map(amounts => (
                                    <li key={amounts.id}>
                                        <a href={amounts.PDF} target="_blank" rel="noopener noreferrer">
                                            <span className='underline underline-offset-2'>Download</span> :   {amounts.Title}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default UnpaidUnclaimed