import React from 'react'
import GovernanceHero from './governance/GovernanceHero'
import { insolvency } from "../js/Insolvency";

function CorporateInsolvency() {
    return (
        <div>
            <GovernanceHero headingText={'CORPORATE INSOLVENCY'} />

            <div className='mx-auto max-w-7xl py-8'>
                <div className='md:flex lg:flex xl:flex 2xl:flex'>
                    <div className='lg:px-6 md:px-6 xl:px-6 px-2 md:pb-6 lg:pb-6 xl:pb-6 pb-4 max-w-4xl w-full'>
                        <h2 class="my-4 text-2xl text-gray-700 font-bold">CORPORATE INSOLVENCY RESOLUTION PROCESS
                        </h2>
                        <div class="relative overflow-x-auto  p-2">

                            <table class=" text-sm text-left rtl:text-right text-gray-500">
                                <thead class="text-xs text-gray-700 uppercase">
                                    <tr>
                                        <th scope="col" class="px-6 py-3 ">
                                            Heading
                                        </th>
                                        <th scope="col" class="px-6 py-3 ">
                                            Public Announcement
                                        </th>
                                    </tr>
                                </thead>

                                {insolvency.map(corporateInsolvency => (
                                    <tbody>
                                        <tr class="border-b border-gray-200 ">
                                            <td class="px-6 py-4">
                                                {corporateInsolvency.Title}
                                            </td>
                                            <td class="px-6 py-4 text-[#2baf2b]">
                                                < a href={corporateInsolvency.PDF} target="_blank" rel="noopener noreferrer">
                                                    Download
                                                </a>
                                            </td>
                                        </tr>

                                    </tbody>
                                ))}
                            </table>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    )
}

export default CorporateInsolvency