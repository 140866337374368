import React from 'react'
import Banner from '../components/Banner'
import Navbar from '../components/Navbar'
import Career from '../components/Career'

function CareerPage() {
    return (
        <div>
            <Banner />
            <Navbar />
            <Career />
        </div>
    )
}

export default CareerPage