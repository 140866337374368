import React from 'react'
import { Link, useLocation } from 'react-router-dom'

function SideMenu() {

    const location = useLocation();

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    const isLinkActive = (pathname) => {
        return location.pathname === pathname;
    };

    return (
        <div>
            <div className="block mt-5 max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-50">

                <h5 class="mb-2 text-xl font-bold tracking-tight text-gray-700 ">Governance</h5>
                <ul class="font-normal text-gray-700 list-disc  list-inside space-y-2">
                    <li className={`${isLinkActive('/announce-corporate/board-meetings') ? 'font-bold text-blue-600' : ''}`}>

                        <Link
                            onClick={scrollToTop}
                            to={`/announce-corporate/board-meetings`}>
                            Board Meetings
                        </Link>
                    </li>
                    <li className={`${isLinkActive('/announce-corporate/agm-postal-ballots') ? 'font-bold text-blue-600' : ''}`}>
                        <Link
                            onClick={scrollToTop}
                            to={`/announce-corporate/agm-postal-ballots`}>
                            AGM/EGM/Postal Ballots
                        </Link>
                    </li>
                    <li className={`${isLinkActive('/announce-corporate/results-disclosures') ? 'font-bold text-blue-600' : ''}`}>
                        <Link
                            onClick={scrollToTop}
                            to={`/announce-corporate/results-disclosures`}>
                            Results & Disclosure
                        </Link>
                    </li>
                    <li className={`${isLinkActive('/announce-corporate/corporate-actions') ? 'font-bold text-blue-600' : ''}`}>
                        <Link
                            onClick={scrollToTop}
                            to={`/announce-corporate/corporate-actions`}>
                            Corporate Actions
                        </Link>
                    </li>
                    <li className={`${isLinkActive('/announce-corporate/stock-exchange-announcements') ? 'font-bold text-blue-600' : ''}`}>
                        <Link
                            onClick={scrollToTop}
                            to={`/announce-corporate/stock-exchange-announcements`}>
                            Stock Exchange Announcements
                        </Link>
                    </li>
                    <li className={`${isLinkActive('/announce-corporate/newspaper-advertisments') ? 'font-bold text-blue-600' : ''}`}>
                        <Link
                            onClick={scrollToTop}
                            to={`/announce-corporate/newspaper-advertisments`}>
                            Newspaper Advertisements
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default SideMenu