import React, { useState, useEffect } from 'react';

function Careers() {
    const [jobs, setJobs] = useState([]);

    useEffect(() => {
        fetch('/jobdata.json')
            .then(response => response.json())
            .then(data => setJobs(data))
            .catch(error => console.error('Error fetching data:', error));
    }, []);

    const scrollToApplyForm = () => {
        const applyFormSection = document.getElementById('formsFill');
        if (applyFormSection) {
            applyFormSection.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <section className="max-w-7xl mx-auto">
            <h1 className="text-3xl pl-4 md:text-3xl border-l-4 font-sans font-bold border-green-400">
                Open Positions
            </h1>

            <div className="h-full max-w-7xl mx-auto py-4 px-1">
                <div className="overflow-hidden md:p-8 lg:p-8 xl:p-8 p-3 sm:py-12 w-full max-w-7xl mx-auto space-y-4 bg-gray-100 rounded-md">
                    {/* {jobs.map((job, index) => (
                        <div key={index} className="bg-white w-full max-w-6xl flex flex-col sm:flex-row gap-3 sm:items-center justify-between px-5 py-4 rounded-md">
                            <div>
                                <span className="text-[#2baf2b] text-sm underline underline-offset-1">
                                    {job.category}
                                </span>
                                <h3 className="font-bold mt-px">{job.title}</h3>
                                <div className="flex items-center gap-3 mt-2">
                                    <span className="bg-[#2baf2b]/10 text-[#2baf2b] rounded-full px-3 py-1 md:block hidden text-sm">
                                        {job.type}
                                    </span>
                                    <span className="text-slate-600 text-sm flex gap-1 items-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                                        </svg>
                                        {job.location}
                                    </span>
                                </div>
                            </div>
                            <div>
                                <button onClick={scrollToApplyForm} className="bg-[#2baf2b] text-white font-medium px-4 py-2 rounded-md flex gap-1 items-center">
                                    Apply
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 13.5 12 21m0 0-7.5-7.5M12 21V3" />
                                    </svg>

                                </button>
                            </div>
                        </div>
                    ))} */}

                    {/* comment this out and uncomment the above one to show the job positing through json  */}
                    <h1 className='font-bold tracking-[1px] text-md underline underline-offset-4 text-gray-800'>Coming Soon</h1>
                </div>
            </div>
        </section>
    );
}

export default Careers;
