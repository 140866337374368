// MyComponent.js
import React from 'react';
import { policies } from '../../js/Policies';
import GovernanceHero from './GovernanceHero';
import { Link, useLocation } from 'react-router-dom';

const Policies = () => {
    const location = useLocation();

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    const isLinkActive = (pathname) => {
        return location.pathname === pathname;
    };

    return (
        <div>
            <GovernanceHero headingText={"Policies"} />

            <div className='mx-auto max-w-7xl py-8'>
                <div className='md:flex lg:flex xl:flex 2xl:flex'>
                    <div className='lg:px-6 md:px-6 xl:px-6 px-2 md:pb-6 lg:pb-6 xl:pb-6 pb-4 max-w-4xl w-full'>
                        <h3 class="my-4 text-lg text-gray-700 font-bold">Policies</h3>
                        <p class="mb-4 text-md font-normal text-gray-500 ">
                            <ul className='space-y-3'>
                                {policies.map(policy => (
                                    <li key={policy.id}>
                                        <a href={policy.PDF} target="_blank" rel="noopener noreferrer">
                                            <span className='underline underline-offset-2'>Download</span> :   {policy.Title}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </p>

                    </div>

                    <div className='md:px-0 lg:px-0 xl:px-0 2xl:px-0 px-3.5'>
                        <div className="block mt-5 max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-50">

                            <h5 class="mb-2 text-xl font-bold tracking-tight text-gray-700 ">Governance</h5>
                            <ul class="font-normal text-gray-700 list-disc  list-inside space-y-2">
                                <li className={`${isLinkActive('/investors/governance') ? 'font-bold text-blue-600' : ''}`}>

                                    <Link
                                        onClick={scrollToTop}
                                        to={`/investors/governance`}>
                                        Board of Directors
                                    </Link>
                                </li>
                                <li className={`${isLinkActive('/investors/terms-and-conditions-independent-directors') ? 'font-bold text-blue-600' : ''}`}>
                                    <Link
                                        onClick={scrollToTop}
                                        to={`/investors/terms-and-conditions-independent-directors`}>
                                        Terms & Conditions for appointment of Independent Directors
                                    </Link>
                                </li>
                                <li className={`${isLinkActive('/investors/committee-to-board') ? 'font-bold text-blue-600' : ''}`}>
                                    <Link
                                        onClick={scrollToTop}
                                        to={`/investors/committee-to-board`}>
                                        Committees to the Board
                                    </Link>
                                </li>
                                <li className={`${isLinkActive('/investors/code-to-conduct') ? 'font-bold text-blue-600' : ''}`}>
                                    <Link
                                        onClick={scrollToTop}
                                        to={`/investors/code-to-conduct`}>
                                        Code of Conduct
                                    </Link>
                                </li>
                                <li className={`${isLinkActive('/investors/policies') ? 'font-bold text-blue-600' : ''}`}>
                                    <Link
                                        onClick={scrollToTop}
                                        to={`/investors/policies`}>
                                        Policies
                                    </Link>
                                </li>
                                <li className={`${isLinkActive('/investors/familiarisation-programme') ? 'font-bold text-blue-600' : ''}`}>
                                    <Link
                                        onClick={scrollToTop}
                                        to={`/investors/familiarisation-programme`}>
                                        Familiarisation Programme
                                    </Link>
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default Policies;
