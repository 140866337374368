import React from 'react';
import navbarvideo from "../assets/videos/navbar.mp4";

function HomeContent() {
    return (
        <section>
            <div className="lg:flex items-center justify-between md:mt-12 lg:mt-12 xl:mt-12 2xl:mt-12 mt-2 mb-4 mx-auto max-w-7xl md:py-12 lg:py-12 xl:py-12 py-2">
                <div className="lg:w-7/12 lg:mt-0 mt-8">
                    <div className="md:px-0 lg:px-0 xl:px-0 2xl:px-0 px-2 w-fit rounded-xl ">
                        <video className="w-full h-80 rounded-lg" autoPlay muted loop playsInline src={navbarvideo} />
                    </div>
                </div>
                <div className="lg:w-2/4">
                    <h2 className="tracking-tight  font-extrabold md:text-4xl text-2xl text-black md:px-0 lg:px-0 xl:px-0 2xl:px-0 px-2">
                        <span className="text-[#2baf2b]"> Our History </span>
                    </h2>
                    <p className="text-md mt-4 text-gray-700 font-normal md:text-xl lg:text-xl xl:text-xl text-sm tracking-wide bg-gray-200/40 p-3">
                        The foundation of Educomp Smartclass is the largest content repository of digital rich-media content for grades K-12 that has been organised to suit the precise goals outlined by the central and state boards. In addition to regional languages, the content is provided in 12 boards. Comprehensive coverage is provided, and the information is matched to chapters and topics in books recommended for the boards in subjects including math, EVS, science, social science, commerce, and the arts.
                        <br />
                        <br />
                    </p>
                </div>
            </div>

            <div className='md:px-4 lg:px-4 xl:px-4 px-2 max-w-7xl mx-auto text-2xl font-light text-gray-800'>
                <p className=''>By bringing abstract ideas to life, the multimedia content repository engages students and helps them practice via <span className='underline underline-offset-4 decoration-green-500 italic font-normal'> exercises, sample papers, and interactivities/simulations </span>. The use of tools like topic synopsis and concept maps can help students reinforce what they have learned. Students' understanding of the subjects taught can be evaluated by teachers using both objective and subjective assessments. The topics can also be explored in greater depth by students using resources like weblinks, real life applications, and assessment for learning.</p>
            </div>
        </section>
    )
}

export default HomeContent