import React from 'react'
import StockExchangeAnnouncment from '../../../components/AnnouncementCorporate/StockExchangeAnnouncment'
import Navbar from '../../../components/Navbar'
import Banner from '../../../components/Banner'

function StockExchangeAnnouncmentPage() {
    return (
        <div>
            <Banner />
            <Navbar />
            <StockExchangeAnnouncment />
        </div>
    )
}

export default StockExchangeAnnouncmentPage