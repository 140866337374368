import React from 'react'
import Banner from '../../../components/Banner'
import Navbar from '../../../components/Navbar'
import NewspaperAdvertisements from '../../../components/AnnouncementCorporate/NewspaperAdvertisements'

function NewspaperAdvertisementsPage() {
    return (
        <div>
            <Banner />
            <Navbar />
            <NewspaperAdvertisements />
        </div>
    )
}

export default NewspaperAdvertisementsPage