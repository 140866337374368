// // MyComponent.js
// import React, { useState } from 'react';
// import { results } from '../../js/Results';
// // import { useLocation } from 'react-router-dom';
// import GovernanceHero from '../governance/GovernanceHero';

// const QuaterlyMain = () => {
//     // const location = useLocation();

//     // const scrollToTop = () => {
//     //     window.scrollTo(0, 0);
//     // };

//     // const isLinkActive = (pathname) => {
//     //     return location.pathname === pathname;
//     // };

//     const [showArchive, setShowArchive] = useState(false);

//     // Filter the data
//     const recentResults = results.filter(item => parseInt(item.Heading.match(/\d{4}/)[0]) >= 2016);
//     const archivedResults = results.filter(item => parseInt(item.Heading.match(/\d{4}/)[0]) < 2016);


//     return (

//         <section>
//             <GovernanceHero headingText={"Quarterly & Annual results"} />
//             <div className='mx-auto max-w-7xl py-8'>
//                 <h2 className="my-4 text-2xl text-gray-700 font-bold">Financial Results</h2>

//                 <div className="relative overflow-x-auto p-2">
//                     <table className="text-sm text-left text-gray-500">
//                         <thead className="text-xs text-gray-700 uppercase">
//                             <tr>
//                                 <th scope="col" className="px-6 py-3">Fiscal Year</th>
//                                 <th scope="col" className="px-6 py-3">Quarter</th>
//                                 <th scope="col" className="px-6 py-3">PDF</th>
//                             </tr>
//                         </thead>
//                         <tbody>
//                             {recentResults.map(result => (
//                                 <tr key={result.id} className="border-b border-gray-200">
//                                     <td className="px-6 py-4">{result.Heading}</td>
//                                     <td className="px-6 py-4">{result.Title}</td>
//                                     <td className="px-6 py-4 text-[#2baf2b]">
//                                         <a href={result.PDF} target="_blank" rel="noopener noreferrer">Download</a>
//                                     </td>
//                                 </tr>
//                             ))}
//                             {showArchive && archivedResults.map(result => (
//                                 <tr key={result.id} className="border-b border-gray-200">
//                                     <td className="px-6 py-4">{result.Heading}</td>
//                                     <td className="px-6 py-4">{result.Title}</td>
//                                     <td className="px-6 py-4 text-[#2baf2b]">
//                                         <a href={result.PDF} target="_blank" rel="noopener noreferrer">Download</a>
//                                     </td>
//                                 </tr>
//                             ))}
//                         </tbody>
//                     </table>
//                 </div>

//                 <button
//                     onClick={() => setShowArchive(!showArchive)}
//                     className="mt-4 px-4 py-2 bg-blue-500 text-white rounded"
//                 >
//                     {showArchive ? 'Hide Archive Data' : 'Show Archive Data'}
//                 </button>
//             </div>
//         </section>
//     );
// };

// export default QuaterlyMain;


// <div>
//     <GovernanceHero headingText={"Quarterly & Annual results"} />

//     <div className='mx-auto max-w-7xl py-8'>
//         <div className='md:flex lg:flex xl:flex 2xl:flex'>
//             <div className='lg:px-6 md:px-6 xl:px-6 px-2 md:pb-6 lg:pb-6 xl:pb-6 pb-4 max-w-5xl w-full'>
//                 <h3 class="my-4 text-lg text-gray-700 font-bold">QUARTERLY & ANNUAL RESULTS</h3>
//                 <p class="mb-4 text-md font-normal text-gray-500 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 gap-8">
//                     {results.map(result => (
//                         <a key={result.id} href={result.PDF} target="_blank" rel="noopener noreferrer" class="border-2 p-4 rounded-lg gap-y-2 hover:border-[#2baf2b] hover:transition hover:delay-100 hover:ease-out">
//                             <span class='hover:underline underline-offset-2 block hover:text-[#2baf2b]'>{result.Heading}</span>
//                             <br />
//                             {result.Title}
//                         </a>
//                     ))}
//                 </p>
//             </div>
//         </div>
//     </div>
// </div>

import React, { useState } from 'react';
import { results } from '../../js/Results';
import GovernanceHero from '../governance/GovernanceHero';

const QuaterlyMain = () => {
    const [showArchive, setShowArchive] = useState(false);

    // Group the data by year and sort it
    const groupAndSortByYear = (data) => {
        const grouped = data.reduce((acc, current) => {
            const year = current.Heading.match(/\d{4}/)[0];
            if (!acc[year]) {
                acc[year] = [];
            }
            acc[year].push(current);
            return acc;
        }, {});

        const sortedYears = Object.keys(grouped).sort((a, b) => b - a);
        return sortedYears.map(year => ({ year, results: grouped[year] }));
    };

    const recentResults = groupAndSortByYear(results.filter(item => parseInt(item.Heading.match(/\d{4}/)[0]) >= 2016));
    const archivedResults = groupAndSortByYear(results.filter(item => parseInt(item.Heading.match(/\d{4}/)[0]) < 2016));

    const renderResults = (groupedResults) => {
        return groupedResults.map(group => (
            <div key={group.year} className="mb-6">
                <h3 className="text-xl text-gray-700 font-bold my-4">{`Financial Results FY ${group.year}`}</h3>
                <div className="overflow-x-auto max-w-2xl">
                    <table className="min-w-full max-w-2xl mx-auto text-sm text-left text-gray-500">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                            <tr>
                                <th scope="col" className="px-4 py-2">Quarter</th>
                                <th scope="col" className="px-4 py-2">PDF</th>
                            </tr>
                        </thead>
                        <tbody>
                            {group.results.map(result => (
                                <tr key={result.id} className="border-b border-gray-200">
                                    <td className="px-4 py-2">{result.Title}</td>
                                    <td className="px-4 py-2 text-[#2baf2b]">
                                        <a href={result.PDF} target="_blank" rel="noopener noreferrer">Download</a>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        ));
    };

    return (
        <section>
            <GovernanceHero headingText={"Quarterly & Annual Results"} />
            <div className='mx-auto max-w-7xl py-8'>
                <h2 className="my-4 text-4xl text-gray-700 font-bold">Financial Results</h2>

                <div className="relative overflow-x-auto p-2">
                    {renderResults(recentResults)}
                    {showArchive && renderResults(archivedResults)}
                </div>

                <button
                    onClick={() => setShowArchive(!showArchive)}
                    className="mt-5 px-4 py-1 bg-[#2baf2b] text-white text-sm rounded flex gap-2 items-center"
                >
                    {showArchive ? 'Hide Archive Data' : 'Show Archive Data'}
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                        <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                    </svg>
                </button>
            </div>
        </section>
    );
};

export default QuaterlyMain;
