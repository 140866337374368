export const companyEducomp = [
    {
        id: 1,
        Title: "ESL Notice AGM 25-08-2023.",
        PDF: "https://educomp.com/DataImages/Downloads/ESL_Notice_AGM_25082023.pdf",
        isActive: 1,
    },


    {
        id: 2,
        Title: "Corrigendum to the AGM Notice",
        PDF: "https://educomp.com/DataImages/Downloads/Corrigendum-to-the-AGM-Notice.pdf",
        isActive: 1
    },
    {
        id: 3,
        Title: "Annual report for 2022-2023.",
        PDF: "https://educomp.com/DataImages/Downloads/ANNUAL-REPORT-ESL-2022-2023.pdf",
        isActive: 1
    },
    {
        id: 4,
        Title: "Annual report for 2021-22",
        PDF: "https://educomp.com/DataImages/Downloads/Educomp_Annual_report_2021_22.pdf",
        isActive: 1
    },
    {
        id: 5,
        Title: "Educomp AR 2020-21_rev_final.",
        PDF: "https://educomp.com/DataImages/Downloads/educomp-AR-2020-21_rev_final.pdf",
        isActive: 1
    },
    {
        id: 6,
        Title: "Annual report for 2020-2021.",
        PDF: "https://educomp.com/DataImages/Downloads/AnnualReport2020-21.pdf",
        isActive: 1
    },
    {
        id: 7,
        Title: "Annual report for 2019-2020.",
        PDF: "https://educomp.com/DataImages/Downloads/ANNUAL-REPORT-ESL-2019-2020-Final.pdf",
        isActive: 1
    },
    {
        id: 8,
        Title: "Annual report for 2018-2019.",
        PDF: "https://educomp.com/DataImages/Downloads/ANNUAL-REPORT-ESL-2018-2019-final.pdf",
        isActive: 1
    },
    {
        id: 9,
        Title: "Annual report for 2016-2017.",
        PDF: "https://educomp.com/DataImages/Downloads/AnnualReport2016-17.pdf",
        isActive: 1
    },
    {
        id: 10,
        Title: "Annual report for 2015-2016.",
        PDF: "https://educomp.com/DataImages/Downloads/AnnualReport2015-16.pdf",
        isActive: 1
    },
    {
        id: 11,
        Title: "Annual report for 2014-2015.",
        PDF: "https://educomp.com/DataImages/Downloads/AnnualReport2014-15.pdf",
        isActive: 1
    },
    {
        id: 12,
        Title: "Annual report for 2013-2014.",
        PDF: "https://educomp.com/DataImages/Downloads/AnnualReport2013-14.pdf",
        isActive: 1
    },
    {
        id: 13,
        Title: "Annual report for 2012-2013.",
        PDF: "https://educomp.com/DataImages/Downloads/AnnualReport2012-13.pdf",
        isActive: 1
    },
    {
        id: 14,
        Title: "Annual report for 2011-2012.",
        PDF: "https://educomp.com/DataImages/Downloads/AnnualReport2011-12.pdf",
        isActive: 1
    },
    {
        id: 15,
        Title: "Annual report for 2010-2011",
        PDF: "https://educomp.com/DataImages/Downloads/AnnualReport2010-11.pdf",
        isActive: 1
    },
    {
        id: 16,
        Title: "Annual report for 2009-2010.",
        PDF: "https://educomp.com/DataImages/Downloads/AnnualReport2009-10.pdf",
        isActive: 1
    },
    {
        id: 17,
        Title: "Annual report for 2008-2009.",
        PDF: "https://educomp.com/DataImages/Downloads/AnnualReport2008-09.pdf",
        isActive: 1
    },
    {
        id: 18,
        Title: "Annual report for 2007-2008.",
        PDF: "https://educomp.com/DataImages/Downloads/AnnualReport2007-08.pdf",
        isActive: 1
    },
    {
        id: 19,
        Title: "Annual report for 2006-2007.",
        PDF: "https://educomp.com/Data/AnnualReport2006-07.pdf",
        isActive: 1
    },
    {
        id: 20,
        Title: "Annual report for 2005-2006.",
        PDF: "https://educomp.com/Data/AnnualReport2005-06.pdf",
        isActive: 1
    }
];