import React from 'react';
import Banner from '../../components/Banner';
import Navbar from '../../components/Navbar';
import GovernanceHero from '../../components/governance/GovernanceHero';
import GovernanceMain from '../../components/governance/GovernanceMain';

function Governance() {
    return (
        <div>
            <Banner />
            <Navbar />
            <GovernanceHero headingText="Governance" />
            <GovernanceMain />
        </div>
    )
}

export default Governance