export const newpaperannounce = [
    {
        id: 1,
        Heading1: "FY 2015-16",
        Heading2: "31-03-16",
        PDF1: "https://educomp.com/Data/31-03-2016_NewspaperAdBoardMeeting-ESL.pdf",
        PDF2: "https://educomp.com/Data/31-03-2016_Financial_Results.pdf",
        isActive: 1
    },
    {
        id: 2,
        Heading1: "FY 2015-16",
        Heading2: "31-12-15",
        PDF1: "https://educomp.com/Data/31-12-2015_NewspaperAdBoardMeeting-ESL.pdf",
        PDF2: "https://educomp.com/Data/31-12-2015_Results_ESL.pdf",
        isActive: 1
    }
]