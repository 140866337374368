import React from 'react';
import Banner from '../../components/Banner';
import Navbar from '../../components/Navbar';

function Disclosures() {
    return (
        <div>
            <Banner />
            <Navbar />
        </div>
    )
}

export default Disclosures