import React from 'react';
import video1 from "../assets/videos/5_NEP_1.mp4";
import video2 from "../assets/videos/7_business.mp4";
import video3 from "../assets/videos/1_content_gamified.mp4";
import video4 from "../assets/videos/2_content_notes.mp4";
import { Link } from 'react-router-dom';

function Videosection() {
    function handleScrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }
    return (
        <section className='md:mt-5 lg:mt-6 xl:mt-6 2xl:mt-6 mt-3'>

            <div className="px-4 py-6 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-6 lg:py-6 flex flex-col lg:flex-row lg:items-center lg:justify-between md:text-center lg:text-center xl:text-center text-start">
                <h2 className="text-3xl lg:text-5xl font-extrabold text-[#2baf2b] mb-4 lg:mb-0">Content Resources</h2>
                <Link to={`/resources`} onClick={handleScrollToTop}>
                    <p className="text-[#2baf2b] text-sm font-bold lg:text-xl text-cebter underline underline-offset-4 mt-2">View Demo Resources</p>
                </Link>
            </div>

            <div class="md:grid md:grid-cols-2 lg:grid lg:grid-col-2 xl:grid xl:grid-col-2 2xl:grid 2xl:grid-col-2 grid grid-col-1 gap-3 max-w-7xl mx-auto p-4">
                <div className="w-fit bg-[#2baf2b] rounded-lg ">
                    <video
                        className="rounded-lg md:max-w-xl lg:max-w-xl xl:max-w-xl w-screen bg-[#2baf2b]"
                        autoPlay
                        muted
                        loop
                        playsInline
                        src={video1} type="video/mp4"
                    />
                </div>

                <div className="w-fit rounded-lg bg-[#2baf2b]">
                    <video
                        className="rounded-lg  md:max-w-xl lg:max-w-xl xl:max-w-xl w-screen bg-[#2baf2b]"
                        autoPlay
                        muted
                        loop
                        playsInline
                        src={video2} type="video/mp4"
                    />
                </div>

                <div className="w-fit rounded-lg bg-[#2baf2b]">
                    <video
                        className="rounded-lg  md:max-w-xl lg:max-w-xl xl:max-w-xl w-screen bg-[#2baf2b]"
                        autoPlay
                        muted
                        loop
                        playsInline
                        src={video3} type="video/mp4"
                    />
                </div>

                <div className="w-fit rounded-lg bg-[#2baf2b]">
                    <video
                        className="rounded-lg  md:max-w-xl lg:max-w-xl xl:max-w-xl w-screen bg-[#2baf2b]"
                        autoPlay
                        muted
                        loop
                        playsInline
                        src={video4} type="video/mp4"
                    />
                </div>

            </div>

        </section>
    )
}

export default Videosection
