import React from 'react'
import GovernanceHero from './GovernanceHero';
import { Link, useLocation } from 'react-router-dom';

function ComitteeBoards() {
    const location = useLocation();

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    const isLinkActive = (pathname) => {
        return location.pathname === pathname;
    };

    return (
        <section>
            <GovernanceHero headingText={"Committees to the Board"} />

            <div className='mx-auto max-w-7xl py-8'>
                <div className='md:flex lg:flex xl:flex 2xl:flex'>
                    <div className='lg:px-6 md:px-6 xl:px-6 px-2 md:pb-6 lg:pb-6 xl:pb-6 pb-4 max-w-4xl w-full'>
                        <h2 class="my-4 text-2xl text-gray-700 font-bold">AUDIT COMMITTEE</h2>
                        <div class="relative overflow-x-auto  p-2">
                            <table class=" text-sm text-left rtl:text-right text-gray-500">
                                <thead class="text-xs text-gray-700 uppercase">
                                    <tr>
                                        <th scope="col" class="px-6 py-3 ">
                                            Name of Director
                                        </th>
                                        <th scope="col" class="px-6 py-3">
                                            Category
                                        </th>
                                    </tr>
                                </thead>
                                {/* <tbody>
                                    <tr class="border-b border-gray-200 ">
                                        <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap  ">
                                            Mr. Vijay K. Choudhary
                                        </th>
                                        <td class="px-6 py-4">
                                            Chairman, Independent & Non Executive Director
                                        </td>

                                    </tr>
                                    <tr class="border-b border-gray-200 ">
                                        <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap  ">
                                            Mr. Shantanu Prakash
                                        </th>
                                        <td class="px-6 py-4">
                                            Member, Promoter & Executive Director
                                        </td>

                                    </tr>
                                    <tr className="border-b border-gray-200">
                                        <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap  ">
                                            Ms. Swati Sinha
                                        </th>
                                        <td class="px-6 py-4">
                                            Member, Independent & Non Executive Director
                                        </td>
                                    </tr>
                                    <tr class="">
                                        <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap  ">
                                            Ms. Azra S. Hasan
                                        </th>
                                        <td class="px-6 py-4">
                                            Member, Independent & Non Executive Director
                                        </td>
                                    </tr>

                                </tbody> */}
                            </table>
                        </div>
                    </div>

                    <div className='md:px-0 lg:px-0 xl:px-0 2xl:px-0 px-3.5'>
                        <div className="block mt-5 max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-50">

                            <h5 class="mb-2 text-xl font-bold tracking-tight text-gray-700 ">Governance</h5>
                            <ul class="font-normal text-gray-700 list-disc  list-inside space-y-2">
                                <li className={`${isLinkActive('/investors/governance') ? 'font-bold text-blue-600' : ''}`}>

                                    <Link
                                        onClick={scrollToTop}
                                        to={`/investors/governance`}>
                                        Board of Directors
                                    </Link>
                                </li>
                                <li className={`${isLinkActive('/investors/terms-and-conditions-independent-directors') ? 'font-bold text-blue-600' : ''}`}>
                                    <Link
                                        onClick={scrollToTop}
                                        to={`/investors/terms-and-conditions-independent-directors`}>
                                        Terms & Conditions for appointment of Independent Directors
                                    </Link>
                                </li>
                                <li className={`${isLinkActive('/investors/committee-to-board') ? 'font-bold text-blue-600' : ''}`}>
                                    <Link
                                        onClick={scrollToTop}
                                        to={`/investors/committee-to-board`}>
                                        Committees to the Board
                                    </Link>
                                </li>
                                <li className={`${isLinkActive('/investors/code-to-conduct') ? 'font-bold text-blue-600' : ''}`}>
                                    <Link
                                        onClick={scrollToTop}
                                        to={`/investors/code-to-conduct`}>
                                        Code of Conduct
                                    </Link>
                                </li>
                                <li className={`${isLinkActive('/investors/policies') ? 'font-bold text-blue-600' : ''}`}>
                                    <Link
                                        onClick={scrollToTop}
                                        to={`/investors/policies`}>
                                        Policies
                                    </Link>
                                </li>
                                <li className={`${isLinkActive('/investors/familiarisation-programme') ? 'font-bold text-blue-600' : ''}`}>
                                    <Link
                                        onClick={scrollToTop}
                                        to={`/investors/familiarisation-programme`}>
                                        Familiarisation Programme
                                    </Link>
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>

                <div className='lg:px-6 md:px-6 xl:px-6 px-2 md:pb-6 lg:pb-6 xl:pb-6 pb-4 max-w-4xl'>
                    <h2 class="my-4 text-2xl text-gray-700 font-bold">NOMINATION & REMUNERATION COMMITTEE</h2>
                    <div class="relative overflow-x-auto  p-2">
                        <table class=" text-sm text-left rtl:text-right text-gray-500">
                            <thead class="text-xs text-gray-700 uppercase">
                                <tr>
                                    <th scope="col" class="px-6 py-3 ">
                                        Name of Director
                                    </th>
                                    <th scope="col" class="px-6 py-3">
                                        Category
                                    </th>
                                </tr>
                            </thead>
                            {/* <tbody>
                                <tr class="border-b border-gray-200 ">
                                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap  ">
                                        Ms. Azra S. Hasan
                                    </th>
                                    <td class="px-6 py-4">
                                        Chairman, Independent & Non Executive Director
                                    </td>

                                </tr>
                                <tr class="border-b border-gray-200 ">
                                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap  ">
                                        Mr. Vijay K. Choudhary
                                    </th>
                                    <td class="px-6 py-4">
                                        Member, Independent & Non Executive Director
                                    </td>

                                </tr>
                                <tr class="">
                                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap  ">
                                        Ms. Swati Sinha
                                    </th>
                                    <td class="px-6 py-4">
                                        Member, Independent & Non Executive Director
                                    </td>
                                </tr>
                            </tbody> */}
                        </table>
                    </div>

                    <h2 class="my-4 text-2xl text-gray-700 font-bold">STAKEHOLDERS RELATIONSHIP COMMITTEE</h2>
                    <div class="relative overflow-x-auto  p-2">
                        <table class=" text-sm text-left rtl:text-right text-gray-500">
                            <thead class="text-xs text-gray-700 uppercase">
                                <tr>
                                    <th scope="col" class="px-6 py-3 ">
                                        Name of Director
                                    </th>
                                    <th scope="col" class="px-6 py-3">
                                        Category
                                    </th>
                                </tr>
                            </thead>
                            {/* <tbody>
                                <tr class="border-b border-gray-200 ">
                                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap  ">
                                        Mr. Vijay K. Choudhary
                                    </th>
                                    <td class="px-6 py-4">
                                        Chairman, Independent & Non Executive Director
                                    </td>

                                </tr>
                                <tr class="border-b border-gray-200 ">
                                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap  ">
                                        Mr. Shantanu Prakash
                                    </th>
                                    <td class="px-6 py-4">
                                        Member, Promoter & Executive Director
                                    </td>

                                </tr>
                                <tr class="">
                                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap  ">
                                        Ms. Azra S. Hasan
                                    </th>
                                    <td class="px-6 py-4">
                                        Member, Independent & Non Executive Director
                                    </td>
                                </tr>
                            </tbody> */}
                        </table>
                    </div>

                    <h2 class="my-4 text-2xl text-gray-700 font-bold">CORPORATE SOCIAL RESPONSIBILITY COMMITTEE</h2>
                    <div class="relative overflow-x-auto  p-2">
                        <table class=" text-sm text-left rtl:text-right text-gray-500">
                            <thead class="text-xs text-gray-700 uppercase">
                                <tr>
                                    <th scope="col" class="px-6 py-3 ">
                                        Name of Director
                                    </th>
                                    <th scope="col" class="px-6 py-3">
                                        Category
                                    </th>
                                </tr>
                            </thead>
                            {/* <tbody>
                                <tr class="border-b border-gray-200 ">
                                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap  ">
                                        Mr. Shantanu Prakash
                                    </th>
                                    <td class="px-6 py-4">
                                        Chairman, Promoter & Executive Director
                                    </td>

                                </tr>
                                <tr class="border-b border-gray-200 ">
                                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap  ">
                                        Mr. Vinod Kumar Dandona
                                    </th>
                                    <td class="px-6 py-4">
                                        Member & Executive Director
                                    </td>

                                </tr>
                                <tr class="">
                                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap  ">
                                        Mr. Vijay K. Choudhary
                                    </th>
                                    <td class="px-6 py-4">
                                        Member, Independent & Non Executive Director
                                    </td>
                                </tr>
                            </tbody> */}
                        </table>
                    </div>

                    <h2 class="my-4 text-2xl text-gray-700 font-bold">FINANCE COMMITTEE</h2>
                    <div class="relative overflow-x-auto  p-2">
                        <table class=" text-sm text-left rtl:text-right text-gray-500">
                            <thead class="text-xs text-gray-700 uppercase">
                                <tr>
                                    <th scope="col" class="px-6 py-3 ">
                                        Name of Director
                                    </th>
                                    <th scope="col" class="px-6 py-3">
                                        Category
                                    </th>
                                </tr>
                            </thead>
                            {/* <tbody>
                                <tr class="border-b border-gray-200 ">
                                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap  ">
                                        Mr. Shantanu Prakash
                                    </th>
                                    <td class="px-6 py-4">
                                        Chairman, Promoter & Executive Director
                                    </td>

                                </tr>
                                <tr class="border-b border-gray-200 ">
                                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap  ">
                                        Mr. Vijay K. Choudhary
                                    </th>
                                    <td class="px-6 py-4">
                                        Member, Independent & Non Executive Director
                                    </td>

                                </tr>
                                <tr class="">
                                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap  ">
                                        Ms. Azra S. Hasan
                                    </th>
                                    <td class="px-6 py-4">
                                        Member, Independent & Non Executive Director
                                    </td>
                                </tr>
                            </tbody> */}
                        </table>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ComitteeBoards