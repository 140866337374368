import React from 'react'
import Banner from '../../../components/Banner'
import Navbar from '../../../components/Navbar'
import AGMPostal from '../../../components/AnnouncementCorporate/AGMPostal'

function AGMPostalPage() {
    return (
        <div>
            <Banner />
            <Navbar />
            <AGMPostal />
        </div>
    )
}

export default AGMPostalPage