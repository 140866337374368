import React from 'react';
import b2g from "../assets/videos/b2g.mp4";
import b2g_2 from "../assets/images/b2g.svg";

function B2g() {
    return (
        <section>
            <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-12">
                <div className="relative mb-6 sm:mx-auto md:mb-10 md:max-w-md lg:max-w-2xl">
                    <video className="w-full h-full rounded-lg" autoPlay muted loop playsInline src={b2g} />
                    <a
                        href="/"
                        aria-label="Play Video"
                        className="absolute inset-0 flex items-center justify-center w-full h-full transition-colors duration-300 bg-opacity-50 group hover:bg-opacity-25"
                    >

                    </a>
                </div>
            </div>

            <div className="flex flex-col lg:flex-row justify-between gap-8 md:px-4 md:py-2 lg:px-4 lg:py-2 xl:px-4 xl:py-2 px-0 pt-2 max-w-7xl mx-auto">

                <div
                    initial={{
                        opacity: 0,
                        x: -300,
                    }}
                    whileInView={{
                        opacity: 1,
                        x: 0,
                    }}
                    transition={{
                        duration: 1.5,
                    }}
                    className="w-full lg:w-6/12 flex flex-col justify-center space-y-4 md:px-0 lg:px-0 xl:px-0 px-2"
                >
                    <h2 className="tracking-tight  font-extrabold md:text-5xl text-2xl text-black">
                        <span className="text-[#2baf2b]"> B2G - Smartclass K-12 Content & Hardware </span>
                    </h2>
                    <div className="max-w-screen-lg text-gray-800 sm:text-lg  rounded-md">

                        <p className="mb-4 font-normal">
                            While enrollment in government schools is large, educational quality falls short of that of private institutions. Live demonstrations and audio-visual tools can help students learn subjects more successfully. In Indian schools, a lack of resources is constantly a problem.

                        </p>
                        <p className="mb-4 font-normal">
                            Educomp Solutions will use the power of the world's biggest K-12 material collection in 2D and 3D animations to make learning more engaging and topics easier to comprehend. Every classroom may be outfitted with cutting-edge Smartclass technology and K-12 material to complement the teachers. The kids will be taught in accordance with the curriculum established by the school board.

                        </p>
                        <p className="mb-4 font-normal">
                            Educomp Solutions believes that every student should have an equal opportunity to study. Our solution was created to bridge the learning gap between public and private institutions, encouraging children to stay in school and not drop out. They become psychologically stronger as a result of digital learning; they become more interested in their studies and perform better.


                        </p>
                    </div>
                </div>

                <div className="w-full lg:w-6/12 ">
                    <img
                        initial={{
                            x: 500,
                            opacity: 0,
                            scale: 0.5,
                        }}
                        animate={{
                            x: 0,
                            opacity: 1,
                            scale: 1,
                        }}
                        transition={{
                            duration: 1,
                        }}
                        className="w-full h-full md:px-0 lg:px-0 xl:px-0 px-2"
                        src={b2g_2}
                        alt="Road-Map"
                    />
                </div>
            </div>

        </section>
    )
}

export default B2g