export const divinendAmount = [
    {
        id: 1,
        Title: "Final Dividend 2011-2012",
        PDF: "https://educomp.com/Data/dividends/dividend-2011-12.pdf",
        isActive: 1
    },
    {
        id: 2,
        Title: "Final Dividend 2010-2011 - Part 1",
        PDF: "https://educomp.com/Data/dividends/dividend-2010-11-1.pdf",
        isActive: 1
    },
    {
        id: 3,
        Title: "Final Dividend 2010-2011 - Part 2",
        PDF: "https://educomp.com/Data/dividends/dividend-2010-11-2.pdf",
        isActive: 1
    },
    {
        id: 4,
        Title: "Final Dividend 2009-2010",
        PDF: "https://educomp.com/Data/dividends/dividend-2009-10.pdf",
        isActive: 1
    },
    {
        id: 5,
        Title: "Dividend 2008-2009",
        PDF: "https://educomp.com/Data/dividends/dividend-2008-09.pdf",
        isActive: 1
    },
    {
        id: 6,
        Title: "Final Dividend 2007-2008",
        PDF: "https://educomp.com/Data/dividends/dividend-07-08.pdf",
        isActive: 1
    },
    {
        id: 7,
        Title: "Final Dividend 2006-2007",
        PDF: "https://educomp.com/Data/dividends/dividend-2006-07.pdf",
        isActive: 1
    },
    {
        id: 8,
        Title: "Final Dividend 2005-2006",
        PDF: "https://educomp.com/Data/dividends/dividend-2005-06.pdf",
        isActive: 1
    }
];