import React from 'react'
import Banner from '../../../components/Banner'
import Navbar from '../../../components/Navbar'
import ComitteeBoards from '../../../components/governance/ComitteeBoards'

function ComitteeBoard() {
    return (
        <div>
            <Banner />
            <Navbar />
            <ComitteeBoards />
        </div>
    )
}

export default ComitteeBoard