import React from 'react';
import Banner from '../../components/Banner';
import Navbar from '../../components/Navbar';
import CorporateGovernance from '../../components/CorporateGovernance/CorporateGovernance';

function CorporateGovernancePage() {
    return (
        <div>
            <Banner />
            <Navbar />
            <CorporateGovernance />
        </div>
    )
}

export default CorporateGovernancePage