import React from 'react';
import engage1 from "../assets/images/engage-img-1.jpg";
import engage2 from "../assets/images/Optimized-educomp-ed-3.jpg";
import engage3 from "../assets/images/engage-img-3.jpg";
import engage4 from "../assets/images/engage-img-4.jpg";
import engage7 from "../assets/images/engage-img-7.jpg";
import engage6 from "../assets/images/engage-img-6.jpg";

function ContentEngage() {
    return (
        <div className='bg-[#01295c] mt-4'>
            <div className="px-4 py-10 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-16">
                <div className="flex flex-col mb-6 lg:justify-between lg:flex-row md:mb-8">
                    <h2 className="max-w-lg mb-6 font-sans lg:text-5xl xl:6xl md:3xl text-4xl font-semibold text-[#2baf2b]">

                        <span className='text-gray-200'>Engage </span>
                        <div className="h-1 ml-auto duration-300 origin-left transform bg-deep-purple-accent-400 scale-x-30 group-hover:scale-x-100" />
                    </h2>

                </div>
                <div className="grid gap-6 row-gap-5 mb-8 lg:grid-cols-4 sm:row-gap-6 sm:grid-cols-2">
                    <a href="/" aria-label="View Item">
                        <div className="relative overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl">
                            <img
                                className="object-cover w-full h-56 md:h-44 xl:h-44"
                                src={engage2}
                                alt="video-lessons"
                            />
                            <p className='p-2 text-white uppercase font-semibold tracking-[1px] text-center bg-[#2baf2b]'>Video Lessons</p>
                            <div className="absolute inset-0 px-6 py-4 transition-opacity duration-200 bg-white/80 bg-opacity-75 opacity-0 hover:opacity-100">

                                <p className="text-md tracking-wide text-gray-900 font-semibold text-center">
                                    These are rich-media 2D-/3D-animated videos that make it easy for students to visualize all the tough concepts in the curriculum and help bring abstract concepts to life.
                                </p>
                            </div>
                        </div>
                    </a>
                    <a href="/" aria-label="View Item">
                        <div className="relative overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl">
                            <img
                                className="object-cover w-full h-56 md:h-44 xl:h-44"
                                src={engage7}
                                alt="video-lessons"
                            />
                            <p className='p-2 text-white uppercase font-semibold tracking-[1px] text-center bg-[#2baf2b]'>Interactive Maps</p>
                            <div className="absolute inset-0 px-6 py-4 transition-opacity duration-200 bg-white/80 bg-opacity-75 opacity-0 hover:opacity-100">

                                <p className="text-md tracking-wide text-gray-900 font-semibold text-center">
                                    These help teachers to teach maps in an interactive manner in a class. A teacher can view/hide areas or information on a map to indicate different topics such as roadways, waterways networks across India.
                                </p>
                            </div>
                        </div>
                    </a>
                    <a href="/" aria-label="View Item">
                        <div className="relative overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl">
                            <img
                                className="object-cover w-full h-56 md:h-44 xl:h-44"
                                src={engage3}
                                alt="video-lessons"
                            />
                            <p className='p-2 text-white uppercase font-semibold tracking-[1px] text-center bg-[#2baf2b]'>Lab Experiments</p>
                            <div className="absolute inset-0 px-6 py-4 transition-opacity duration-200 bg-white/80 bg-opacity-75 opacity-0 hover:opacity-100">

                                <p className="text-md tracking-wide text-gray-900 font-semibold text-center">
                                    These are real-time videos of lab experiments and activities in Science and Math across grades 3-10 and provide a virtual lab experience in the classroom.
                                </p>
                            </div>
                        </div>
                    </a>
                    <a href="/" aria-label="View Item">
                        <div className="relative overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl">
                            <img
                                className="object-cover w-full h-56 md:h-44 xl:h-44"
                                src={engage4}
                                alt="video-lessons"
                            />
                            <p className='p-2 text-white uppercase font-semibold tracking-[1px] text-center bg-[#2baf2b]'>Teacher Presentations
                            </p>
                            <div className="absolute inset-0 px-6 py-4 transition-opacity duration-200 bg-white/80 bg-opacity-75 opacity-0 hover:opacity-100">

                                <p className="text-md tracking-wide text-gray-900 font-semibold text-center">
                                    These are topic presentations created by teachers across India, mapped to state- board curricula.

                                </p>
                            </div>
                        </div>
                    </a>
                    <a href="/" aria-label="View Item">
                        <div className="relative overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl">
                            <img
                                className="object-cover w-full h-56 md:h-44 xl:h-44"
                                src={engage1}
                                alt="video-lessons"
                            />
                            <p className='p-2 text-white uppercase font-semibold tracking-[1px] text-center bg-[#2baf2b]'>Diagram Makers</p>
                            <div className="absolute inset-0 px-6 py-4 transition-opacity duration-200 bg-white/80 bg-opacity-75 opacity-0 hover:opacity-100">

                                <p className="text-md tracking-wide text-gray-900 font-semibold text-center">
                                    These are animated videos that provide step-by-step instructions to draw a diagram of relevant topics for Science and Geography across grades 6-12

                                </p>
                            </div>
                        </div>
                    </a>
                    <a href="/" aria-label="View Item">
                        <div className="relative overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl">
                            <img
                                className="object-cover w-full h-56 md:h-44 xl:h-44"
                                src={engage6}
                                alt="video-lessons"
                            />
                            <p className='p-2 text-white uppercase font-semibold tracking-[1px] text-center bg-[#2baf2b]'>Simulations</p>
                            <div className="absolute inset-0 px-6 py-4 transition-opacity duration-200 bg-white/80 bg-opacity-75 opacity-0 hover:opacity-100">

                                <p className="text-md tracking-wide text-gray-900 font-semibold text-center">
                                    These resources provide a simulated experience to students in lab experiments and activities of Math and Science.

                                </p>
                            </div>
                        </div>
                    </a>
                </div>

            </div>
        </div>
    )
}

export default ContentEngage