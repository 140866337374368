import React from 'react';
import Banner from '../../components/Banner';
import Navbar from '../../components/Navbar';
import BoardMeetingsPage from './AnnounceCorporate/BoardMeetingsPage';

function CorporateAnnoucement() {
    return (
        <div>
            <Banner />
            <Navbar />
            <BoardMeetingsPage />
        </div>
    )
}

export default CorporateAnnoucement